import { Activity, ActivityState, Quiz } from "../models/Activity.model";
import {
  BookmarkStatus,
  Category as CategoryGql,
  Lesson as LessonGql,
  Quiz as QuizGql,
  Status
} from "../graphql/index";
import { Category, ParentMapped } from "../models/category.model";
import { getLastBookmark, mapActivity } from "./activity.service";
import { AccessTreeItem } from "../models/user.model";
import { canAccessCategoryFromTree } from "./user.service";
import { getDomainFromPath } from "../helpers/domain.helper";

export const mapCategory = (
  category: CategoryGql,
  access?: AccessTreeItem,
  isSuperAdmin?: boolean
): Category => {
  const domain = getDomainFromPath(category.path);

  // Init var
  let nbActivities = 0;
  let estimatedTime = 0;
  let confirmedTime = 0;
  let isStarted = false;
  let isNew = false;
  let isUpdated = false;

  // Map children
  const children: Category[] = [];
  const childrensData = (category.childrensData ?? []) as CategoryGql[];

  for (let c of childrensData) {
    // Check blended access
    if (access && !canAccessCategoryFromTree(access, c.learningId)) {
      continue;
    }

    const categoryMapped = mapCategory(
      c,
      access?.children?.[c.learningId] ?? undefined,
      isSuperAdmin
    );

    // Don't show empty categories if not super admin or if dossier d'actualité
    if (!isSuperAdmin && !categoryMapped.nbActivities) {
      continue;
    }

    nbActivities += categoryMapped.nbActivities;
    estimatedTime += categoryMapped.estimatedTime;
    confirmedTime += categoryMapped.confirmedTime;
    isStarted = isStarted || categoryMapped.isStarted;
    isNew = isNew || categoryMapped.state === ActivityState.NEW;
    isUpdated = isUpdated || categoryMapped.state === ActivityState.UPDATE;

    children.push(categoryMapped);
  }

  // Map activities
  let activities: Activity[] = [];
  const quizzes: QuizGql[] = (category.quizzes ?? []) as QuizGql[];
  const lessons: LessonGql[] = (category.lessons ?? []) as LessonGql[];
  let activitiesConfirmedTime = 0;

  for (let activity of [...quizzes, ...lessons]) {
    // Don't show unpublished activities if not super admin
    if (!activity || (!isSuperAdmin && activity.status !== Status.Published))
      continue;
    const activityMapped = {
      ...mapActivity(activity, false, category),
      domain: domain
    };

    nbActivities++;
    estimatedTime += activityMapped.estimatedTime ?? 0;
    activitiesConfirmedTime += activityMapped.confirmedTime;
    isStarted = isStarted || activityMapped.isStarted;
    isNew = isNew || activityMapped.state === ActivityState.NEW;
    isUpdated = isUpdated || activityMapped.state === ActivityState.UPDATE;

    activities.push(activityMapped);
  }

  confirmedTime +=
    category.elapsedTime?.confirmedDuration ?? activitiesConfirmedTime;

  isStarted = isStarted || confirmedTime > 0;

  const starterQuiz =
    !isStarted && category.quizzes?.length && category.quizzes[0]
      ? (mapActivity(category.quizzes[0], true) as Quiz)
      : undefined;
  const lastStarterQuizProgress = getLastBookmark(
    starterQuiz?.bookmark ?? [],
    true
  );

  return {
    ...category,
    title: category.title ?? "",
    childrensMapped: children,
    nbActivities,
    estimatedTime,
    confirmedTime,
    progress: estimatedTime
      ? Math.floor((confirmedTime / estimatedTime) * 100)
      : 0,
    isStarted,
    starterQuiz:
      !isStarted && lastStarterQuizProgress?.status !== BookmarkStatus.Done
        ? starterQuiz
        : undefined,
    state: isNew
      ? ActivityState.NEW
      : isUpdated
      ? ActivityState.UPDATE
      : undefined,
    domain,
    parentMapped: getParent(category.parent),
    activitiesMapped: activities
  };
};

export const getParent = (
  category?: CategoryGql | null
): ParentMapped | undefined => {
  if (!category) return;

  return {
    _id: category._id,
    title: category.title,
    learningId: category.learningId,
    parentMapped: getParent(category.parent)
  };
};
