import { CONST } from "../config/constant";
import {
  BookmarkStatus,
  BookmarkType,
  Category as CategoryGql,
  Lesson as LessonGql,
  Quiz as QuizGql,
  QuizProgress
} from "../graphql";
import { formattedDate } from "../helpers/date-fomatter.helper";
import {
  Activity,
  ActivityState,
  Lesson,
  NATURES,
  Quiz,
  StarterQuizList,
  TYPES
} from "../models/Activity.model";
import { Category } from "../models/category.model";
import { store } from "../store/createStore";
import { getParent } from "./category.service";
import { saveAs } from "file-saver";
import { getDomainFromParent } from "../helpers/domain.helper";

export const mapActivity = (
  activity: QuizGql | LessonGql,
  isStarter: boolean = false,
  parent?: CategoryGql
): Activity => {
  let isStarted = false;
  let isDone = false;
  let confirmedTime = 0;
  let progress = 0;
  let validatedQuestionCount = 0;

  if (activity.elapsedTime) {
    confirmedTime = activity.elapsedTime?.confirmedDuration ?? 0;
    progress = activity.estimatedTime
      ? Math.floor((confirmedTime / activity.estimatedTime) * 100)
      : 0;
    isDone = progress === 100 ? true : false;
    isStarted = confirmedTime > 0;
  }

  if (activity?.__typename === "Quiz") {
    const lastBookmark = getLastBookmark(activity.bookmark ?? [], isStarter);

    if (lastBookmark) {
      isStarted = true;

      if (
        lastBookmark?.status === BookmarkStatus.Done &&
        !lastBookmark.current_question_id
      ) {
        progress = 100;
        validatedQuestionCount = activity.questionCount;
      } else if (
        lastBookmark?.current_question_id &&
        activity.exerciseListData
      ) {
        const index = activity.exerciseListData.findIndex(
          e => e?.learningId === lastBookmark.current_question_id
        );

        if (index >= 0) {
          validatedQuestionCount = index + 1;
          progress = Math.floor(
            (validatedQuestionCount / activity.questionCount) * 100
          );
        }
      }
    }
  }

  return {
    ...activity,
    state: getActivityState(activity),
    domain: getDomainFromParent(parent ?? activity.parentCategories?.[0]),
    parentMapped: getParent(
      activity.parentCategories?.length ? activity.parentCategories[0] : parent
    ),
    confirmedTime,
    progress,
    type: activity.tags?.find(t => t && TYPES.includes(t)) ?? undefined,
    nature: activity.tags?.find(t => t && NATURES.includes(t)) ?? undefined,
    isStarted,
    isDone,
    validatedQuestionCount
  };
};

export const getActivityState = (
  activity: QuizGql | LessonGql
): ActivityState | null => {
  const dateCompareTo = new Date();
  dateCompareTo.setMonth(dateCompareTo.getMonth() - CONST.MONTH_REDUCED);

  if (new Date(activity.createdAt) >= dateCompareTo) {
    return ActivityState.NEW;
  } else if (
    activity.tags &&
    activity.tags.includes("A_LA_UNE") &&
    new Date(activity.updatedAt) >= dateCompareTo
  ) {
    return ActivityState.UPDATE;
  }

  return null;
};

const getLearningIdPath = (
  category?: CategoryGql | null
): Pick<Category, "_id" | "learningId" | "path">[] =>
  category
    ? [
        {
          _id: category._id,
          path: category.path,
          learningId: category.learningId
        },
        ...getLearningIdPath(category.parent)
      ]
    : [];

export const sortActivities = (activities: Activity[]) =>
  activities.sort((a1, a2) => {
    if (a1.state !== a2.state) {
      return a1.state === ActivityState.NEW ? -1 : 1;
    } else if (a1.state === ActivityState.UPDATE) {
      return a1.updatedAt > a2.updatedAt ? -1 : 1;
    }
    return a1.createdAt > a2.createdAt ? -1 : 1;
  });

export const getYoutubeId = (activity: Activity): string => {
  if (activity.assetLinks) {
    const asset = activity.assetLinks.find(
      a => a?.type === "external-attachment"
    );
    if (asset && asset?.media?.url) {
      const regexMatch = asset.media.url.match(
        /(https?:\/\/)?(www.)?youtu(be)?\.((com)|(be)){1}\/((embed\/)|(watch\?v=))?([A-Za-z0-9-_]+)(\?.*)?/i
      );
      if (regexMatch && regexMatch.length >= 9) {
        return regexMatch[10];
      }
    }
  }
  return "";
};

export const isQuiz = (activity: Activity): activity is Quiz => {
  return activity?.__typename === "Quiz";
};

export const isLesson = (activity: Activity): activity is Lesson => {
  return activity?.__typename === "Lesson";
};

export const getLastBookmark = (
  bookmarks?: (QuizProgress | null)[],
  checkStarter: boolean = false,
  currentQuestionId?: number | null
): QuizProgress | null | undefined => {
  const bookmarksFiltered = bookmarks?.filter(
    b =>
      ((checkStarter && b?.type === BookmarkType.MockExam) ||
        (!checkStarter && b?.type === BookmarkType.Standard)) &&
      (!currentQuestionId ||
        (currentQuestionId && b.current_question_id === currentQuestionId))
  );
  return bookmarksFiltered && bookmarksFiltered.length > 0
    ? bookmarksFiltered[bookmarksFiltered.length - 1]
    : undefined;
};

export const getStarterQuizList = (
  starterQuizCategory: CategoryGql
): StarterQuizList => {
  const starterQuizList: StarterQuizList = {};
  const fullDomains = store.getState().user?.subscription.fullDomainsAccessEl;

  if (fullDomains && fullDomains.length && starterQuizCategory.childrensData) {
    starterQuizCategory.childrensData.forEach(c => {
      if (
        c?.quizzes?.length &&
        c.quizzes[0] &&
        fullDomains.includes(c.learningId)
      ) {
        const quiz = mapActivity(c.quizzes[0], true) as Quiz;
        if (quiz.progress < 100) {
          starterQuizList[c.learningId] = {
            _id: quiz._id,
            isStarted: quiz.progress > 0
          };
        }
      }
    });
  }

  return starterQuizList;
};

const getActivityHtml = (activity: Activity, url: string): string => {
  let html = `
    <p>
      <span>Créé le : <b>${formattedDate(
        new Date(activity.createdAt)
      )}</b></span><br />
      <span>Modifié le : <b>${formattedDate(
        new Date(activity.updatedAt)
      )}</b></span>
    </p>
    <p><a href="${url}"><b><u>${url}</u></b></span></p>
    <p>
      <span>Domaine : <b>${activity.domain.title}</b></span><br />
      <span>Thème : <b>${activity.parentMapped?.parentMapped?.parentMapped
        ?.title ?? ""}</b></span><br />
      <span>Sous-thème : <b>${activity.parentMapped?.parentMapped?.title ??
        ""}</b></span><br />
      <span>Sujet : <b>${activity.parentMapped?.title ?? ""}</b></span>
    </p>
    <p>
      <span>Titre de l'activité : <b>${activity.title}</b></span>
    </p>
  `;

  if (isQuiz(activity)) {
    return (
      html +
      (activity.exerciseListData ?? [])
        .map(
          (exercise, i) => `
        <hr />
        <h1> QUESTION N°${i + 1} : </h1>
        <b> ${exercise?.text ?? ""}</b><br />
        ${(exercise?.questionsData ?? [])
          .map(
            question => `
            ${question?.text ?? ""}
            ${(question?.possibleAnswers ?? [])
              .map(answer =>
                answer?.isCorrect
                  ? `<p><b><i>${answer?.text ?? ""}</i></b></p>`
                  : `<p>${answer?.text ?? ""}</p>`
              )
              .join("")}
            <p>Explication : <i>${question?.explanation.text ?? ""}</i></p>
          `
          )
          .join("")}
      `
        )
        .join("")
    );
  } else {
    return `${html}<hr>${activity.html}`;
  }
};

export const getActivityWord = async (
  activity: Activity,
  url: string,
  fetchWithCredentials: typeof fetch
): Promise<void> => {
  return fetchWithCredentials(`${CONST.PDF_GENERATOR_ENDPOINT}/pandoc`, {
    method: "post",
    body: JSON.stringify({
      inputFormat: "html",
      outputFormat: "docx",
      outputFilename: activity.slug + ".docx",
      inputString: getActivityHtml(activity, url)
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      if (!response.ok) {
        throw new Error();
      }
      return response.blob();
    })
    .then(result => saveAs(result, activity.slug + ".docx"));
};
