import hexToRgba from "hex-to-rgba";
import { Category } from "../graphql";
import {
  DEFAULT_DOMAIN,
  Domain,
  DomainColor,
  DomainId,
  DOMAINS
} from "../models/domain.model";

export const isMultipleDomains = (multiple: boolean): string => {
  return multiple ? "MES DOMAINES" : "MON DOMAINE";
};

export const getDomainById = (domainId: DomainId): Domain => {
  return DOMAINS.find(d => d.learningId === domainId) ?? DEFAULT_DOMAIN;
};

export const getDomainBySlug = (slug: string): Domain => {
  return DOMAINS.find(d => d.slug === slug) ?? DEFAULT_DOMAIN;
};

export const getDomainFromParent = (parent?: Category | null): Domain =>
  parent ? getDomainFromPath(`${parent.path},${parent.slug}`) : DEFAULT_DOMAIN;

export const getDomainFromPath = (path: string): Domain =>
  DOMAINS.find(domain => path.startsWith(domain.fullPath)) ?? DEFAULT_DOMAIN;

export const getDomainColor = (domain: Domain, alpha?: number): string =>
  alpha
    ? hexToRgba(DomainColor[domain.learningId], alpha)
    : DomainColor[domain.learningId];
