// React
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

// Store
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store";

// Material
import { makeStyles, createStyles, Box } from "@material-ui/core";

// Components
import NoResult from "../../features/NoResult/NoResult";
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";
import ActivityList from "../../features/ActivityList/ActivityList";
import StarterQuizCard from "../Quiz/QuizStarterCard";
import LockedBanner from "../../features/LockedBanner/LockedBanner";
import CardFolder, {
  FakeCardFolder
} from "../../features/CardFolder/CardFolder";

// Models
import { Activity, StarterQuizListItem } from "../../models/Activity.model";
import { Category } from "../../models/category.model";
import { mapCategory } from "../../services/category.service";

// Query
import { DEFAULT_DOMAIN, Domain } from "../../models/domain.model";
import { mapActivity, sortActivities } from "../../services/activity.service";
import { getDomainBySlug } from "../../helpers/domain.helper";
import { useNewsLazyQuery } from "../../graphql";
import { User } from "../../models/user.model";
import { canAccessDomain } from "../../services/user.service";
import { setErrorToHandleError } from "../../store/errorHandler/actions";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      minWidth: 320,
      maxWidth: 1000,
      margin: "0 auto",
      marginBottom: 15
    }
  })
);

/**
 * News component
 */
const News = withRouter(({ match }) => {
  const classes = useStyles();

  /** Domain state */
  const [domain, setDomain] = useState<Domain>(DEFAULT_DOMAIN);

  /** GraphQL */
  const [getNews, { data, error }] = useNewsLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network"
  });

  /** Content states */
  const [activities, setActivities] = useState<Activity[]>();
  const [folder, setFolder] = useState<Category>();
  /** Loading states */
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [loadingFolder, setLoadingFolder] = useState(true);
  /** Starter quiz */
  const starterQuizList = useSelector(
    (state: StoreState) => state.starterQuizList
  );
  /** User state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  const [starterQuiz, setStarterQuiz] = useState<StarterQuizListItem>();
  /** show locked notification state */
  const [showLockedNotification, setShowLockedNotification] = useState(false);
  const dispatch = useDispatch();

  const activateLockedNotification = () => {
    setShowLockedNotification(true);
    setTimeout(() => {
      setShowLockedNotification(false);
    }, 7500);
  };

  /**
   * Use effect activity list
   */
  useEffect(() => {
    ((activities && activities.length > 0) || activities === null) &&
      setLoadingActivities(false);
  }, [activities]);

  /** Get activity list and folder */
  const fetchData = (_domain: Domain) => {
    // Only show activities created or updated 3 months before
    const newsLimitDate = new Date();
    newsLimitDate.setMonth(newsLimitDate.getMonth() - 3);
    getNews({
      variables: {
        date: newsLimitDate.toISOString()
      }
    });
  };

  /** Use effect folder query */
  useEffect(() => {
    if (data) {
      // Handle folder
      if (data.folders?.data?.length) {
        const folder = data.folders?.data.find(category =>
          category.path.startsWith(domain.fullPath)
        );
        setFolder(
          folder
            ? mapCategory(folder, undefined, user?.isSuperAdmin)
            : undefined
        );
      } else {
        setFolder(undefined);
      }
      setLoadingFolder(false);

      // Handle activities
      const _activities: Activity[] = [];
      for (const activity of [
        ...(data.newLessons?.data ?? []),
        ...(data.newQuizzes?.data ?? []),
        ...(data.updatedLessons?.data ?? []),
        ...(data.updatedQuizzes?.data ?? [])
      ]) {
        // Check if activity already added
        if (!activity || _activities.some(a => a._id === activity._id)) {
          continue;
        }

        // Map activity
        const _activity = mapActivity(activity);

        // Check if actity in domain and add
        if (_activity.domain.slug === domain.slug) {
          _activities.push(mapActivity(activity));
        }
      }

      setActivities(sortActivities(_activities));
      setLoadingActivities(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoadingActivities(false);
      setLoadingFolder(false);
    }
  }, [error]);

  useEffect(() => {
    const _domain = getDomainBySlug(match.params.domainSlug);

    if (canAccessDomain(_domain.learningId)) {
      setDomain(_domain);
      setLoadingActivities(true);
      setLoadingFolder(true);
      setStarterQuiz(starterQuizList[_domain.learningId]);
      fetchData(_domain);
    } else {
      dispatch(setErrorToHandleError(true, 401));
    }
  }, [match.params.domainSlug]);

  return (
    <div id="news">
      {showLockedNotification && (
        <div style={{ marginTop: -20 }}>
          <LockedBanner />
        </div>
      )}
      {domain ? (
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <title>{domain.title} - Actualités</title>
        </Helmet>
      ) : null}
      {starterQuiz && domain && (
        <div className={classes.container}>
          <StarterQuizCard
            id={starterQuiz._id}
            isStarted={starterQuiz.isStarted}
            domain={domain}
          />
        </div>
      )}
      {loadingFolder ? (
        <FakeCardFolder />
      ) : folder ? (
        <CardFolder
          folder={folder}
          locked={!!starterQuiz}
          onLockedClick={activateLockedNotification}
        />
      ) : null}
      {loadingActivities ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      ) : activities && activities.length > 0 ? (
        <ActivityList
          locked={!!starterQuiz}
          onLockedClick={activateLockedNotification}
          list={activities}
        />
      ) : null}
      {!loadingActivities && !loadingFolder && !folder && !activities ? (
        <NoResult />
      ) : null}
    </div>
  );
});

export default News;
