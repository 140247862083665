// React
import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router";
// Libs
import ContentLoader from "react-content-loader";
import Scrollbars from "react-custom-scrollbars";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";
import { isIPad13 } from "react-device-detect";
import clsx from "clsx";

// Material
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
// Components
import Lesson from "../Lesson/Lessons";
import ColorCircularProgress from "../../features/ColorCircularProgress/ColorCircularProgress";
import Timer from "../../features/Timer/Timer";
// Images
import logo from "../../assets/RF_Elearning_logo.png";
import icClose from "../../assets/ic-close.svg";
import GetAppIcon from "@material-ui/icons/GetApp";
// Styles
import "./ActivityLessonOrQuiz.scss";
// Services
import {
  getIsSuperAdmin,
  canAccessActivity
} from "../../services/user.service";
// Store
import { QuizPlayer } from "../Quiz/QuizPlayer";
import {
  BookmarkStatus,
  Quiz as QuizGql,
  useActivityLazyQuery
} from "../../graphql/index";
import { Activity } from "../../models/Activity.model";
import {
  getActivityWord,
  getLastBookmark,
  isLesson,
  isQuiz,
  mapActivity
} from "../../services/activity.service";
import Bilan from "../Quiz/Bilan/Bilan";
import StarterBilan from "../Quiz/Bilan/StarterBilan";
import { StoreState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { setErrorToHandleError } from "../../store/errorHandler/actions";
import { getDomainColor } from "../../helpers/domain.helper";
import { useAuthentication } from "@dsk-lib/user";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: "2001!important" as any
    },
    appBar: {
      backgroundColor: "#ffffff",
      [theme.breakpoints.up("md")]: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    appBarTextToLong: {
      backgroundColor: "#ffffff",
      paddingBottom: 5,
      paddingTop: 5,
      ["& > div:first-child"]: {
        minHeight: 114
      }
    },
    appBarTextToLongIpad: {
      backgroundColor: "#ffffff",
      paddingBottom: 5,
      paddingTop: 5,
      ["& > div:first-child"]: {
        minHeight: 160
      }
    },
    logo: {
      width: "200px"
    },
    headerTitle: {
      [theme.breakpoints.up("md")]: {
        textAlign: "center"
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(0.5)
      }
    },
    chipDomain: {
      color: "white",
      margin: theme.spacing(0.5)
    },
    button: {
      marginBottom: 2,
      marginLeft: theme.spacing(0.5),
      fontWeight: 600,
      textTransform: "none",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#7ebc4e",
        color: "#FFFFFF"
      }
    },
    activityContentLesson: {
      backgroundColor: "#f1f4f5",
      padding: 16
    },
    activityContentQuiz: {
      backgroundColor: "#f1f4f5",
      padding: 16,
      display: "flex",
      flexDirection: "column"
    },
    btgroup: {
      height: 40,
      marginRight: 10
    },
    textUp: {
      fontSize: 24,
      lineHeight: "normal",
      fontWeight: 600,
      color: "#9ea2aa",
      right: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderLeftWidth: 2,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        backgroundColor: "white"
      }
    },
    textDown: {
      fontSize: 16,
      fontWeight: 600,
      color: "#18202f",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 0,
        borderBottom: "none",
        borderTop: "none"
      },
      [theme.breakpoints.up("md")]: {
        borderRightWidth: 2,
        borderTopLeftRadius: 25,
        borderBottomLeftRadius: 25,
        backgroundColor: "white"
      }
    },
    chip: {
      borderRadius: 20,
      width: 90,
      backgroundColor: "white"
    },
    secondToolbar: {
      backgroundColor: "#f9fdfc"
    },
    headerSecondToolbar: {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },
    headerTitleResponsive: {
      whiteSpace: "nowrap"
    },
    video: {
      textAlign: "center"
    },
    subtitle: {
      fontSize: "inherit"
    }
  })
);

/**
 * Activity component
 */
const ActivityLessonOrQuiz: React.FC<any> = (props: any) => {
  const { match, history, location } = props;

  const [getActivity, { data }] = useActivityLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only"
  });

  /** Classes */
  const classes = useStyles();
  /** Activity */
  const [activity, setActivity] = useState<Activity>();
  /** Activity */
  const [quizGql, setQuizGql] = useState<QuizGql>();
  /** Show bilan */
  const [bilanScore, setBilanScore] = useState<number | null>(null);
  /** FontSize */
  const [fontSize, setFontSize] = useState(16);
  /** Loading state */
  const [loading, setLoading] = useState(true);
  /** Play tiemr */
  const [playTimer, setPlayTimer] = useState<boolean>(true);
  /** Keycloak */
  const { fetchWithCredentials } = useAuthentication();
  /** isSuperAdmin */
  const [isSuperAdmin, setIsSuperAdmin] = React.useState<boolean>(false);
  /** loadingWordFile */
  const [isLoadingWordFile, setIsLoadingWordFile] = React.useState(false);
  /** errorWordFile */
  const [isErrorWordFile, setIsErrorWordFile] = React.useState(false);
  /** use snackbar */
  const { enqueueSnackbar } = useSnackbar();

  /** Is starter */
  const isStarter = location.pathname.includes("/positionnement/");
  /** Starter quiz */
  const starterQuizList = useSelector(
    (state: StoreState) => state.starterQuizList
  );
  const dispatch = useDispatch();

  const contentRef = useRef<any>(null);

  /**
   * This function returns true or false if the text is longer than 70 characters.
   * @returns {"" | undefined | boolean}
   */
  const isLong = () => {
    return activity?.title && activity.title.length > 64;
  };
  const ifIsLong = isLong();

  /**
   * Handle close lesson
   */
  const handleCloseLesson = (): void => {
    history.goBack();
  };

  const handleQuizEnd = (score: number): void => {
    setBilanScore(score);
    fetchData();
  };

  /**
   * Increment size text
   */
  const incrementSizeText = (): void => {
    setFontSize(fontSize + 1);
  };

  /**
   * Decrement size text
   */
  const decrementSizeText = (): void => {
    setFontSize(fontSize - 1);
  };

  /**
   * Scroll to top between quiz exercises
   */
  const contentScrollTop = (): void => {
    if (contentRef?.current) contentRef.current.scrollTop = 0;
  };

  /**
   * Get activity as word file
   */
  const fetchWordFile = async () => {
    setIsLoadingWordFile(true);
    if (activity) {
      await getActivityWord(activity, location.pathname, fetchWithCredentials)
        .catch(() => {
          setIsErrorWordFile(true);
        })
        .finally(() => setIsLoadingWordFile(false));
    }
  };

  const generateTimer = (display: "chip" | "box", key: number) => {
    return (
      <Timer
        key={key}
        canPlayTimer={playTimer}
        display={display}
        initialTime={activity?.confirmedTime ?? 0}
        activityTime={activity?.estimatedTime ?? 0}
      />
    );
  };

  /**
   * Use effect error download file
   */
  useEffect(() => {
    if (isErrorWordFile) {
      enqueueSnackbar(
        "Il y a eu une erreur lors du téléchargement. Veuillez réessayer ultérieurement.",
        {
          variant: "error"
        }
      );
      setTimeout(() => {
        setIsErrorWordFile(false);
      }, 5000);
    }
  }, [isErrorWordFile]);

  /** Fetch data */
  const fetchData = async () => {
    const { params } = match;
    setLoading(true);

    getActivity({
      variables: {
        id: params.activityId,
        isQuiz: isStarter || params.type === "quiz"
      }
    });
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    /** params */
    setIsSuperAdmin(getIsSuperAdmin());
    fetchData();
  }, [match.params.id]);

  useEffect(() => {
    if (data) {
      if (data.quiz) {
        setActivity(mapActivity(data.quiz));
        setQuizGql(data.quiz);

        const lastBookmark = getLastBookmark(
          data.quiz?.bookmark ?? [],
          isStarter
        );

        if (
          lastBookmark &&
          !lastBookmark.current_question_id &&
          lastBookmark.status === BookmarkStatus.Done
        ) {
          setBilanScore(lastBookmark?.score ?? 0);
        } else {
          setBilanScore(null);
        }
      } else if (data.lesson) {
        setActivity(mapActivity(data.lesson));
        setBilanScore(null);
      } else {
        // Activity not found
        dispatch(setErrorToHandleError(true, 404));
      }
    }
  }, [data]);

  useEffect(() => {
    if (activity) {
      if (
        (!isStarter && starterQuizList[activity.domain.learningId]) ||
        !canAccessActivity(activity)
      ) {
        dispatch(setErrorToHandleError(true, 401));
      } else {
        setLoading(false);
      }
    }
  }, [activity]);

  return (
    <Dialog
      fullScreen={true}
      open={true}
      keepMounted={true}
      className={classes.root}
    >
      {loading ? (
        <ContentLoader
          height={780}
          width={1180}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="10" y="5" rx="10" ry="10" width="150" height="72" />
          <rect x="200" y="5" rx="10" ry="10" width="800" height="72" />
          <rect x="1050" y="5" rx="10" ry="10" width="100" height="72" />
          <rect x="200" y="100" rx="10" ry="10" width="800" height="600" />
        </ContentLoader>
      ) : activity ? (
        <React.Fragment>
          <Helmet defer={false}>
            <meta charSet="utf-8" />
            <title>
              {activity.domain.title} - activité : {activity.title}
            </title>
          </Helmet>
          <Hidden smDown={true}>
            <AppBar
              position="static"
              className={clsx({
                [classes.appBar]: !ifIsLong,
                [classes.appBarTextToLong]: ifIsLong,
                [classes.appBarTextToLongIpad]: isIPad13
              })}
            >
              <Toolbar>
                <Grid
                  container={true}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item={true} xs={true}>
                    <img
                      src={logo}
                      className={classes.logo}
                      alt="rf-elearning"
                    />
                  </Grid>
                  <Grid item={true} xs={6} className={classes.headerTitle}>
                    <Chip
                      size="small"
                      label={activity.domain.title}
                      className={classes.chipDomain}
                      style={{
                        backgroundColor: getDomainColor(activity.domain)
                      }}
                    />
                    <Typography variant="h5" gutterBottom={true}>
                      {activity.title}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Grid
                      container={true}
                      alignItems="flex-start"
                      justifyContent="flex-end"
                      direction="row"
                    >
                      {isSuperAdmin ? (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => fetchWordFile()}
                        >
                          {isLoadingWordFile ? (
                            <ColorCircularProgress />
                          ) : (
                            <GetAppIcon />
                          )}
                          Télécharger le Word
                        </Button>
                      ) : null}
                      {!isStarter && (
                        <Button
                          variant="outlined"
                          className={classes.button}
                          onClick={() => handleCloseLesson()}
                        >
                          Quitter l'activité
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          </Hidden>

          <Hidden mdUp={true}>
            <AppBar position="static" className={classes.appBar}>
              <Grid container={true} direction="column">
                <Toolbar className={classes.headerSecondToolbar}>
                  <Grid container={true} alignItems="center">
                    <Grid item={true} xs={1}>
                      <img
                        src={icClose}
                        alt="close"
                        onClick={() => history.goBack()}
                      />
                    </Grid>
                    <Grid item={true} xs={11} className={classes.headerTitle}>
                      <Scrollbars style={{ height: 40 }}>
                        <Typography
                          variant="h6"
                          gutterBottom={true}
                          className={classes.headerTitleResponsive}
                        >
                          {activity.title}
                        </Typography>
                      </Scrollbars>
                    </Grid>
                  </Grid>
                </Toolbar>

                <Grid
                  container={true}
                  direction="row"
                  alignItems="baseline"
                  className={classes.secondToolbar}
                >
                  <Grid item={true} xs={9}>
                    {isLesson(activity) ? generateTimer("box", 1) : null}
                  </Grid>
                  {isLesson(activity) && activity?.nature === "VIDEO" ? (
                    <Grid item={true} xs={3}>
                      <Box m={1}>
                        <Grid
                          container={true}
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <ButtonGroup
                            size="medium"
                            variant="outlined"
                            className={classes.btgroup}
                          >
                            <Button
                              className={classes.textDown}
                              onClick={decrementSizeText}
                            >
                              A
                            </Button>
                            <Button
                              className={classes.textUp}
                              onClick={incrementSizeText}
                            >
                              A
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </AppBar>
          </Hidden>

          <DialogContent
            ref={contentRef}
            className={
              isLesson(activity)
                ? classes.activityContentLesson
                : classes.activityContentQuiz
            }
          >
            <Hidden smDown={true}>
              {isLesson(activity) && activity.nature !== "VIDEO" ? (
                <Grid
                  container={true}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item={true} xs={true} />
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    <Grid
                      container={true}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <ButtonGroup
                        size="medium"
                        variant="outlined"
                        className={classes.btgroup}
                      >
                        <Button
                          className={classes.textDown}
                          onClick={decrementSizeText}
                        >
                          A
                        </Button>
                        <Button
                          className={classes.textUp}
                          onClick={incrementSizeText}
                        >
                          A
                        </Button>
                      </ButtonGroup>
                      {generateTimer("chip", 2)}
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={true} />
                </Grid>
              ) : (
                <Grid container={true}>
                  <Grid item={true} xs={2} />
                  <Grid item={true} xs={8} className={classes.headerTitle}>
                    {isLesson(activity) ? generateTimer("chip", 3) : null}
                  </Grid>
                  <Grid item={true} xs={2} />
                </Grid>
              )}
            </Hidden>

            {isLesson(activity) && (
              <Lesson
                lesson={activity}
                fontSize={fontSize}
                playTimer={setPlayTimer}
              />
            )}
            {isQuiz(activity) && isStarter && bilanScore !== null && (
              <StarterBilan
                handleClose={() => handleCloseLesson()}
                quiz={quizGql as any}
                score={bilanScore}
              />
            )}
            {isQuiz(activity) && !isStarter && bilanScore !== null && (
              <Bilan
                handleClose={() => handleCloseLesson()}
                handleRestart={() => setBilanScore(null)}
                score={bilanScore}
                quiz={quizGql as any}
              />
            )}
            {isQuiz(activity) && bilanScore === null && (
              <QuizPlayer
                data={quizGql as any}
                quiz={activity}
                domain={activity.domain}
                withStarterCard={isStarter && location?.state?.starterInfo}
                isStarter={isStarter}
                isDone={activity.isDone}
                onQuizEnd={handleQuizEnd}
                onExerciseChange={contentScrollTop}
              />
            )}
          </DialogContent>
        </React.Fragment>
      ) : null}
    </Dialog>
  );
};

export default withRouter(ActivityLessonOrQuiz);
