// React
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

// Material - ui
import { Collapse, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";

// Component
import CardCategory from "../CardCategory/CardCategory";
import CardSubCategory from "../CardSubCategory/CardSubCategory";
import CardSubject from "../CardSubject/CardSubject";

// Models
import { Category } from "../../models/category.model";
import { Domain } from "../../models/domain.model";

/**
 * Styles
 */
const useStyles = makeStyles(() =>
  createStyles({
    cardCategory: {
      width: "100%",
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 10
    },
    cardSubCategory: {
      width: "100%",
      zIndex: 10
    },
    cardSubCategoryCollapse: {
      paddingLeft: 20,
      width: "100%"
    },
    cardSubject: {
      width: "100%"
    }
  })
);

/**
 * CardCategory props
 */
interface BaseSkillsItemProps {
  category: Category;
  isLevelCategory: boolean;
  categoryListOpened: Category[];
  domain: Domain;
  locked?: boolean;
  onLockedClick?: () => void;
}

/**
 * Card category
 */
const BaseSkillsItem = ({
  category,
  isLevelCategory,
  categoryListOpened,
  domain,
  locked,
  onLockedClick
}: BaseSkillsItemProps) => {
  /** state variable open */
  const [open, setOpen] = useState(false);
  /** Classes */
  const classes = useStyles();

  /**
   * Has children
   * @param category
   */
  function hasChildren(element: Category): boolean {
    return element && element.childrensData
      ? element.childrensData.length > 0
      : false;
  }

  useEffect(() => {
    setOpen(categoryListOpened.some(cat => cat._id === category._id));
  }, [categoryListOpened, category]);

  return (
    <React.Fragment>
      {hasChildren(category) ? (
        <ListItem
          className={classes.cardCategory}
          button={true}
          disableGutters={true}
          onClick={() => setOpen(!open)}
        >
          {isLevelCategory ? (
            <CardCategory
              key={category._id}
              category={category}
              domain={domain}
              isExpandLess={open}
            />
          ) : (
            <div className={classes.cardSubCategory}>
              <CardSubCategory
                key={category._id}
                category={category}
                isExpandLess={open}
                domain={domain}
              />
            </div>
          )}
        </ListItem>
      ) : (
        <ListItem disableGutters={true}>
          <div id={category._id} className={classes.cardSubject}>
            <CardSubject
              key={category._id}
              category={category}
              domain={domain}
              locked={locked}
              onLockedClick={onLockedClick}
            />
          </div>
        </ListItem>
      )}
      {hasChildren(category) && category.childrensMapped
        ? category.childrensMapped.map((subcategory: Category) => (
            <Collapse
              key={subcategory._id}
              className={classes.cardSubCategoryCollapse}
              in={open}
              timeout="auto"
              unmountOnExit={true}
            >
              <List disablePadding={true} component="div">
                <BaseSkillsItem
                  key={subcategory._id}
                  category={subcategory}
                  isLevelCategory={false}
                  categoryListOpened={categoryListOpened}
                  domain={domain}
                  locked={locked}
                  onLockedClick={onLockedClick}
                />
              </List>
            </Collapse>
          ))
        : null}
    </React.Fragment>
  );
};

export default React.memo(BaseSkillsItem);
