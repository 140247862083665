import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { RouteComponentProps } from "react-router";

// Material:
import { Box } from "@material-ui/core";

// Store
import { useSelector } from "react-redux";
import { StoreState } from "../../store";

// Components
import ActivityList from "../../features/ActivityList/ActivityList";
import { FakeCardActivity } from "../../features/CardActivity/CardActivity";
import CardFolder, {
  FakeCardFolder
} from "../../features/CardFolder/CardFolder";

// Model
import { Activity } from "../../models/Activity.model";
import { User } from "../../models/user.model";

// Keycloak
import { Category } from "../../models/category.model";
import { mapCategory } from "../../services/category.service";

import { mapActivity, sortActivities } from "../../services/activity.service";
import { getDomainById } from "../../helpers/domain.helper";
import { useNewsLazyQuery } from "../../graphql";

/**
 * Home component
 */
const Home = ({ history }: RouteComponentProps) => {
  /** Folder state   */
  const [folders, setFolders] = useState<Category[]>([]);
  /** Activity list state */
  const [activities, setActivities] = useState<Activity[]>();
  /** GraphQL */
  const [getHome, { data, error }] = useNewsLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "cache-and-network"
  });
  /** Processing time state */
  const user: User | null = useSelector((state: StoreState) => state.user);
  /** Loading states */
  const [loadingActivities, setLoadingActivities] = useState(true);
  const [loadingFolder, setLoadingFolder] = useState(true);

  /**
   * Fetch Home Data
   */
  const fetchData = () => {
    // Only show activities created or updated 3 months before
    const newsLimitDate = new Date();
    newsLimitDate.setMonth(newsLimitDate.getMonth() - 3);
    getHome({
      variables: {
        date: newsLimitDate.toISOString()
      }
    });
  };

  /**
   * Use effect get activity list
   */
  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      fetchData();
    }

    return function cleanup() {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (data) {
      // Handle folders
      const _folders: Category[] = [];

      for (const category of data.folders?.data ?? []) {
        if (
          !category ||
          (!user?.isSuperAdmin &&
            !category?.lessons?.length &&
            !category?.quizzes?.length)
        ) {
          // Don't show if no activity for normal users
          continue;
        }

        if (_folders.some(f => category.path.includes(f.domain.slug))) {
          // Don't add if already a folder for this domain
          continue;
        }
        _folders.push(mapCategory(category, undefined, user?.isSuperAdmin));
      }
      setFolders(_folders);
      setLoadingFolder(false);

      // Handle activities
      const _activities: Activity[] = [];
      for (const activity of [
        ...(data.newLessons?.data ?? []),
        ...(data.newQuizzes?.data ?? []),
        ...(data.updatedLessons?.data ?? []),
        ...(data.updatedQuizzes?.data ?? [])
      ]) {
        if (!activity || _activities.some(a => a._id === activity._id))
          continue;

        _activities.push(mapActivity(activity));
      }

      setActivities(sortActivities(_activities));
      setLoadingActivities(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setLoadingActivities(false);
      setLoadingFolder(false);
    }
  }, [error]);

  useEffect(() => {
    if (
      user?.isBlended &&
      !user.subscription.fullDomainsAccessEl.length &&
      user.subscription.domainsAccessEl.length
    ) {
      const firstDomain = getDomainById(user.subscription.domainsAccessEl[0]);
      history.push("categorie/" + firstDomain.slug + "/competences");
    }
  }, [user]);

  return (
    <div>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>À la une</title>
      </Helmet>
      {loadingFolder ? (
        <Box marginBottom={15}>
          <FakeCardFolder />
        </Box>
      ) : (
        folders.map(folder => (
          <Box marginTop={1} key={folder._id}>
            <CardFolder folder={folder} />
          </Box>
        ))
      )}
      {loadingActivities ? (
        <Box marginTop={3}>
          <FakeCardActivity />
        </Box>
      ) : activities && activities.length > 0 ? (
        <Box marginBottom={15}>
          <ActivityList list={activities} />
        </Box>
      ) : null}
    </div>
  );
};

export default Home;
