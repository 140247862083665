import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Json: any;
};

export enum AccessType {
  Free = "FREE",
  Premium = "PREMIUM",
  FreeComplete = "FREE_COMPLETE"
}

export type ActivitiesSlugs = {
  __typename?: "ActivitiesSlugs";
  slugs: Array<Scalars["String"]>;
};

export enum ActivityType {
  Lesson = "LESSON",
  Quiz = "QUIZ",
  Flashcardv2 = "FLASHCARDV2",
  PastPaper = "PAST_PAPER"
}

export type Address = {
  __typename?: "Address";
  streetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  shippingInstructions?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  streetNumber?: Maybe<Scalars["String"]>;
  streetName?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  shippingInstructions?: Maybe<Scalars["String"]>;
};

export type AdminReservationInput = {
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
  ssoIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  after?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type AdminSubscriptionListInput = {
  ssoIds?: Maybe<Array<Scalars["String"]>>;
};

export type AndroidSubscriptionPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: "AndroidSubscriptionPrepaidGoodSource";
  type: PrepaidGoodSourceType;
  androidSubscriptionId: Scalars["String"];
};

export type Answer = {
  __typename?: "Answer";
  _id: Scalars["String"];
  learningId: Scalars["Int"];
  audioText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  isCorrect: Scalars["Boolean"];
  /**
   * # Deprecated
   * Use `gapIndex`
   * @deprecated Use `gapIndex`
   */
  index?: Maybe<Scalars["String"]>;
  gapIndex?: Maybe<Scalars["String"]>;
};

export type Area = {
  __typename?: "Area";
  id: Scalars["String"];
  name: Scalars["String"];
  coordinates: AreaCoordinates;
};

export type AreaCoordinates = {
  __typename?: "AreaCoordinates";
  topLeft: Array<Scalars["Int"]>;
  bottomRight: Array<Scalars["Int"]>;
};

export type AssetLink = {
  __typename?: "AssetLink";
  type?: Maybe<Scalars["String"]>;
  media?: Maybe<Media>;
};

export type Attachment = {
  __typename?: "Attachment";
  name: Scalars["String"];
  content: Scalars["String"];
};

export type AttachmentInput = {
  name: Scalars["String"];
  content: Scalars["String"];
};

export type Author = {
  __typename?: "Author";
  _id: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  userName: Scalars["String"];
};

export enum Availability {
  Available = "AVAILABLE",
  Unavailable = "UNAVAILABLE"
}

export type AvailableReviewsInput = {
  activityType: ActivityType;
  language: Language;
};

export type BacFrenchStylisticDeviceFlashcard = FlashcardV2 & {
  __typename?: "BacFrenchStylisticDeviceFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<BacFrenchStylisticDeviceFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type BacFrenchStylisticDeviceFlashcardContent = {
  __typename?: "BacFrenchStylisticDeviceFlashcardContent";
  definition?: Maybe<Scalars["String"]>;
  example?: Maybe<Scalars["String"]>;
  stylisticDevice?: Maybe<Scalars["String"]>;
};

export type BacHistoryLandmarkFlashcard = FlashcardV2 & {
  __typename?: "BacHistoryLandmarkFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<BacHistoryLandmarkFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type BacHistoryLandmarkFlashcardContent = {
  __typename?: "BacHistoryLandmarkFlashcardContent";
  landmark?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
};

export type BacPhilosophyQuoteFlashcard = FlashcardV2 & {
  __typename?: "BacPhilosophyQuoteFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<BacPhilosophyQuoteFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type BacPhilosophyQuoteFlashcardContent = {
  __typename?: "BacPhilosophyQuoteFlashcardContent";
  quote?: Maybe<Scalars["String"]>;
  author?: Maybe<Scalars["String"]>;
};

export type BillingAddress = {
  __typename?: "BillingAddress";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  gender: Gender;
  address: Address;
};

export type BillingAddressInput = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
};

export enum BookmarkProduct {
  Codedelaroute = "codedelaroute",
  Feuvert = "feuvert",
  Superbac = "superbac",
  Superbrevet = "superbrevet",
  Rfformation = "rfformation",
  Actuacac = "actuacac",
  Projetvoltaire = "projetvoltaire",
  Fle = "fle",
  Toeic = "toeic",
  Orthographe = "orthographe",
  Mosaseries = "mosaseries",
  Concours = "concours",
  Primaire = "primaire",
  Bts = "bts",
  College = "college",
  Lycee = "lycee"
}

export enum BookmarkSection {
  Auto = "AUTO",
  Moto = "MOTO",
  Cyclo = "CYCLO",
  Tpr = "TPR",
  SecondeGenerale = "SECONDE_GENERALE",
  PremiereGenerale = "PREMIERE_GENERALE",
  TerminaleGenerale = "TERMINALE_GENERALE",
  TerminaleSt2S = "TERMINALE_ST2S",
  PremiereSt2S = "PREMIERE_ST2S",
  TerminaleSti2D = "TERMINALE_STI2D",
  PremiereSti2D = "PREMIERE_STI2D",
  TerminaleStl = "TERMINALE_STL",
  PremiereStl = "PREMIERE_STL",
  TerminaleStmg = "TERMINALE_STMG",
  PremiereStmg = "PREMIERE_STMG",
  BacPro = "BAC_PRO",
  Sixieme = "SIXIEME",
  Cinquieme = "CINQUIEME",
  Quatrieme = "QUATRIEME",
  Troisieme = "TROISIEME",
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  Anglais = "ANGLAIS",
  Francais = "FRANCAIS",
  Espagnol = "ESPAGNOL",
  Allemand = "ALLEMAND",
  Italien = "ITALIEN",
  Portugais = "PORTUGAIS",
  MiddleSchool = "MIDDLE_SCHOOL",
  HighSchool = "HIGH_SCHOOL",
  Student = "STUDENT",
  Professional = "PROFESSIONAL",
  JobSeeker = "JOB_SEEKER",
  Cp = "CP",
  Ce1 = "CE1",
  Ce2 = "CE2",
  Cm1 = "CM1",
  Cm2 = "CM2",
  BtsAmenagementFinition = "BTS_AMENAGEMENT_FINITION",
  BtsAnalysesDeBiologieMedicale = "BTS_ANALYSES_DE_BIOLOGIE_MEDICALE",
  BtsArchitecturesEnMetalConceptionEtRealisation = "BTS_ARCHITECTURES_EN_METAL_CONCEPTION_ET_REALISATION",
  BtsAssistanceTechniqueDIngenieur = "BTS_ASSISTANCE_TECHNIQUE_D_INGENIEUR",
  BtsAssurance = "BTS_ASSURANCE",
  BtsAeronautique = "BTS_AERONAUTIQUE",
  BtsaAgronomieProductionsVegetales = "BTSA_AGRONOMIE_PRODUCTIONS_VEGETALES",
  BtsaAmenagementsPaysagers = "BTSA_AMENAGEMENTS_PAYSAGERS",
  BtsaAnalyseConduiteEtStrategieDeLEntrepriseAgricole = "BTSA_ANALYSE_CONDUITE_ET_STRATEGIE_DE_L_ENTREPRISE_AGRICOLE",
  BtsaAnalysesAgricolesBiologiquesEtBiotechnologies = "BTSA_ANALYSES_AGRICOLES_BIOLOGIQUES_ET_BIOTECHNOLOGIES",
  BtsaAquaculture = "BTSA_AQUACULTURE",
  BtsBanqueConseillerDeClientele = "BTS_BANQUE_CONSEILLER_DE_CLIENTELE",
  BtsBioanalysesEtContr = "BTS_BIOANALYSES_ET_CONTR",
  BtsBiotechnologies = "BTS_BIOTECHNOLOGIES",
  BtsBatiment = "BTS_BATIMENT",
  BtsEnInformatique = "BTS_EN_INFORMATIQUE",
  BtsEnSecretariat = "BTS_EN_SECRETARIAT",
  BtsInnovationTextile = "BTS_INNOVATION_TEXTILE",
  BtsManagementOperationnelDeLaSecurite = "BTS_MANAGEMENT_OPERATIONNEL_DE_LA_SECURITE",
  BtsMetiersDeLaModeChaussureEtMaroquinerie = "BTS_METIERS_DE_LA_MODE_CHAUSSURE_ET_MAROQUINERIE",
  BtsCommerceInternationalAReferentielCommunEuropeen = "BTS_COMMERCE_INTERNATIONAL_A_REFERENTIEL_COMMUN_EUROPEEN",
  BtsCommunication = "BTS_COMMUNICATION",
  BtsComptabiliteEtGestion = "BTS_COMPTABILITE_ET_GESTION",
  BtsConceptionDesProcessusDeDecoupeEtDEmboutissage = "BTS_CONCEPTION_DES_PROCESSUS_DE_DECOUPE_ET_D_EMBOUTISSAGE",
  BtsConceptionDesProcessusDeRealisationDeProduits = "BTS_CONCEPTION_DES_PROCESSUS_DE_REALISATION_DE_PRODUITS",
  BtsConceptionDesProduitsIndustriels = "BTS_CONCEPTION_DES_PRODUITS_INDUSTRIELS",
  BtsConceptionEtIndustrialisationEnConstructionNavale = "BTS_CONCEPTION_ET_INDUSTRIALISATION_EN_CONSTRUCTION_NAVALE",
  BtsConceptionEtIndustrialisationEnMicrotechniques = "BTS_CONCEPTION_ET_INDUSTRIALISATION_EN_MICROTECHNIQUES",
  BtsConceptionEtRealisationDeCarrosseries = "BTS_CONCEPTION_ET_REALISATION_DE_CARROSSERIES",
  BtsConceptionEtRealisationDeSystemesAutomatiques = "BTS_CONCEPTION_ET_REALISATION_DE_SYSTEMES_AUTOMATIQUES",
  BtsConceptionEtRealisationEnChaudronnerieIndustrielle = "BTS_CONCEPTION_ET_REALISATION_EN_CHAUDRONNERIE_INDUSTRIELLE",
  BtsControleDesRayonnementsIonisantsEtApplicationDesTechniquesDeProtection = "BTS_CONTROLE_DES_RAYONNEMENTS_IONISANTS_ET_APPLICATION_DES_TECHNIQUES_DE_PROTECTION",
  BtsControleIndustrielEtRegulationAutomatique = "BTS_CONTROLE_INDUSTRIEL_ET_REGULATION_AUTOMATIQUE",
  BtsDietetique = "BTS_DIETETIQUE",
  BtsDeveloppementEtRealisationBois = "BTS_DEVELOPPEMENT_ET_REALISATION_BOIS",
  BtsaDeveloppementDeLAgricultureDesRegionsChaudes = "BTSA_DEVELOPPEMENT_DE_L_AGRICULTURE_DES_REGIONS_CHAUDES",
  BtsaDeveloppementAnimationDesTerritoiresRuraux = "BTSA_DEVELOPPEMENT_ANIMATION_DES_TERRITOIRES_RURAUX",
  BtsEnveloppeDesB = "BTS_ENVELOPPE_DES_B",
  BtsEnvironnementNucleaire = "BTS_ENVIRONNEMENT_NUCLEAIRE",
  BtsEuroplasticsEtComposites = "BTS_EUROPLASTICS_ET_COMPOSITES",
  BtsEconomieSocialeEtFamiliale = "BTS_ECONOMIE_SOCIALE_ET_FAMILIALE",
  BtsEdition = "BTS_EDITION",
  BtsElectrotechnique = "BTS_ELECTROTECHNIQUE",
  BtsEtudeEtRealisationDAgencement = "BTS_ETUDE_ET_REALISATION_D_AGENCEMENT",
  BtsEtudesDeRealisationDUnProjetDeCommunication = "BTS_ETUDES_DE_REALISATION_D_UN_PROJET_DE_COMMUNICATION",
  BtsEtudesEtTravauxGeographiques = "BTS_ETUDES_ET_TRAVAUX_GEOGRAPHIQUES",
  BtsEtudesEtEconomieDeLaConstruction = "BTS_ETUDES_ET_ECONOMIE_DE_LA_CONSTRUCTION",
  BtsFluidesEnergiesDomotique = "BTS_FLUIDES_ENERGIES_DOMOTIQUE",
  BtsFonderie = "BTS_FONDERIE",
  BtsForge = "BTS_FORGE",
  BtsGestionDeLaPme = "BTS_GESTION_DE_LA_PME",
  BtsGeologieAppliquee = "BTS_GEOLOGIE_APPLIQUEE",
  BtsaGestionEtMa = "BTSA_GESTION_ET_MA",
  BtsaGestionEtProtectionDeLaNature = "BTSA_GESTION_ET_PROTECTION_DE_LA_NATURE",
  BtsaGestionForestiere = "BTSA_GESTION_FORESTIERE",
  BtsaGenieDesEquipementsAgricoles = "BTSA_GENIE_DES_EQUIPEMENTS_AGRICOLES",
  BtsIndustriesCeramiques = "BTS_INDUSTRIES_CERAMIQUES",
  BtsMaintenanceDesMaterielsDeConstructionEtDeManutention = "BTS_MAINTENANCE_DES_MATERIELS_DE_CONSTRUCTION_ET_DE_MANUTENTION",
  BtsMaintenanceDesSystemes = "BTS_MAINTENANCE_DES_SYSTEMES",
  BtsMaintenanceDesVehicules = "BTS_MAINTENANCE_DES_VEHICULES",
  BtsMco = "BTS_MCO",
  BtsManagementEnHotellerieRestauration = "BTS_MANAGEMENT_EN_HOTELLERIE_RESTAURATION",
  BtsMasen = "BTS_MASEN",
  BtsPgem = "BTS_PGEM",
  BtsMoteursACombustionInterne = "BTS_MOTEURS_A_COMBUSTION_INTERNE",
  BtsMetiersDeLEsthetiqueCosmetiqueParfumerie = "BTS_METIERS_DE_L_ESTHETIQUE_COSMETIQUE_PARFUMERIE",
  BtsMetiersDeLaChimie = "BTS_METIERS_DE_LA_CHIMIE",
  BtsMetiersDeLaCoiffure = "BTS_METIERS_DE_LA_COIFFURE",
  BtsMetiersDeLaModeV = "BTS_METIERS_DE_LA_MODE_V",
  BtsMetiersDeLAudiovisuel = "BTS_METIERS_DE_L_AUDIOVISUEL",
  BtsMetiersDeLEau = "BTS_METIERS_DE_L_EAU",
  BtsMetiersDesServicesAlEnvironnement = "BTS_METIERS_DES_SERVICES_AL_ENVIRONNEMENT",
  BtsMetiersDuGeometreTopographeEtDeLaModelisationNumerique = "BTS_METIERS_DU_GEOMETRE_TOPOGRAPHE_ET_DE_LA_MODELISATION_NUMERIQUE",
  BtsNotariat = "BTS_NOTARIAT",
  BtsNegociationEtDigitalisationDeLaRelationClient = "BTS_NEGOCIATION_ET_DIGITALISATION_DE_LA_RELATION_CLIENT",
  BtsOpticienLunetier = "BTS_OPTICIEN_LUNETIER",
  BtsPhotographie = "BTS_PHOTOGRAPHIE",
  BtsPilotageDeProcedes = "BTS_PILOTAGE_DE_PROCEDES",
  BtsPodoOrthesiste = "BTS_PODO_ORTHESISTE",
  BtsProfessionsImmobilieres = "BTS_PROFESSIONS_IMMOBILIERES",
  BtsProthesisteDentaire = "BTS_PROTHESISTE_DENTAIRE",
  BtsProthesisteOrthesiste = "BTS_PROTHESISTE_ORTHESISTE",
  BtsaProductionHorticole = "BTSA_PRODUCTION_HORTICOLE",
  BtsaProductionsAnimales = "BTSA_PRODUCTIONS_ANIMALES",
  BtsBioqualite = "BTS_BIOQUALITE",
  BtsServicesEtPrestationsDesSecteursSanitaireEtSocial = "BTS_SERVICES_ET_PRESTATIONS_DES_SECTEURS_SANITAIRE_ET_SOCIAL",
  BtsSioSisr = "BTS_SIO_SISR",
  BtsSioSlam = "BTS_SIO_SLAM",
  BtsSupportAlActionManageriale = "BTS_SUPPORT_AL_ACTION_MANAGERIALE",
  BtsSystemesConstructifsBoisEtHabitat = "BTS_SYSTEMES_CONSTRUCTIFS_BOIS_ET_HABITAT",
  BtsSystemesNumeriques = "BTS_SYSTEMES_NUMERIQUES",
  BtsSystemesPhotoniques = "BTS_SYSTEMES_PHOTONIQUES",
  BtsaSciencesEtTechnologiesDesAliments = "BTSA_SCIENCES_ET_TECHNOLOGIES_DES_ALIMENTS",
  BtsGestionDesTransportsEtLogistiqueAssociee = "BTS_GESTION_DES_TRANSPORTS_ET_LOGISTIQUE_ASSOCIEE",
  BtsTechnicoCommercial = "BTS_TECHNICO_COMMERCIAL",
  BtsTechniquesEtServicesEnMaterielsAgricoles = "BTS_TECHNIQUES_ET_SERVICES_EN_MATERIELS_AGRICOLES",
  BtsTechniquesPhysiquesPourLIndustrieEtLeLaboratoire = "BTS_TECHNIQUES_PHYSIQUES_POUR_L_INDUSTRIE_ET_LE_LABORATOIRE",
  BtsTourisme = "BTS_TOURISME",
  BtsTraitementDesMateriaux = "BTS_TRAITEMENT_DES_MATERIAUX",
  BtsTravauxPublics = "BTS_TRAVAUX_PUBLICS",
  BtsaTechnicoCommercial = "BTSA_TECHNICO_COMMERCIAL",
  BtsaViticultureOenologie = "BTSA_VITICULTURE_OENOLOGIE"
}

export enum BookmarkStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Done = "done"
}

export enum BookmarkType {
  Standard = "standard",
  MockExam = "mock_exam",
  FeuvertMortSubite = "feuvert_mort_subite",
  MortSubite = "mort_subite",
  PlacementTest = "placement_test",
  ProgressionTest = "progression_test",
  SuperQuiz = "super_quiz"
}

export type BrevetChemistryMoleculeFlashcard = FlashcardV2 & {
  __typename?: "BrevetChemistryMoleculeFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<BrevetChemistryMoleculeFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type BrevetChemistryMoleculeFlashcardContent = {
  __typename?: "BrevetChemistryMoleculeFlashcardContent";
  name?: Maybe<Scalars["String"]>;
  formula?: Maybe<Scalars["String"]>;
  composition?: Maybe<Scalars["String"]>;
  model?: Maybe<File>;
};

export type BrevetFrenchStylisticDeviceFlashcard = FlashcardV2 & {
  __typename?: "BrevetFrenchStylisticDeviceFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<BrevetFrenchStylisticDeviceFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type BrevetFrenchStylisticDeviceFlashcardContent = {
  __typename?: "BrevetFrenchStylisticDeviceFlashcardContent";
  definition?: Maybe<Scalars["String"]>;
  example?: Maybe<Scalars["String"]>;
  stylisticDevice?: Maybe<Scalars["String"]>;
};

export type BrevetHistoryLandmarkFlashcard = FlashcardV2 & {
  __typename?: "BrevetHistoryLandmarkFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<BrevetHistoryLandmarkFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type BrevetHistoryLandmarkFlashcardContent = {
  __typename?: "BrevetHistoryLandmarkFlashcardContent";
  landmark?: Maybe<Scalars["String"]>;
  date?: Maybe<Scalars["String"]>;
};

export type Brief = {
  __typename?: "Brief";
  _id: Scalars["String"];
  learningId?: Maybe<Scalars["Int"]>;
  html?: Maybe<Scalars["String"]>;
  markdown?: Maybe<Scalars["String"]>;
  status?: Maybe<Status>;
  products?: Maybe<Array<Maybe<GutenbergProduct>>>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  parentCategories?: Maybe<Array<Maybe<Category>>>;
};

export type BriefsFromCategorySearchInput = {
  status?: Maybe<Status>;
};

export type BriefsSearchInput = {
  learningIds?: Maybe<Array<Scalars["Int"]>>;
  status?: Maybe<Status>;
};

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE"
}

export type Cart = {
  __typename?: "Cart";
  _id: Scalars["String"];
  ssoId: Scalars["String"];
  customerId: Scalars["String"];
  lines: Array<Maybe<CartLine>>;
  coupon?: Maybe<Scalars["String"]>;
  totalPrice: Scalars["Int"];
  discount: Scalars["Int"];
  createdAt?: Maybe<Scalars["String"]>;
  resolvedAt?: Maybe<Scalars["String"]>;
  paidAt?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["Json"]>;
};

export type CartInput = {
  customerEmail?: Maybe<Scalars["String"]>;
  lines: Array<Maybe<CartLineInput>>;
  coupon?: Maybe<Scalars["String"]>;
  metadata?: Maybe<CartMetadata>;
};

export type CartIntent = {
  __typename?: "CartIntent";
  id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  customer?: Maybe<Scalars["String"]>;
};

export type CartLine = {
  __typename?: "CartLine";
  quantity: Scalars["Int"];
  item: Scalars["String"];
};

export type CartLineInput = {
  quantity: Scalars["Int"];
  item: Scalars["String"];
};

export type CartLineV2Input = {
  quantity: Scalars["Int"];
  priceId: Scalars["String"];
};

export type CartMetadata = {
  reservationId: Scalars["String"];
};

export type CartPatchInput = {
  id: Scalars["String"];
  lines: Array<Maybe<CartLineInput>>;
  coupon?: Maybe<Scalars["String"]>;
  metadata?: Maybe<CartMetadata>;
};

export type CartPatchV2Input = {
  id: Scalars["String"];
  lines: Array<Maybe<CartLineV2Input>>;
  couponCode?: Maybe<Scalars["String"]>;
};

export type CartPaypalApprove = {
  __typename?: "CartPaypalApprove";
  approvalUrls: Array<Scalars["String"]>;
};

export type CartPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: "CartPrepaidGoodSource";
  type: PrepaidGoodSourceType;
  cartId: Scalars["String"];
};

export type CartStripeIntent = {
  __typename?: "CartStripeIntent";
  id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  customer?: Maybe<Scalars["String"]>;
};

export type CartV2 = {
  __typename?: "CartV2";
  _id: Scalars["String"];
  ssoId?: Maybe<Scalars["String"]>;
  customerEmail?: Maybe<Scalars["String"]>;
  lines: Array<CartV2Line>;
  couponId?: Maybe<Scalars["String"]>;
  coupon?: Maybe<Coupon>;
  totalPrice: Scalars["Int"];
  discount: Scalars["Int"];
  createdAt: Scalars["String"];
  resolvedAt?: Maybe<Scalars["String"]>;
  paidAt?: Maybe<Scalars["String"]>;
  metadata?: Maybe<Scalars["Json"]>;
  tenant: TenantType;
};

export type CartV2Input = {
  customerEmail?: Maybe<Scalars["String"]>;
  lines: Array<Maybe<CartLineV2Input>>;
  couponCode?: Maybe<Scalars["String"]>;
};

export type CartV2Line = {
  __typename?: "CartV2Line";
  quantity: Scalars["Int"];
  priceId: Scalars["String"];
  price: PriceUnion;
};

export type CartV2LinePriceArgs = {
  tenant?: Maybe<TenantType>;
};

export type CartV2Metadata = {
  reservationId: Scalars["String"];
};

export type CartsInput = {
  isResolved?: Maybe<Scalars["Boolean"]>;
};

export type CartsV2Input = {
  isResolved?: Maybe<Scalars["Boolean"]>;
};

export type CategoriesChildrenDataV2SearchInput = {
  status?: Maybe<Status>;
  platform?: Maybe<CategoryPlatformFilter>;
};

export type CategoriesV2SearchInput = {
  parentIdOrPath?: Maybe<Scalars["String"]>;
  status?: Maybe<Status>;
  platform?: Maybe<CategoryPlatformFilter>;
  tags?: Maybe<Array<Scalars["String"]>>;
};

export type Category = {
  __typename?: "Category";
  _id: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  path: Scalars["String"];
  learningId: Scalars["Int"];
  status: Status;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  parent?: Maybe<Category>;
  children?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** **DEPRECATED:** use `childrenDataV2` instead */
  childrensData?: Maybe<Array<Maybe<Category>>>;
  childrenDataV2?: Maybe<Array<Maybe<Category>>>;
  quizzes?: Maybe<Array<Maybe<Quiz>>>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  /** @deprecated Use `flashcardsV2` field instead */
  flashcards?: Maybe<Array<Maybe<Flashcard>>>;
  flashcardsV2?: Maybe<Array<Maybe<FlashcardV2>>>;
  briefs?: Maybe<Array<Maybe<Brief>>>;
  /** Actually contains the training papers (both the official and unofficial papers), not only the past papers (= official ones) but name did not change for compatibility reasons. */
  pastPapers?: Maybe<Array<Maybe<PastPaper>>>;
  rightOfWayTests?: Maybe<Array<Maybe<RightOfWayTest>>>;
  lessonsIds?: Maybe<Array<Scalars["String"]>>;
  quizzesIds?: Maybe<Array<Scalars["String"]>>;
  pastPapersIds?: Maybe<Array<Scalars["String"]>>;
  briefsIds?: Maybe<Array<Scalars["String"]>>;
  /**
   * **DEPRECATED:** Returns flashcards from Feu Vert exclusively. Use `activitiesV2` instead
   * @deprecated Field no longer supported
   */
  activities?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  activitiesV2?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  elapsedTime?: Maybe<Time>;
  topic?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  platformAvailability: CategoryPlatformAvailability;
  description?: Maybe<Scalars["String"]>;
  validityTimeRange?: Maybe<ValidityTimeRange>;
  recursiveCounts?: Maybe<RecursiveCount>;
};

export type CategoryChildrensDataArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  order?: Maybe<OrderDirection>;
};

export type CategoryChildrenDataV2Args = {
  search?: Maybe<CategoriesChildrenDataV2SearchInput>;
};

export type CategoryQuizzesArgs = {
  search?: Maybe<QuizzesFromCategoryV2SearchInput>;
};

export type CategoryLessonsArgs = {
  search?: Maybe<LessonsFromCategoryV2SearchInput>;
};

export type CategoryFlashcardsV2Args = {
  search?: Maybe<FlashcardsV2FromCategoryV2SearchInput>;
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};

export type CategoryBriefsArgs = {
  search?: Maybe<BriefsFromCategorySearchInput>;
};

export type CategoryPastPapersArgs = {
  search?: Maybe<TrainingPapersFromCategoryV2SearchInput>;
};

export type CategoryActivitiesArgs = {
  order?: Maybe<OrderDirection>;
};

export type CategoryActivitiesV2Args = {
  search?: Maybe<CategoryActivitiesV2SearchInput>;
  order?: Maybe<OrderDirection>;
};

export type CategoryElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};

export type CategoryActivitiesV2SearchInput = {
  status?: Maybe<Status>;
};

export type CategoryConstraint = {
  __typename?: "CategoryConstraint";
  categories?: Maybe<Array<Scalars["String"]>>;
  count?: Maybe<Scalars["Int"]>;
};

export type CategoryConstraintInput = {
  categories?: Maybe<Array<Scalars["String"]>>;
  count?: Maybe<Scalars["Int"]>;
};

export type CategoryFilter = {
  status?: Maybe<Status>;
  lessons?: Maybe<Scalars["String"]>;
  quizzes?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  parentId?: Maybe<Scalars["String"]>;
  parentPath?: Maybe<Scalars["String"]>;
};

export type CategoryPlatformAvailability = {
  __typename?: "CategoryPlatformAvailability";
  web: Availability;
  mobile: Availability;
};

export enum CategoryPlatformFilter {
  Mobile = "MOBILE",
  Web = "WEB"
}

export type CdrRoadSignFlashcard = FlashcardV2 & {
  __typename?: "CdrRoadSignFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<CdrRoadSignFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type CdrRoadSignFlashcardContent = {
  __typename?: "CdrRoadSignFlashcardContent";
  code?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  roadSign?: Maybe<File>;
};

export type ChildUsersInput = {
  client: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type CleaAttempt = {
  __typename?: "CleaAttempt";
  attempt: Scalars["Int"];
};

export type CleaAttemptCountdown = {
  __typename?: "CleaAttemptCountdown";
  attemptCountdown: Scalars["Int"];
  success: Scalars["Boolean"];
};

export type CleaAttemptSuccess = {
  __typename?: "CleaAttemptSuccess";
  attempt: Scalars["Int"];
  success: Scalars["Boolean"];
};

export type CleaEditedCv = {
  __typename?: "CleaEditedCv";
  content: Scalars["String"];
};

export type CleaHelpPopup = {
  __typename?: "CleaHelpPopup";
  contactDisplayed: Scalars["Boolean"];
  mapDisplayed: Scalars["Boolean"];
  shareArticleDisplayed: Scalars["Boolean"];
  uploadDisplayed: Scalars["Boolean"];
};

export type CleaPagination = {
  __typename?: "CleaPagination";
  next?: Maybe<Scalars["String"]>;
  previous?: Maybe<Scalars["String"]>;
};

export type CleaQuestions = {
  __typename?: "CleaQuestions";
  codeFound: Scalars["Boolean"];
  codeMailOpened: Scalars["Boolean"];
  contactMailOpened: Scalars["Boolean"];
  companyInfo: CleaAttemptSuccess;
  companyName: CleaAttemptSuccess;
  contactJob: CleaAttemptSuccess;
  findContact: CleaAttemptSuccess;
  sharedArticle: CleaAttemptSuccess;
  wrongCVUploadCount: Scalars["Int"];
  editedCV: CleaEditedCv;
  helpPopup: CleaHelpPopup;
  introChat: CleaAttempt;
  trap: CleaTrap;
};

export type CleaReservation = {
  __typename?: "CleaReservation";
  _id: Scalars["ID"];
  ssoId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  idCompte?: Maybe<Scalars["Int"]>;
  idReservation?: Maybe<Scalars["Int"]>;
  idSession?: Maybe<Scalars["Int"]>;
  numeroDePassage?: Maybe<Scalars["String"]>;
  civilite?: Maybe<Scalars["String"]>;
  nom?: Maybe<Scalars["String"]>;
  prenom?: Maybe<Scalars["String"]>;
  dateNaissance?: Maybe<Scalars["String"]>;
  emailDestinataireSupplementaire?: Maybe<Scalars["String"]>;
  typeActivite?: Maybe<Scalars["String"]>;
  statut?: Maybe<Scalars["String"]>;
  birthName?: Maybe<Scalars["String"]>;
  codePostale?: Maybe<Scalars["String"]>;
  telephone?: Maybe<Scalars["String"]>;
  studyLevel?: Maybe<Scalars["String"]>;
  origin?: Maybe<Scalars["String"]>;
  birthCity?: Maybe<Scalars["String"]>;
  funder?: Maybe<Scalars["String"]>;
  postalAddress?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  resolutionStatus?: Maybe<Scalars["String"]>;
  resolvedAt?: Maybe<Scalars["String"]>;
};

export type CleaReservationPaginationInput = {
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type CleaResult = {
  __typename?: "CleaResult";
  userId: Scalars["String"];
  _id: Scalars["String"];
  codeTrapDisplayed: Scalars["Boolean"];
  companyUid: Scalars["Int"];
  currentStep: Scalars["String"];
  effectiveEndTime: Scalars["String"];
  finalResponse: Scalars["String"];
  questions: CleaQuestions;
  responseCompanyName?: Maybe<CleaAttemptCountdown>;
  responseFindContact: CleaAttemptCountdown;
  success?: Maybe<Scalars["Boolean"]>;
  theoreticalEndTime?: Maybe<Scalars["String"]>;
  timedOut?: Maybe<Scalars["Boolean"]>;
};

export type CleaTrap = {
  __typename?: "CleaTrap";
  formValidated: Scalars["Boolean"];
  popupClicked: Scalars["Boolean"];
};

export enum Client {
  Codedelaroute = "codedelaroute",
  Toeic = "toeic",
  Brevet = "brevet",
  Bac = "bac",
  Feuvert = "feuvert",
  Digipass = "digipass",
  Education = "education",
  Concours = "concours"
}

export type Code = {
  __typename?: "Code";
  _id: Scalars["String"];
  code: Scalars["String"];
  deal: Deal;
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  activatedAt?: Maybe<Scalars["String"]>;
  activatedBy?: Maybe<Scalars["String"]>;
  activatedByUser?: Maybe<User>;
  updatedAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
};

export type CodeActivatedByUserArgs = {
  client?: Maybe<Scalars["String"]>;
};

export type CodeInput = {
  codeCount: Scalars["Int"];
  deal: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
};

export type CodeOutput = {
  __typename?: "CodeOutput";
  codeCount: Scalars["Int"];
  deal: Scalars["String"];
  createdAt: Scalars["String"];
};

export type CodePrepaidGoodSource = PrepaidGoodSource & {
  __typename?: "CodePrepaidGoodSource";
  type: PrepaidGoodSourceType;
  codeId: Scalars["String"];
};

export enum CodeSearchType {
  Exact = "exact",
  Start = "start",
  End = "end",
  Middle = "middle"
}

export type CodedelarouteService = {
  __typename?: "CodedelarouteService";
  neph?: Maybe<Scalars["String"]>;
};

export type CodedelarouteServiceInput = {
  neph?: Maybe<Scalars["String"]>;
};

export type CodesV3Input = {
  searchTerm?: Maybe<Scalars["String"]>;
  searchType?: Maybe<CodeSearchType>;
  isActivated?: Maybe<Scalars["Boolean"]>;
  deals?: Maybe<Array<Scalars["String"]>>;
  createdAt?: Maybe<Scalars["String"]>;
  activatedBy?: Maybe<Scalars["String"]>;
  sort?: Maybe<Array<SortRule>>;
};

export type Contact = {
  __typename?: "Contact";
  tags: Array<Maybe<ReportService>>;
  service: ReportService;
  email: Scalars["String"];
  platform: ReportPlatform;
  type: Scalars["String"];
  subType: Scalars["String"];
  message: Scalars["String"];
  attachments: Array<Maybe<Attachment>>;
};

export type ContactInput = {
  tags: Array<Maybe<ReportService>>;
  service: ReportService;
  email: Scalars["String"];
  platform: ReportPlatform;
  type: Scalars["String"];
  subType: Scalars["String"];
  message: Scalars["String"];
  attachments: Array<Maybe<AttachmentInput>>;
};

export type Convocation = {
  __typename?: "Convocation";
  pdf: Scalars["String"];
};

export type Coupon = {
  __typename?: "Coupon";
  _id: Scalars["String"];
  code: Scalars["String"];
  tenant: TenantType;
  description: Scalars["String"];
  amountOff?: Maybe<Scalars["Int"]>;
  percentOff?: Maybe<Scalars["Int"]>;
  active: Scalars["Boolean"];
  duration: CouponDuration;
  durationInDays?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["String"];
  updatedAt?: Maybe<Scalars["String"]>;
  valid: Scalars["Boolean"];
};

export enum CouponDuration {
  Once = "ONCE",
  Repeating = "REPEATING",
  Forever = "FOREVER"
}

export type CreatePartnerInput = {
  name: Scalars["String"];
  mentors?: Maybe<Array<Scalars["String"]>>;
};

export type CreatePostInput = {
  title: Scalars["String"];
  text: Scalars["String"];
  type: PostType;
  levelIds?: Maybe<Array<Scalars["String"]>>;
  subjectIds?: Maybe<Array<Scalars["String"]>>;
  sectorIds?: Maybe<Array<Scalars["String"]>>;
  orientationIds?: Maybe<Array<Scalars["String"]>>;
};

export type CreateResponseInput = {
  text: Scalars["String"];
  postId: Scalars["String"];
};

export enum CurrencyType {
  Eur = "EUR",
  Usd = "USD"
}

export type CursorPaginatedResponse = {
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type CursorPaginatedResponseLinks = {
  __typename?: "CursorPaginatedResponseLinks";
  first: Scalars["String"];
  previous?: Maybe<Scalars["String"]>;
  next?: Maybe<Scalars["String"]>;
  last: Scalars["String"];
};

export type CursorPaginatedResponseMeta = {
  __typename?: "CursorPaginatedResponseMeta";
  itemCount: Scalars["Int"];
  totalItemCount: Scalars["Int"];
  itemsPerPage: Scalars["Int"];
  totalPages: Scalars["Int"];
  currentPage: Scalars["Int"];
};

export type CustomGeneratedQuiz = {
  __typename?: "CustomGeneratedQuiz";
  startScore: Scalars["Int"];
  title: Scalars["String"];
  slug: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  maximalScore: Scalars["Int"];
  successScore?: Maybe<Scalars["Int"]>;
  questionCount: Scalars["Int"];
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  exerciseList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  exerciseListData?: Maybe<Array<Maybe<Exercise>>>;
  estimatedTime?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  criteria?: Maybe<GeneratedQuizCriteria>;
};

export type Deal = {
  __typename?: "Deal";
  _id: Scalars["String"];
  name: Scalars["String"];
  template: Scalars["String"];
  gains: Array<Gain>;
  partner: Partner;
  clients: Array<Maybe<Scalars["String"]>>;
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  batches?: Maybe<Array<DealBatch>>;
  codes?: Maybe<PaginatedCodesOutput>;
};

export type DealCodesArgs = {
  input?: Maybe<DealCodesInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type DealBatch = {
  __typename?: "DealBatch";
  createdAt: Scalars["String"];
  codeCount: Scalars["Int"];
};

export type DealCodesInput = {
  status?: Maybe<VoucherStatus>;
};

export type DealInput = {
  name: Scalars["String"];
  template: Scalars["String"];
  gains: Array<Maybe<Scalars["String"]>>;
  partner: Scalars["String"];
  clients: Array<Maybe<Scalars["String"]>>;
  comment?: Maybe<Scalars["String"]>;
};

export type DealsV2Input = {
  status?: Maybe<VoucherStatus>;
  partners?: Maybe<Array<Scalars["String"]>>;
  clients?: Maybe<Array<Scalars["String"]>>;
};

export type DeletePaymentMethodInput = {
  id: Scalars["String"];
};

export type DeletionProcessState = {
  __typename?: "DeletionProcessState";
  firstWarningSentAt?: Maybe<Scalars["Int"]>;
  secondWarningSentAt?: Maybe<Scalars["Int"]>;
};

export type Diploma = {
  __typename?: "Diploma";
  slug: Scalars["String"];
  label: Scalars["String"];
  specialities?: Maybe<Array<Maybe<Speciality>>>;
};

export type DiplomaV2 = {
  __typename?: "DiplomaV2";
  slug: Scalars["String"];
  label: Scalars["String"];
  specialities?: Maybe<Array<Speciality>>;
};

export type Domain = {
  __typename?: "Domain";
  slug: Scalars["String"];
  label: Scalars["String"];
  sectors: Array<Maybe<Sector>>;
};

export type DomainV2 = {
  __typename?: "DomainV2";
  slug: Scalars["String"];
  label: Scalars["String"];
  sectors: Array<Sector>;
};

export type EphemeralKey = {
  __typename?: "EphemeralKey";
  id?: Maybe<Scalars["String"]>;
  secret?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Int"]>;
  expires?: Maybe<Scalars["Int"]>;
};

export type Exercise = {
  __typename?: "Exercise";
  _id: Scalars["String"];
  learningId: Scalars["Int"];
  text?: Maybe<Scalars["String"]>;
  difficulty: Scalars["Int"];
  displayType: Scalars["String"];
  audioText: Scalars["String"];
  maxDurationSeconds: Scalars["Int"];
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  questions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  questionsData?: Maybe<Array<Maybe<Question>>>;
  containsLatex: Scalars["Boolean"];
};

export type Explanation = {
  __typename?: "Explanation";
  text: Scalars["String"];
  audioText?: Maybe<Scalars["String"]>;
  assetLinks: Array<Maybe<Scalars["String"]>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
};

export type ExternalReference = {
  __typename?: "ExternalReference";
  stripe?: Maybe<Reference>;
  paypal?: Maybe<Reference>;
};

export type FacetByLevels = {
  __typename?: "FacetByLevels";
  id: Scalars["String"];
  count: Scalars["Int"];
};

export type FacetByOrientations = {
  __typename?: "FacetByOrientations";
  id: Scalars["String"];
  count: Scalars["Int"];
};

export type FacetBySectors = {
  __typename?: "FacetBySectors";
  id: Scalars["String"];
  count: Scalars["Int"];
};

export type FacetBySubjects = {
  __typename?: "FacetBySubjects";
  id: Scalars["String"];
  count: Scalars["Int"];
};

export type FacetByTypes = {
  __typename?: "FacetByTypes";
  type: PostType;
  count: Scalars["Int"];
};

export enum FacetType {
  Level = "LEVEL",
  Subject = "SUBJECT",
  Sector = "SECTOR",
  Orientation = "ORIENTATION"
}

export type FeuVertRoadSignFlashcard = FlashcardV2 & {
  __typename?: "FeuVertRoadSignFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<FeuVertRoadSignFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type FeuVertRoadSignFlashcardContent = {
  __typename?: "FeuVertRoadSignFlashcardContent";
  description?: Maybe<Scalars["String"]>;
  roadSign?: Maybe<File>;
};

export type File = {
  __typename?: "File";
  /** @deprecated Use `_id` */
  id: Scalars["String"];
  _id: Scalars["String"];
  version?: Maybe<Scalars["String"]>;
  mime?: Maybe<Scalars["String"]>;
  filename: Scalars["String"];
  s3Url: Scalars["String"];
  s3swhUrl: Scalars["String"];
  realm: Scalars["String"];
  author?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  userMetadatas?: Maybe<Scalars["Json"]>;
  userTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  automaticTags?: Maybe<Scalars["Json"]>;
  imageTags?: Maybe<ImageTags>;
};

export type Flashcard = {
  __typename?: "Flashcard";
  _id: Scalars["String"];
  title: Scalars["String"];
  author?: Maybe<Author>;
  okulusIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
};

export type FlashcardProgress = {
  __typename?: "FlashcardProgress";
  id: Scalars["Int"];
  gutenberg_uuid: Scalars["String"];
  product?: Maybe<BookmarkProduct>;
  assimilated_count?: Maybe<Scalars["Int"]>;
};

export type FlashcardProgressInput = {
  gutenberg_uuid: Scalars["String"];
  product?: Maybe<BookmarkProduct>;
  assimilated_count?: Maybe<Scalars["Int"]>;
};

export type FlashcardProgressSortInput = {
  id?: Maybe<Sort>;
  created_at?: Maybe<Sort>;
};

export enum FlashcardType {
  BacFrenchStylisticDevice = "BAC_FRENCH_STYLISTIC_DEVICE",
  BacHistoryLandmark = "BAC_HISTORY_LANDMARK",
  BacPhilosophyQuote = "BAC_PHILOSOPHY_QUOTE",
  BrevetChemistryMolecule = "BREVET_CHEMISTRY_MOLECULE",
  BrevetFrenchStylisticDevice = "BREVET_FRENCH_STYLISTIC_DEVICE",
  BrevetHistoryLandmark = "BREVET_HISTORY_LANDMARK",
  CdrRoadSign = "CDR_ROAD_SIGN",
  FeuVertRoadSign = "FEU_VERT_ROAD_SIGN",
  FleJobs = "FLE_JOBS",
  FleVocabulary = "FLE_VOCABULARY",
  Mosalingua = "MOSALINGUA"
}

export type FlashcardV2 = {
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type FlashcardsV2FromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

/** Deprecated: Prefer using FleVocabularyFlashcard */
export type FleJobsFlashcard = FlashcardV2 & {
  __typename?: "FleJobsFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<FleJobsFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

/** Deprecated: Prefer using FleVocabularyFlashcardContent */
export type FleJobsFlashcardContent = {
  __typename?: "FleJobsFlashcardContent";
  job?: Maybe<Scalars["String"]>;
  picture?: Maybe<File>;
};

export type FleVocabularyFlashcard = FlashcardV2 & {
  __typename?: "FleVocabularyFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<FleVocabularyFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type FleVocabularyFlashcardContent = {
  __typename?: "FleVocabularyFlashcardContent";
  notion?: Maybe<Scalars["String"]>;
  picture?: Maybe<File>;
};

export type Frequency = {
  __typename?: "Frequency";
  intervalUnit: IntervalPriceType;
  intervalCount: Scalars["Int"];
};

export type Gain = {
  __typename?: "Gain";
  _id: Scalars["String"];
  type: GainType;
  name: Scalars["String"];
  stripe?: Maybe<Stripe>;
  durationInDays: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
};

export type GainInput = {
  type: GainType;
  name: Scalars["String"];
  stripe: StripeInput;
  durationInDays: Scalars["Int"];
  comment?: Maybe<Scalars["String"]>;
};

export enum GainType {
  OneTime = "ONE_TIME",
  Recurring = "RECURRING"
}

export type GainsV2Input = {
  status?: Maybe<VoucherStatus>;
};

export enum Gender {
  M = "M",
  F = "F"
}

export type GeneratedQuizCriteria = {
  __typename?: "GeneratedQuizCriteria";
  size: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
  startScore: Scalars["Int"];
  accessType?: Maybe<AccessType>;
  status?: Maybe<Status>;
  ttl: Scalars["Int"];
  constraints?: Maybe<GeneratedQuizCriteriaContraint>;
};

export type GeneratedQuizCriteriaContraint = {
  __typename?: "GeneratedQuizCriteriaContraint";
  categories?: Maybe<Array<Maybe<CategoryConstraint>>>;
  videoCount?: Maybe<Scalars["Int"]>;
  seriousFaultCount?: Maybe<Scalars["Int"]>;
};

export type GeneratedQuizCriteriaContraintInput = {
  categories?: Maybe<Array<Maybe<CategoryConstraintInput>>>;
  videoCount?: Maybe<Scalars["Int"]>;
  seriousFaultCount?: Maybe<Scalars["Int"]>;
};

export type GeneratedQuizCriteriaInput = {
  size: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
  startScore: Scalars["Int"];
  successScore: Scalars["Int"];
  constraints?: Maybe<GeneratedQuizCriteriaContraintInput>;
};

export type GutenbergPaginationInput = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export enum GutenbergProduct {
  Exercice = "EXERCICE",
  Primaire = "PRIMAIRE",
  Brevet = "BREVET",
  College = "COLLEGE",
  Bac = "BAC",
  Lycee = "LYCEE",
  Bts = "BTS",
  Superieur = "SUPERIEUR",
  Cdr = "CDR",
  FeuVert = "FEU_VERT",
  Mosalingua = "MOSALINGUA",
  Orthographe = "ORTHOGRAPHE",
  Toeic = "TOEIC",
  ToeicWorld = "TOEIC_WORLD",
  Alternance = "ALTERNANCE",
  Clea = "CLEA",
  Secours = "SECOURS",
  FonctionPublique = "FONCTION_PUBLIQUE",
  Voltaire = "VOLTAIRE",
  Fle = "FLE",
  Hsk = "HSK",
  Orientation = "ORIENTATION",
  Digischool = "DIGISCHOOL",
  RfFormation = "RF_FORMATION",
  ActuaCac = "ACTUA_CAC"
}

export type ImageTags = {
  __typename?: "ImageTags";
  width?: Maybe<Scalars["Int"]>;
  height?: Maybe<Scalars["Int"]>;
  density?: Maybe<Scalars["Int"]>;
  format?: Maybe<Scalars["String"]>;
};

export enum IntervalPriceType {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  Year = "YEAR"
}

export type IosSubscriptionPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: "IosSubscriptionPrepaidGoodSource";
  type: PrepaidGoodSourceType;
  iosSubscriptionId: Scalars["String"];
};

export type LabelList = {
  __typename?: "LabelList";
  fr?: Maybe<Scalars["String"]>;
  en?: Maybe<Scalars["String"]>;
};

export type LabelListInput = {
  fr: Scalars["String"];
  en?: Maybe<Scalars["String"]>;
};

export enum Language {
  Fr = "FR",
  En = "EN",
  De = "DE",
  Es = "ES",
  Eo = "EO",
  It = "IT",
  Pt = "PT",
  Ru = "RU",
  Cn = "CN"
}

export type LanguageCombination = {
  __typename?: "LanguageCombination";
  source?: Maybe<Scalars["String"]>;
  target?: Maybe<Scalars["String"]>;
};

export type LanguageCombinationInput = {
  source: Language;
  target: Language;
};

export enum LearningType {
  Quiz = "Quiz",
  Lesson = "Lesson",
  MockExam = "Mock_Exam",
  Session = "Session",
  Flashcards = "Flashcards",
  Superquiz = "Superquiz",
  SuddenDeath = "Sudden_Death",
  IrregularVerbs = "Irregular_Verbs"
}

export type Lesson = {
  __typename?: "Lesson";
  _id: Scalars["String"];
  learningId: Scalars["Int"];
  title: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  slug: Scalars["String"];
  slugV2: Scalars["String"];
  html: Scalars["String"];
  pdfs?: Maybe<Array<Maybe<LessonPdf>>>;
  author?: Maybe<Author>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  estimatedTime?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  relatedActivities: RelatedActivities;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  elapsedTime?: Maybe<Time>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars["Float"]>;
};

export type LessonRelatedActivitiesDataArgs = {
  languageCombinationInput?: Maybe<LanguageCombinationInput>;
};

export type LessonElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};

export type LessonPdf = {
  __typename?: "LessonPdf";
  _id: Scalars["String"];
  slug: Scalars["String"];
  okulusId: Scalars["String"];
  srcPath?: Maybe<Scalars["String"]>;
  okulusAsset: File;
};

export type LessonProgress = {
  __typename?: "LessonProgress";
  id: Scalars["Int"];
  learning_id?: Maybe<Scalars["Int"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  status: BookmarkStatus;
  product?: Maybe<BookmarkProduct>;
  section?: Maybe<BookmarkSection>;
  created_at: Scalars["String"];
  updated_at?: Maybe<Scalars["String"]>;
  lesson?: Maybe<Lesson>;
};

export type LessonProgressInput = {
  learning_id?: Maybe<Scalars["Int"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  status: BookmarkStatus;
  product: BookmarkProduct;
  section?: Maybe<BookmarkSection>;
};

export type LessonsFromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type LessonsV2SearchInput = {
  learningIds?: Maybe<Array<Scalars["Int"]>>;
  slugs?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Status>;
  product?: Maybe<GutenbergProduct>;
  tags?: Maybe<Array<Scalars["String"]>>;
  createdAfter?: Maybe<Scalars["String"]>;
  updatedAfter?: Maybe<Scalars["String"]>;
};

export type Level = {
  __typename?: "Level";
  slug: Scalars["String"];
  label: Scalars["String"];
  diplomas: Array<Maybe<Diploma>>;
};

export type LevelV2 = {
  __typename?: "LevelV2";
  slug: Scalars["String"];
  label: Scalars["String"];
  diplomas: Array<DiplomaV2>;
};

export type LilsisStatus = {
  __typename?: "LilsisStatus";
  slug: Scalars["String"];
  label: Scalars["String"];
  levels: Array<Maybe<Level>>;
};

export type LilsisStatusV2 = {
  __typename?: "LilsisStatusV2";
  slug: Scalars["String"];
  label: Scalars["String"];
  levels: Array<LevelV2>;
};

export enum Locale {
  En = "en",
  Fr = "fr"
}

export type MailValidationProcessState = {
  __typename?: "MailValidationProcessState";
  firstMailSentAt?: Maybe<Scalars["Int"]>;
  reminderSentAt?: Maybe<Scalars["Int"]>;
};

export type Media = {
  __typename?: "Media";
  learningId?: Maybe<Scalars["Int"]>;
  okulusId?: Maybe<Scalars["String"]>;
  okulusAsset?: Maybe<File>;
  name?: Maybe<Scalars["String"]>;
  mimeType?: Maybe<Scalars["String"]>;
  provider?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  contentType?: Maybe<Scalars["String"]>;
  data?: Maybe<Scalars["Json"]>;
};

export type MenteesInput = {
  client: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type MosalinguaFlashcard = FlashcardV2 & {
  __typename?: "MosalinguaFlashcard";
  _id: Scalars["String"];
  flashcardType: FlashcardType;
  content?: Maybe<MosalinguaFlashcardContent>;
  status: Status;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  bookmark?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type MosalinguaFlashcardContent = {
  __typename?: "MosalinguaFlashcardContent";
  mnemoImage?: Maybe<File>;
  mnemoText?: Maybe<LanguageCombination>;
  notion?: Maybe<LanguageCombination>;
  oralLevel?: Maybe<Scalars["Int"]>;
  teacherRecord?: Maybe<File>;
  writtenLevel?: Maybe<Scalars["Int"]>;
};

export type MosalinguaService = {
  __typename?: "MosalinguaService";
  firstConnectionAt?: Maybe<Scalars["Float"]>;
  targetLanguage?: Maybe<Scalars["String"]>;
};

export type MosalinguaServiceInput = {
  targetLanguage?: Maybe<Scalars["String"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  scheduleReview?: Maybe<ScheduledReview>;
  quizProgressPost?: Maybe<QuizProgress>;
  lessonProgressPost?: Maybe<LessonProgress>;
  quizProgressPostBulk?: Maybe<Array<Maybe<QuizProgress>>>;
  lessonProgressPostBulk?: Maybe<Array<Maybe<LessonProgress>>>;
  flashcardProgressPost?: Maybe<FlashcardProgress>;
  flashcardProgressPostBulk?: Maybe<Array<Maybe<FlashcardProgress>>>;
  createPost: Post;
  addPostClaps?: Maybe<Post>;
  createResponse?: Maybe<PostResponse>;
  addResponseClaps?: Maybe<PostResponse>;
  lockToeicPromoCode?: Maybe<ToeicPromoCodesLockOutput>;
  importToeicPromoCodesAdmin?: Maybe<ToeicPromoCodesAdminImportOutput>;
  /**
   * # Deprecated
   * Use `userPatchv2`, because this one does not use an input field and is missing some fields.
   * @deprecated Use `userPatchv2`, because this one does not use input.
   */
  userPatch?: Maybe<User>;
  /**
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  userPatchv2?: Maybe<User>;
  /**
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  userDelete?: Maybe<Scalars["Json"]>;
  tag?: Maybe<UserTag>;
  tagDelete?: Maybe<Scalars["Json"]>;
  userPatchTags?: Maybe<Scalars["Json"]>;
  cart?: Maybe<Cart>;
  cartPatch?: Maybe<Cart>;
  cartIntent?: Maybe<CartIntent>;
  ephemeralKey?: Maybe<EphemeralKey>;
  cancelSubscription?: Maybe<Scalars["Json"]>;
  roleMapping?: Maybe<Scalars["Json"]>;
  consumePrepaidGood?: Maybe<PrepaidGood>;
  consumePrepaidGoodV2?: Maybe<PrepaidGood>;
  cartV2?: Maybe<CartV2>;
  cartPatchV2?: Maybe<CartV2>;
  cartV2StripeIntent?: Maybe<CartStripeIntent>;
  cartV2PaypalApprove?: Maybe<CartPaypalApprove>;
  createPaymentMethodWithSetupIntent: StripeSetupIntent;
  updateDefaultStripePaymentMethod: StripePaymentMethod;
  deletePaymentMethod?: Maybe<Scalars["Json"]>;
  cancelSubscriptionV2?: Maybe<Scalars["Json"]>;
  activateCode?: Maybe<Scalars["Json"]>;
  codes?: Maybe<CodeOutput>;
  gain?: Maybe<Gain>;
  deal?: Maybe<Deal>;
  createPartner?: Maybe<Partner>;
  reasonPost?: Maybe<Scalars["Json"]>;
  reasonPut?: Maybe<Scalars["Json"]>;
  reportPost?: Maybe<Scalars["Json"]>;
  reportSolve?: Maybe<ReportSolve>;
  contactPost?: Maybe<Contact>;
  reservationDelete?: Maybe<Scalars["Json"]>;
  reservationPost?: Maybe<Reservation>;
  timePost?: Maybe<Time>;
};

export type MutationScheduleReviewArgs = {
  input: ScheduleReviewInput;
};

export type MutationQuizProgressPostArgs = {
  data?: Maybe<QuizProgressInput>;
};

export type MutationLessonProgressPostArgs = {
  data?: Maybe<LessonProgressInput>;
};

export type MutationQuizProgressPostBulkArgs = {
  data?: Maybe<Array<Maybe<QuizProgressInput>>>;
};

export type MutationLessonProgressPostBulkArgs = {
  data?: Maybe<Array<Maybe<LessonProgressInput>>>;
};

export type MutationFlashcardProgressPostArgs = {
  input?: Maybe<FlashcardProgressInput>;
};

export type MutationFlashcardProgressPostBulkArgs = {
  input?: Maybe<Array<Maybe<FlashcardProgressInput>>>;
};

export type MutationCreatePostArgs = {
  params?: Maybe<CreatePostInput>;
};

export type MutationAddPostClapsArgs = {
  id: Scalars["String"];
  count: Scalars["Int"];
};

export type MutationCreateResponseArgs = {
  params?: Maybe<CreateResponseInput>;
};

export type MutationAddResponseClapsArgs = {
  id: Scalars["String"];
  count: Scalars["Int"];
};

export type MutationImportToeicPromoCodesAdminArgs = {
  importData?: Maybe<ToeicPromoCodesAdminImportInput>;
};

export type MutationUserPatchArgs = {
  userName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  address?: Maybe<AddressInput>;
  situation?: Maybe<SituationInput>;
  newsletters?: Maybe<NewslettersInput>;
};

export type MutationUserPatchv2Args = {
  input: UserPatchInput;
  client?: Maybe<Scalars["String"]>;
};

export type MutationUserDeleteArgs = {
  client?: Maybe<Scalars["String"]>;
};

export type MutationTagArgs = {
  value: Scalars["String"];
};

export type MutationTagDeleteArgs = {
  id: Scalars["String"];
};

export type MutationUserPatchTagsArgs = {
  userId: Scalars["String"];
  input: PatchTagsInput;
};

export type MutationCartArgs = {
  input: CartInput;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCartPatchArgs = {
  input: CartPatchInput;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCartIntentArgs = {
  id: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationEphemeralKeyArgs = {
  customerId: Scalars["String"];
  apiVersion: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCancelSubscriptionArgs = {
  id: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationRoleMappingArgs = {
  input: RoleMappingInput;
};

export type MutationConsumePrepaidGoodArgs = {
  id: Scalars["String"];
  reservationId: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationConsumePrepaidGoodV2Args = {
  id: Scalars["String"];
  reservationId?: Maybe<Scalars["String"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCartV2Args = {
  input: CartV2Input;
  tenant: TenantType;
};

export type MutationCartPatchV2Args = {
  input: CartPatchV2Input;
  tenant: TenantType;
};

export type MutationCartV2StripeIntentArgs = {
  cartId: Scalars["String"];
  tenant: TenantType;
};

export type MutationCartV2PaypalApproveArgs = {
  cartId: Scalars["String"];
  tenant: TenantType;
  redirectUrl: Scalars["String"];
  cancelUrl: Scalars["String"];
};

export type MutationCreatePaymentMethodWithSetupIntentArgs = {
  client?: Maybe<Scalars["String"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationUpdateDefaultStripePaymentMethodArgs = {
  input: StripePaymentMethodInput;
  client?: Maybe<Scalars["String"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationDeletePaymentMethodArgs = {
  input?: Maybe<DeletePaymentMethodInput>;
  client?: Maybe<Scalars["String"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCancelSubscriptionV2Args = {
  id: Scalars["String"];
};

export type MutationActivateCodeArgs = {
  code: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCodesArgs = {
  input: CodeInput;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationGainArgs = {
  input?: Maybe<GainInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationDealArgs = {
  input?: Maybe<DealInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
  stripeAccount?: Maybe<StripeAccount>;
};

export type MutationReasonPostArgs = {
  input?: Maybe<ReasonInput>;
};

export type MutationReasonPutArgs = {
  input?: Maybe<ReasonInput>;
};

export type MutationReportPostArgs = {
  input?: Maybe<ReportInput>;
};

export type MutationReportSolveArgs = {
  input?: Maybe<ReportSolveInput>;
};

export type MutationContactPostArgs = {
  input?: Maybe<ContactInput>;
};

export type MutationReservationDeleteArgs = {
  id: Scalars["String"];
};

export type MutationReservationPostArgs = {
  input: ReservationInput;
};

export type MutationTimePostArgs = {
  userID: Scalars["String"];
  learningProduct: Scalars["String"];
  startTimestamp: Scalars["Int"];
  endTimestamp: Scalars["Int"];
  learningCategorie?: Maybe<Scalars["String"]>;
  learningType?: Maybe<LearningType>;
  learningID?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type Newsletters = {
  __typename?: "Newsletters";
  digiSchool?: Maybe<Scalars["Boolean"]>;
  partners?: Maybe<Scalars["Boolean"]>;
  feuvert?: Maybe<Scalars["Boolean"]>;
  mosalingua?: Maybe<Scalars["Boolean"]>;
};

export type NewslettersInput = {
  digiSchool?: Maybe<Scalars["Boolean"]>;
  partners?: Maybe<Scalars["Boolean"]>;
  feuvert?: Maybe<Scalars["Boolean"]>;
  mosalingua?: Maybe<Scalars["Boolean"]>;
};

export type OffsetPaginatedResponse = {
  pagination: OffsetPagination;
};

export type OffsetPagination = {
  __typename?: "OffsetPagination";
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  total: Scalars["Int"];
};

export type OffsetPaginationInput = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type OneTimePrice = PriceInterface & {
  __typename?: "OneTimePrice";
  _id: Scalars["String"];
  slug: Scalars["String"];
  name: Scalars["String"];
  description: Scalars["String"];
  enable: Scalars["Boolean"];
  amount: Scalars["Int"];
  currency: CurrencyType;
  externalReference: ExternalReference;
  productId?: Maybe<Scalars["String"]>;
  product: Product;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC"
}

export type PaginatedBriefsOutput = CursorPaginatedResponse & {
  __typename?: "PaginatedBriefsOutput";
  data?: Maybe<Array<Maybe<Brief>>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedCartsOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedCartsOutput";
  data: Array<Cart>;
  pagination: OffsetPagination;
};

export type PaginatedCartsV2Output = OffsetPaginatedResponse & {
  __typename?: "PaginatedCartsV2Output";
  data: Array<CartV2>;
  pagination: OffsetPagination;
};

export type PaginatedCategoriesOutput = CursorPaginatedResponse & {
  __typename?: "PaginatedCategoriesOutput";
  data?: Maybe<Array<Category>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedCleaReservation = {
  __typename?: "PaginatedCleaReservation";
  id: Scalars["ID"];
  data: Array<Maybe<CleaReservation>>;
  cursor?: Maybe<CleaPagination>;
};

export type PaginatedCodesOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedCodesOutput";
  data: Array<Code>;
  pagination: OffsetPagination;
};

export type PaginatedDealsOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedDealsOutput";
  data: Array<Deal>;
  pagination: OffsetPagination;
};

export type PaginatedFlashcardsV2Output = CursorPaginatedResponse & {
  __typename?: "PaginatedFlashcardsV2Output";
  data?: Maybe<Array<Maybe<FlashcardV2>>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedGainsOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedGainsOutput";
  data: Array<Gain>;
  pagination: OffsetPagination;
};

export type PaginatedLessonsOutput = CursorPaginatedResponse & {
  __typename?: "PaginatedLessonsOutput";
  data?: Maybe<Array<Maybe<Lesson>>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedPartnersOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedPartnersOutput";
  data: Array<Partner>;
  pagination: OffsetPagination;
};

export type PaginatedPostFacetsInput = {
  pagination?: Maybe<PaginationInput>;
  filters?: Maybe<PostFacetsFilters>;
};

export type PaginatedPostFacetsOutput = {
  __typename?: "PaginatedPostFacetsOutput";
  data: Array<PostFacet>;
  pagination: PaginatedResponse;
};

export type PaginatedPostsInput = {
  pagination?: Maybe<PaginationInput>;
  filters?: Maybe<PostsFilters>;
  order?: Maybe<PostOrder>;
};

export type PaginatedPostsOutput = {
  __typename?: "PaginatedPostsOutput";
  data: Array<Post>;
  pagination: PaginatedResponse;
};

export type PaginatedPrepaidGoodsOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedPrepaidGoodsOutput";
  data: Array<PrepaidGood>;
  pagination: OffsetPagination;
};

export type PaginatedProductsOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedProductsOutput";
  data: Array<Product>;
  pagination: OffsetPagination;
};

export type PaginatedQuizzesOutput = CursorPaginatedResponse & {
  __typename?: "PaginatedQuizzesOutput";
  data?: Maybe<Array<Maybe<Quiz>>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedReportSummariesOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedReportSummariesOutput";
  data: Array<Report>;
  pagination: OffsetPagination;
};

export type PaginatedResponse = {
  __typename?: "PaginatedResponse";
  nextCursor?: Maybe<Scalars["String"]>;
};

export type PaginatedResponsesInput = {
  pagination?: Maybe<PaginationInput>;
  filter?: Maybe<ResponsesFilters>;
};

export type PaginatedResponsesOutput = {
  __typename?: "PaginatedResponsesOutput";
  data: Array<PostResponse>;
  pagination: PaginatedResponse;
};

export type PaginatedRightOfWayTestsOutput = CursorPaginatedResponse & {
  __typename?: "PaginatedRightOfWayTestsOutput";
  data?: Maybe<Array<RightOfWayTest>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedRoleMappingsOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedRoleMappingsOutput";
  data: Array<RoleMapping>;
  pagination: OffsetPagination;
};

export type PaginatedToeicPromoCodesOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedToeicPromoCodesOutput";
  data: Array<ToeicPromoCode>;
  pagination: OffsetPagination;
};

export type PaginatedTrainingPapersOutput = CursorPaginatedResponse & {
  __typename?: "PaginatedTrainingPapersOutput";
  data?: Maybe<Array<Maybe<PastPaper>>>;
  meta: CursorPaginatedResponseMeta;
  links: CursorPaginatedResponseLinks;
};

export type PaginatedUsersOutput = OffsetPaginatedResponse & {
  __typename?: "PaginatedUsersOutput";
  data: Array<User>;
  pagination: OffsetPagination;
};

export type PaginationInput = {
  cursor?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type Partner = {
  __typename?: "Partner";
  _id: Scalars["String"];
  name: Scalars["String"];
  mentors?: Maybe<Array<Scalars["String"]>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
  deals?: Maybe<PaginatedDealsOutput>;
};

export type PartnerDealsArgs = {
  input?: Maybe<PartnerDealsInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type PartnerDealsInput = {
  status?: Maybe<VoucherStatus>;
  client?: Maybe<Scalars["String"]>;
};

export type PartnersInput = {
  status?: Maybe<VoucherStatus>;
  mentor?: Maybe<Scalars["String"]>;
};

export type PastPaper = {
  __typename?: "PastPaper";
  _id: Scalars["String"];
  learningId?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
  slug: Scalars["String"];
  slugV2: Scalars["String"];
  document?: Maybe<Media>;
  year?: Maybe<Scalars["Int"]>;
  type?: Maybe<PastPaperType>;
  isOfficial: Scalars["Boolean"];
  html?: Maybe<Scalars["String"]>;
  author?: Maybe<Author>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType: AccessType;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars["Float"]>;
};

export enum PastPaperDocumentType {
  Embed = "embed",
  Okulus = "okulus"
}

export enum PastPaperType {
  Paper = "PAPER",
  Correction = "CORRECTION",
  CorrectedPaper = "CORRECTED_PAPER"
}

export type PatchTagsInput = {
  add?: Maybe<Array<Scalars["String"]>>;
  remove?: Maybe<Array<Scalars["String"]>>;
};

export type PathsCategories = {
  __typename?: "PathsCategories";
  paths: Array<Scalars["String"]>;
};

export enum Platform {
  Desktop = "Desktop",
  Android = "Android",
  IOs = "iOS",
  WindowsPhone = "WindowsPhone"
}

export type Playlist = {
  __typename?: "Playlist";
  data: Scalars["Json"];
};

export type Post = {
  __typename?: "Post";
  id: Scalars["ID"];
  title: Scalars["String"];
  text: Scalars["String"];
  type: PostType;
  authorId: Scalars["String"];
  author?: Maybe<RestrainedUser>;
  levels: Array<PostFacet>;
  subjects: Array<PostFacet>;
  sectors: Array<PostFacet>;
  orientations: Array<PostFacet>;
  imagePreview?: Maybe<Scalars["String"]>;
  textPreview: Scalars["String"];
  slug: Scalars["String"];
  clapCount: Scalars["Int"];
  responses: PaginatedResponsesOutput;
  createdAt: Scalars["String"];
  publishedAt?: Maybe<Scalars["String"]>;
  responsesCount: Scalars["Int"];
  deadlineAt?: Maybe<Scalars["String"]>;
};

export type PostResponsesArgs = {
  params?: Maybe<PaginationInput>;
};

export type PostByIdParams = {
  id: Scalars["String"];
};

export type PostBySlugParams = {
  slug: Scalars["String"];
};

export type PostFacet = {
  __typename?: "PostFacet";
  id: Scalars["ID"];
  label: Scalars["String"];
  type: FacetType;
};

export type PostFacetsFilters = {
  types: Array<FacetType>;
};

export enum PostOrder {
  CreatedAt = "CREATED_AT",
  LastActivity = "LAST_ACTIVITY"
}

export type PostResponse = {
  __typename?: "PostResponse";
  id: Scalars["ID"];
  text: Scalars["String"];
  authorId: Scalars["String"];
  author?: Maybe<RestrainedUser>;
  clapCount: Scalars["Int"];
  createdAt: Scalars["String"];
  publishedAt?: Maybe<Scalars["String"]>;
  postId: Scalars["String"];
  post: Post;
};

export enum PostType {
  Question = "Question",
  Resource = "Resource"
}

export type PostsFacetsOutput = {
  __typename?: "PostsFacetsOutput";
  bySubjects: Array<FacetBySubjects>;
  byLevels: Array<FacetByLevels>;
  bySectors: Array<FacetByLevels>;
  byTypes: Array<FacetByTypes>;
  byOrientations: Array<FacetByOrientations>;
};

export type PostsFilters = {
  types?: Maybe<Array<PostType>>;
  subjectIds?: Maybe<Array<Scalars["String"]>>;
  levelIds?: Maybe<Array<Scalars["String"]>>;
  sectorIds?: Maybe<Array<Scalars["String"]>>;
  orientationIds?: Maybe<Array<Scalars["String"]>>;
  hasResponse?: Maybe<Scalars["Boolean"]>;
};

export type PrepaidGood = {
  __typename?: "PrepaidGood";
  _id: Scalars["String"];
  priceId: Scalars["String"];
  ssoId: Scalars["String"];
  reservationId?: Maybe<Scalars["String"]>;
  reservation?: Maybe<Reservation>;
  consumedAt?: Maybe<Scalars["String"]>;
  source?: Maybe<PrepaidGoodSource>;
  account: StripeAccount;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type PrepaidGoodSource = {
  type: PrepaidGoodSourceType;
};

export enum PrepaidGoodSourceType {
  Cart = "CART",
  Code = "CODE",
  SubscriptionAndroid = "SUBSCRIPTION_ANDROID",
  SubscriptionIos = "SUBSCRIPTION_IOS",
  SubscriptionStripe = "SUBSCRIPTION_STRIPE"
}

export type PriceInterface = {
  _id: Scalars["String"];
  slug: Scalars["String"];
  name: Scalars["String"];
  description: Scalars["String"];
  enable: Scalars["Boolean"];
  amount: Scalars["Int"];
  currency: CurrencyType;
  externalReference: ExternalReference;
  productId?: Maybe<Scalars["String"]>;
  product: Product;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type PriceUnion = RecurringPrice | OneTimePrice;

export type Product = {
  __typename?: "Product";
  _id: Scalars["String"];
  name: Scalars["String"];
  slug: Scalars["String"];
  kind: ProductKind;
  description: Scalars["String"];
  clients: Array<Client>;
  tenant: TenantType;
  enable: Scalars["Boolean"];
  externalReference: ExternalReference;
  roles: Array<Role>;
  prices: Array<PriceUnion>;
};

export enum ProductKind {
  Recurring = "RECURRING",
  OneTime = "ONE_TIME"
}

export type ProductList = {
  __typename?: "ProductList";
  data: Scalars["Json"];
};

export type ProductPagination = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type Query = {
  __typename?: "Query";
  service: Service;
  availableReviews: Array<Maybe<ScheduledReview>>;
  /** **DEPRECATED:** use `quizProgressV2` query instead */
  quizProgress?: Maybe<Array<Maybe<QuizProgress>>>;
  quizProgressV2?: Maybe<QuizProgressPaginated>;
  lessonProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  flashcardProgress?: Maybe<Array<Maybe<FlashcardProgress>>>;
  adminQuizProgress?: Maybe<Array<Maybe<QuizProgress>>>;
  /** Returns current user statistics */
  quizzesProgressStatistics?: Maybe<UserQuizStatistics>;
  adminLessonProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  cleaResult?: Maybe<Array<Maybe<CleaResult>>>;
  listPostFacets: PaginatedPostFacetsOutput;
  postsFacet: PostsFacetsOutput;
  posts: PaginatedPostsOutput;
  postById: Post;
  postBySlug: Post;
  responses: PaginatedResponsesOutput;
  toeicPromoCodes?: Maybe<PaginatedToeicPromoCodesOutput>;
  toeicPromoCodesAdmin?: Maybe<PaginatedToeicPromoCodesOutput>;
  brief?: Maybe<Brief>;
  briefs?: Maybe<PaginatedBriefsOutput>;
  briefsFromCategory?: Maybe<PaginatedBriefsOutput>;
  flashcardV2?: Maybe<FlashcardV2>;
  flashcardsV2?: Maybe<PaginatedFlashcardsV2Output>;
  /**
   * DEPRECATED
   * prefer using flashcardsV2FromCategoryV2
   */
  flashcardsV2FromCategory?: Maybe<Array<Maybe<FlashcardV2>>>;
  flashcardsV2FromCategoryV2?: Maybe<PaginatedFlashcardsV2Output>;
  /** **DEPRECATED:** use `categoriesV2` query instead */
  categories?: Maybe<Array<Maybe<Category>>>;
  categoriesV2?: Maybe<PaginatedCategoriesOutput>;
  category?: Maybe<Category>;
  /** **DEPRECATED:** use `trainingPapers` query instead */
  pastPapers?: Maybe<Array<Maybe<PastPaper>>>;
  /** **DEPRECATED:** use `trainingPaper` query instead */
  pastPaper?: Maybe<PastPaper>;
  /** **DEPRECATED:** use `flashcardsV2` query instead */
  flashcards?: Maybe<Array<Maybe<Flashcard>>>;
  /** **DEPRECATED:** use `flashcardV2` query instead */
  flashcard?: Maybe<Flashcard>;
  /** **DEPRECATED:** use `flashcardsV2FromCategory` instead */
  flashcardsFromCategory?: Maybe<Array<Maybe<Flashcard>>>;
  /** **DEPRECATED:** use `trainingPapersFromCategory` instead */
  pastPapersFromCategory?: Maybe<Array<Maybe<PastPaper>>>;
  categoriesPaths?: Maybe<PathsCategories>;
  activitiesSlugs?: Maybe<ActivitiesSlugs>;
  lesson?: Maybe<Lesson>;
  /**
   * **DEPRECATED:** use `lessonsSearchV2` instead
   * @deprecated Field no longer supported
   */
  lessonsSearch?: Maybe<PaginatedLessonsOutput>;
  lessonsSearchV2?: Maybe<PaginatedLessonsOutput>;
  lessonsV2?: Maybe<PaginatedLessonsOutput>;
  lessonsFromCategoryV2?: Maybe<PaginatedLessonsOutput>;
  /**
   * **DEPRECATED:** use `lessonsV2` instead
   * @deprecated Field no longer supported
   */
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  /** **DEPRECATED:** use `lessonsFromCategoryV2` instead */
  lessonsFromCategory?: Maybe<Array<Maybe<Lesson>>>;
  quizzesV2?: Maybe<PaginatedQuizzesOutput>;
  randomQuizzes?: Maybe<Array<Maybe<Quiz>>>;
  quizzesFromCategoryV2?: Maybe<PaginatedQuizzesOutput>;
  quiz?: Maybe<Quiz>;
  exercise?: Maybe<Exercise>;
  /** **DEPRECATED:** don't use since questions are now nested in exercise */
  question?: Maybe<Question>;
  generatedQuiz: Quiz;
  customGeneratedQuiz?: Maybe<CustomGeneratedQuiz>;
  /**
   * **DEPRECATED:** use `quizzesSearchV2` instead
   * @deprecated Field no longer supported
   */
  quizzesSearch?: Maybe<PaginatedQuizzesOutput>;
  quizzesSearchV2?: Maybe<PaginatedQuizzesOutput>;
  /**
   * **DEPRECATED:** use `quizzesV2` instead
   * @deprecated Field no longer supported
   */
  quizzes?: Maybe<Array<Maybe<Quiz>>>;
  /**
   * **DEPRECATED:** use `quizzesFromCategoryV2` instead
   * @deprecated Field no longer supported
   */
  quizzesFromCategory?: Maybe<Array<Maybe<Quiz>>>;
  rightOfWayTests?: Maybe<Array<Maybe<RightOfWayTest>>>;
  rightOfWayTest?: Maybe<RightOfWayTest>;
  /** **DEPRECATED:** use `rightOfWayTestsFromCategoryV2` instead */
  rightOfWayTestsFromCategory?: Maybe<Array<Maybe<RightOfWayTest>>>;
  rightOfWayTestsFromCategoryV2?: Maybe<PaginatedRightOfWayTestsOutput>;
  trainingPapersV2?: Maybe<PaginatedTrainingPapersOutput>;
  trainingPaper?: Maybe<PastPaper>;
  trainingPapersFromCategoryV2?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * **DEPRECATED:** use `trainingPapersSearchV2` query instead
   * @deprecated Field no longer supported
   */
  trainingPapersSearch?: Maybe<PaginatedTrainingPapersOutput>;
  trainingPapersSearchV2?: Maybe<PaginatedTrainingPapersOutput>;
  /** **DEPRECATED:** use `trainingPapersV2` query instead */
  trainingPapers?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * **DEPRECATED:** use `trainingPapersFromCategoryV2` instead
   * @deprecated Field no longer supported
   */
  trainingPapersFromCategory?: Maybe<PaginatedTrainingPapersOutput>;
  /**
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  users?: Maybe<Array<Maybe<User>>>;
  /**
   * If userId is specified, the token in the authorization header must be admin.
   * If no userId is specified the autorization token will be used as ID.
   * client represent the keycloak client ID used for the project using the query (ex: toeic, bac, brevet, etc...), remember
   * to set it to `feuvert` when needed, else you will not contact the right Lilsis
   */
  user?: Maybe<User>;
  childUsers?: Maybe<PaginatedUsersOutput>;
  childUser?: Maybe<User>;
  mentees?: Maybe<PaginatedUsersOutput>;
  mentee?: Maybe<User>;
  schools?: Maybe<Array<Maybe<School>>>;
  school?: Maybe<School>;
  /**
   * # Deprecated
   * Use `situationConstraintsv2` for better null handling
   * @deprecated Use `situationConstraintsv2` for better null handling
   */
  situationConstraints?: Maybe<SituationConstraints>;
  situationConstraintsv2?: Maybe<SituationConstraintsV2>;
  tag?: Maybe<UserTag>;
  tags: Array<UserTag>;
  file?: Maybe<File>;
  /**
   * `filesIdAndCommand` can be an array of okulus ids like 025807e9-38c4-4bd0-9e96-e3d95422eb5b but also can have optionnal resize flags like
   * 025807e9-38c4-4bd0-9e96-e3d95422eb5b_w1125h2436 for example
   */
  offlineMedias?: Maybe<Scalars["String"]>;
  cart?: Maybe<Cart>;
  carts?: Maybe<PaginatedCartsOutput>;
  /** @deprecated on stripe subscriptions is supported, use `subscriptionsV2` instead */
  subscriptions?: Maybe<Array<Maybe<StripeSubscription>>>;
  roleMappings?: Maybe<PaginatedRoleMappingsOutput>;
  invoiceCodeDeLaRoute: ResultBase64;
  prepaidGoods?: Maybe<PaginatedPrepaidGoodsOutput>;
  cartV2?: Maybe<CartV2>;
  cartsV2?: Maybe<PaginatedCartsV2Output>;
  price: PriceUnion;
  products?: Maybe<PaginatedProductsOutput>;
  productBySlug?: Maybe<Product>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  defaultStripePaymentMethod?: Maybe<StripePaymentMethod>;
  subscriptionsV2?: Maybe<SubscriptionListOutput>;
  adminSubscriptionsV2?: Maybe<SubscriptionListOutput>;
  codesV3?: Maybe<PaginatedCodesOutput>;
  dealsV2?: Maybe<PaginatedDealsOutput>;
  deal?: Maybe<Deal>;
  gainsV2?: Maybe<PaginatedGainsOutput>;
  gain?: Maybe<Gain>;
  partners?: Maybe<PaginatedPartnersOutput>;
  partner?: Maybe<Partner>;
  /**
   * # Deprecated
   * Use `codesv2`, because this one does not return pagination information
   * @deprecated Use `codesv2`, because this one does not return pagination information
   */
  codes?: Maybe<Array<Maybe<Code>>>;
  /** @deprecated Use `codesv3` */
  codesv2?: Maybe<PaginatedCodesOutput>;
  /** @deprecated Use `dealsv2`, because this one does not return pagination information */
  deals?: Maybe<Array<Maybe<Deal>>>;
  /** @deprecated Use `gainsv2`, because this one does not return pagination information */
  gains?: Maybe<Array<Maybe<Gain>>>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
  reasonsAdmin?: Maybe<Array<Maybe<Reason>>>;
  reports?: Maybe<Array<Maybe<Report>>>;
  reportsv2?: Maybe<PaginatedReportSummariesOutput>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  sessionsBySites?: Maybe<Array<Maybe<Session>>>;
  sites?: Maybe<Array<Maybe<Site>>>;
  site?: Maybe<Site>;
  convocation?: Maybe<Convocation>;
  resultPDF?: Maybe<ResultPdf>;
  adminReservations?: Maybe<Array<Maybe<Reservation>>>;
  /** Returns the reservations for the currently logged in user, can be accessed inside the `user` query too */
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  reservation?: Maybe<Reservation>;
  cleaReservation?: Maybe<CleaReservation>;
  cleaReservations?: Maybe<PaginatedCleaReservation>;
  productList?: Maybe<ProductList>;
  /**
   * # Deprecated
   * Use `timev2`, because this one does not use an input field and is missing some fields.
   * @deprecated Use `timev2`, because this one does not use an input.
   */
  time?: Maybe<Time>;
  timev2?: Maybe<Time>;
  youtubePlaylist?: Maybe<Playlist>;
};

export type QueryAvailableReviewsArgs = {
  input: AvailableReviewsInput;
};

export type QueryQuizProgressArgs = {
  created_after?: Maybe<Scalars["String"]>;
  updated_after?: Maybe<Scalars["String"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  section?: Maybe<BookmarkSection>;
  learning_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<BookmarkProduct>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars["Boolean"]>;
  best_scores?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<BookmarkType>;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
};

export type QueryQuizProgressV2Args = {
  created_after?: Maybe<Scalars["String"]>;
  updated_after?: Maybe<Scalars["String"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  section?: Maybe<BookmarkSection>;
  learning_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars["Boolean"]>;
  best_scores?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<BookmarkType>;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
  sort?: Maybe<QuizProgressV2SortInput>;
};

export type QueryLessonProgressArgs = {
  created_after?: Maybe<Scalars["String"]>;
  updated_after?: Maybe<Scalars["String"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  learning_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  status?: Maybe<BookmarkStatus>;
  section?: Maybe<BookmarkSection>;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
};

export type QueryFlashcardProgressArgs = {
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
  sort?: Maybe<FlashcardProgressSortInput>;
};

export type QueryAdminQuizProgressArgs = {
  sso_id?: Maybe<Scalars["String"]>;
  created_after?: Maybe<Scalars["String"]>;
  updated_after?: Maybe<Scalars["String"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  section?: Maybe<BookmarkSection>;
  learning_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<BookmarkProduct>;
  products?: Maybe<Array<BookmarkProduct>>;
  status?: Maybe<BookmarkStatus>;
  only_last_results?: Maybe<Scalars["Boolean"]>;
  best_scores?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<BookmarkType>;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
  sort?: Maybe<QuizProgressV2SortInput>;
};

export type QueryQuizzesProgressStatisticsArgs = {
  sso_id: Scalars["String"];
  gutenberg_uuid: Scalars["String"];
  type?: Maybe<BookmarkType>;
};

export type QueryAdminLessonProgressArgs = {
  sso_id?: Maybe<Array<Maybe<Scalars["String"]>>>;
  created_after?: Maybe<Scalars["String"]>;
  updated_after?: Maybe<Scalars["String"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  learning_id?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  product?: Maybe<BookmarkProduct>;
  status?: Maybe<BookmarkStatus>;
  page?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
};

export type QueryCleaResultArgs = {
  id?: Maybe<Scalars["String"]>;
};

export type QueryListPostFacetsArgs = {
  params?: Maybe<PaginatedPostFacetsInput>;
};

export type QueryPostsFacetArgs = {
  params?: Maybe<PostsFilters>;
};

export type QueryPostsArgs = {
  params?: Maybe<PaginatedPostsInput>;
};

export type QueryPostByIdArgs = {
  params: PostByIdParams;
};

export type QueryPostBySlugArgs = {
  params: PostBySlugParams;
};

export type QueryResponsesArgs = {
  params?: Maybe<PaginatedResponsesInput>;
};

export type QueryToeicPromoCodesArgs = {
  pagination?: Maybe<OffsetPaginationInput>;
};

export type QueryToeicPromoCodesAdminArgs = {
  search?: Maybe<ToeicPromoCodesAdminSearchInput>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type QueryBriefArgs = {
  id: Scalars["String"];
};

export type QueryBriefsArgs = {
  search?: Maybe<BriefsSearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryBriefsFromCategoryArgs = {
  idOrPath: Scalars["String"];
  search?: Maybe<BriefsFromCategorySearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryFlashcardV2Args = {
  id: Scalars["String"];
  languageCombination?: Maybe<LanguageCombinationInput>;
};

export type QueryFlashcardsV2Args = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  status?: Maybe<Status>;
  flashcardType?: Maybe<FlashcardType>;
  excluded_ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  bookmarkProduct?: Maybe<BookmarkProduct>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};

export type QueryFlashcardsV2FromCategoryArgs = {
  path?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};

export type QueryFlashcardsV2FromCategoryV2Args = {
  path: Scalars["String"];
  search?: Maybe<FlashcardsV2FromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
  languageCombination?: Maybe<LanguageCombinationInput>;
};

export type QueryCategoriesArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  filter?: Maybe<CategoryFilter>;
  learningIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type QueryCategoriesV2Args = {
  search?: Maybe<CategoriesV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryCategoryArgs = {
  idOrPath: Scalars["String"];
};

export type QueryPastPapersArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  status?: Maybe<Status>;
};

export type QueryPastPaperArgs = {
  id: Scalars["String"];
};

export type QueryFlashcardsArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  status?: Maybe<Status>;
  excluded_ids?: Maybe<Array<Maybe<Scalars["String"]>>>;
  bookmarkProduct?: Maybe<BookmarkProduct>;
};

export type QueryFlashcardArgs = {
  id: Scalars["String"];
};

export type QueryFlashcardsFromCategoryArgs = {
  path?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryPastPapersFromCategoryArgs = {
  path: Scalars["String"];
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryCategoriesPathsArgs = {
  basePath?: Maybe<Scalars["String"]>;
  platform?: Maybe<CategoryPlatformFilter>;
};

export type QueryActivitiesSlugsArgs = {
  activity: ActivityType;
  categoryPathsRecursive?: Maybe<Array<Scalars["String"]>>;
};

export type QueryLessonArgs = {
  id: Scalars["String"];
};

export type QueryLessonsSearchArgs = {
  query: Scalars["String"];
  product: GutenbergProduct;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryLessonsSearchV2Args = {
  query: Scalars["String"];
  products: Array<GutenbergProduct>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryLessonsV2Args = {
  search?: Maybe<LessonsV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryLessonsFromCategoryV2Args = {
  idOrPath: Scalars["String"];
  search?: Maybe<LessonsFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryLessonsArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  learningIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryLessonsFromCategoryArgs = {
  path?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryQuizzesV2Args = {
  search?: Maybe<QuizzesV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryRandomQuizzesArgs = {
  size?: Maybe<Scalars["Int"]>;
  categoryIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryQuizzesFromCategoryV2Args = {
  idOrPath: Scalars["String"];
  search?: Maybe<QuizzesFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryQuizArgs = {
  id: Scalars["String"];
};

export type QueryExerciseArgs = {
  id: Scalars["String"];
};

export type QueryQuestionArgs = {
  id: Scalars["String"];
};

export type QueryGeneratedQuizArgs = {
  criteriaId: Scalars["String"];
};

export type QueryCustomGeneratedQuizArgs = {
  criteria: GeneratedQuizCriteriaInput;
};

export type QueryQuizzesSearchArgs = {
  query: Scalars["String"];
  product: GutenbergProduct;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryQuizzesSearchV2Args = {
  query: Scalars["String"];
  products: Array<GutenbergProduct>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryQuizzesArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  learningIds?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryQuizzesFromCategoryArgs = {
  path?: Maybe<Scalars["String"]>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryRightOfWayTestsArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  slugs?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type QueryRightOfWayTestArgs = {
  id: Scalars["String"];
};

export type QueryRightOfWayTestsFromCategoryArgs = {
  path: Scalars["String"];
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryRightOfWayTestsFromCategoryV2Args = {
  idOrPath: Scalars["String"];
  search?: Maybe<RightOfWayTestsFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryTrainingPapersV2Args = {
  search?: Maybe<TrainingPapersSearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryTrainingPaperArgs = {
  id: Scalars["String"];
};

export type QueryTrainingPapersFromCategoryV2Args = {
  idOrPath: Scalars["String"];
  search?: Maybe<TrainingPapersFromCategoryV2SearchInput>;
  pagination?: Maybe<GutenbergPaginationInput>;
};

export type QueryTrainingPapersSearchArgs = {
  query: Scalars["String"];
  product: GutenbergProduct;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryTrainingPapersSearchV2Args = {
  query: Scalars["String"];
  products: Array<GutenbergProduct>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type QueryTrainingPapersArgs = {
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  status?: Maybe<Status>;
  isOfficial?: Maybe<Scalars["Boolean"]>;
};

export type QueryTrainingPapersFromCategoryArgs = {
  idOrPath: Scalars["String"];
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
  isOfficial?: Maybe<Scalars["Boolean"]>;
};

export type QueryUsersArgs = {
  client: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  neph?: Maybe<Scalars["String"]>;
  cleaId?: Maybe<Scalars["String"]>;
};

export type QueryUserArgs = {
  client?: Maybe<Scalars["String"]>;
  platform?: Maybe<Platform>;
  userId?: Maybe<Scalars["String"]>;
};

export type QueryChildUsersArgs = {
  input: ChildUsersInput;
};

export type QueryChildUserArgs = {
  userId: Scalars["String"];
  client: Scalars["String"];
};

export type QueryMenteesArgs = {
  input: MenteesInput;
};

export type QueryMenteeArgs = {
  userId: Scalars["String"];
  client: Scalars["String"];
};

export type QuerySchoolsArgs = {
  query: Scalars["String"];
  schoolType: SchoolType;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
};

export type QuerySchoolArgs = {
  id: Scalars["String"];
};

export type QuerySituationConstraintsArgs = {
  version?: Maybe<Scalars["String"]>;
};

export type QuerySituationConstraintsv2Args = {
  version?: Maybe<Scalars["String"]>;
};

export type QueryTagArgs = {
  id: Scalars["String"];
};

export type QueryFileArgs = {
  id?: Maybe<Scalars["String"]>;
};

export type QueryOfflineMediasArgs = {
  filesIdAndCommand: Array<Scalars["String"]>;
};

export type QueryCartArgs = {
  id: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryCartsArgs = {
  input?: Maybe<CartsInput>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QuerySubscriptionsArgs = {
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryRoleMappingsArgs = {
  platform?: Maybe<RoleMappingPlatform>;
  productId?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type QueryInvoiceCodeDeLaRouteArgs = {
  date: Scalars["String"];
};

export type QueryPrepaidGoodsArgs = {
  priceId?: Maybe<Scalars["String"]>;
  isConsumed?: Maybe<Scalars["Boolean"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryCartV2Args = {
  id: Scalars["String"];
  tenant: TenantType;
};

export type QueryCartsV2Args = {
  tenant: TenantType;
  input?: Maybe<CartsV2Input>;
  pagination?: Maybe<OffsetPaginationInput>;
};

export type QueryPriceArgs = {
  tenant: TenantType;
  id: Scalars["String"];
};

export type QueryProductsArgs = {
  tenant: TenantType;
  clients?: Maybe<Array<Client>>;
  pagination?: Maybe<ProductPagination>;
  enable?: Maybe<Scalars["Boolean"]>;
};

export type QueryProductBySlugArgs = {
  tenant: TenantType;
  slug: Scalars["String"];
};

export type QueryStripePaymentMethodsArgs = {
  client?: Maybe<Scalars["String"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryDefaultStripePaymentMethodArgs = {
  client?: Maybe<Scalars["String"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QuerySubscriptionsV2Args = {
  pagination?: Maybe<SimpleCursorPaginationInput>;
};

export type QueryAdminSubscriptionsV2Args = {
  input?: Maybe<AdminSubscriptionListInput>;
  pagination?: Maybe<SimpleCursorPaginationInput>;
};

export type QueryCodesV3Args = {
  input?: Maybe<CodesV3Input>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryDealsV2Args = {
  input?: Maybe<DealsV2Input>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryDealArgs = {
  id: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryGainsV2Args = {
  input?: Maybe<GainsV2Input>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryGainArgs = {
  id: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryPartnersArgs = {
  input?: Maybe<PartnersInput>;
  pagination?: Maybe<OffsetPaginationInput>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryPartnerArgs = {
  id: Scalars["String"];
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryCodesArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryCodesv2Args = {
  createdAt?: Maybe<Scalars["String"]>;
  deal?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  stripeAccount?: Maybe<StripeAccount>;
  activatedBy?: Maybe<Scalars["String"]>;
};

export type QueryDealsArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryGainsArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  stripeAccount?: Maybe<StripeAccount>;
};

export type QueryReasonsArgs = {
  locale?: Maybe<Locale>;
};

export type QueryReportsArgs = {
  input?: Maybe<ReportSummariesInput>;
};

export type QueryReportsv2Args = {
  input?: Maybe<ReportSummariesInput>;
};

export type QuerySessionsArgs = {
  input: SessionInput;
};

export type QuerySessionsBySitesArgs = {
  input: SessionBySitesInput;
};

export type QuerySitesArgs = {
  provider: ReservationProviders;
  departement?: Maybe<Scalars["String"]>;
};

export type QuerySiteArgs = {
  id: Scalars["String"];
  provider: ReservationProviders;
};

export type QueryConvocationArgs = {
  id: Scalars["String"];
};

export type QueryResultPdfArgs = {
  id: Scalars["String"];
};

export type QueryAdminReservationsArgs = {
  input?: Maybe<AdminReservationInput>;
};

export type QueryReservationsArgs = {
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
};

export type QueryReservationArgs = {
  id: Scalars["String"];
};

export type QueryCleaReservationArgs = {
  id: Scalars["String"];
};

export type QueryCleaReservationsArgs = {
  inputs?: Maybe<CleaReservationPaginationInput>;
};

export type QueryTimeArgs = {
  userID?: Maybe<Scalars["String"]>;
  learningCategorie?: Maybe<Scalars["String"]>;
  learningProduct?: Maybe<Scalars["String"]>;
  learningType?: Maybe<LearningType>;
  learningID?: Maybe<Scalars["Int"]>;
};

export type QueryTimev2Args = {
  input?: Maybe<TimeInput>;
};

export type QueryYoutubePlaylistArgs = {
  maxResults: Scalars["Int"];
  part: Scalars["String"];
  playlistId?: Maybe<Scalars["String"]>;
};

export type Question = {
  __typename?: "Question";
  _id: Scalars["String"];
  learningId: Scalars["Int"];
  score: Score;
  text?: Maybe<Scalars["String"]>;
  audioText?: Maybe<Scalars["String"]>;
  explanation: Explanation;
  type: QuestionType;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  possibleAnswers?: Maybe<Array<Maybe<Answer>>>;
  isCaseSensitive?: Maybe<Scalars["Boolean"]>;
};

export enum QuestionType {
  QcmSingle = "QCM_SINGLE",
  QcmMultiple = "QCM_MULTIPLE",
  MissingWordUnique = "MISSING_WORD_UNIQUE",
  MissingWordMultiple = "MISSING_WORD_MULTIPLE",
  MissingWordFreetext = "MISSING_WORD_FREETEXT",
  MixedSentence = "MIXED_SENTENCE"
}

export type Quiz = {
  __typename?: "Quiz";
  _id: Scalars["String"];
  learningId: Scalars["Int"];
  startScore: Scalars["Int"];
  title: Scalars["String"];
  slug: Scalars["String"];
  slugV2: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  maximalScore: Scalars["Int"];
  successScore: Scalars["Int"];
  questionCount: Scalars["Int"];
  difficulty?: Maybe<QuizDifficulty>;
  author?: Maybe<Author>;
  assetLinks?: Maybe<Array<Maybe<AssetLink>>>;
  okulusAssets?: Maybe<Array<Maybe<File>>>;
  status?: Maybe<Status>;
  accessType?: Maybe<AccessType>;
  exerciseList?: Maybe<Array<Maybe<Scalars["String"]>>>;
  exerciseListData?: Maybe<Array<Maybe<Exercise>>>;
  estimatedTime?: Maybe<Scalars["Int"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  parentCategories?: Maybe<Array<Maybe<Category>>>;
  relatedActivities?: Maybe<RelatedActivities>;
  relatedActivitiesData?: Maybe<Array<Maybe<RelatedActivitiesData>>>;
  elapsedTime?: Maybe<Time>;
  bookmark?: Maybe<Array<Maybe<QuizProgress>>>;
  /** Only set when using search */
  searchScore?: Maybe<Scalars["Float"]>;
};

export type QuizElapsedTimeArgs = {
  search?: Maybe<TimeInput>;
};

export enum QuizDifficulty {
  Novice = "NOVICE",
  Beginner = "BEGINNER",
  Intermediate = "INTERMEDIATE",
  Confirmed = "CONFIRMED",
  Expert = "EXPERT"
}

export type QuizProgress = {
  __typename?: "QuizProgress";
  id: Scalars["Int"];
  learning_id?: Maybe<Scalars["Int"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  section?: Maybe<BookmarkSection>;
  type?: Maybe<BookmarkType>;
  status: BookmarkStatus;
  product?: Maybe<BookmarkProduct>;
  current_question_id?: Maybe<Scalars["Int"]>;
  score?: Maybe<Scalars["Int"]>;
  created_at: Scalars["String"];
  updated_at?: Maybe<Scalars["String"]>;
  quiz?: Maybe<Quiz>;
};

export type QuizProgressInput = {
  learning_id?: Maybe<Scalars["Int"]>;
  gutenberg_uuid?: Maybe<Scalars["String"]>;
  type: BookmarkType;
  section?: Maybe<BookmarkSection>;
  status: BookmarkStatus;
  product: BookmarkProduct;
  current_question_id?: Maybe<Scalars["Int"]>;
  score: Scalars["Int"];
};

export type QuizProgressPaginated = OffsetPaginatedResponse & {
  __typename?: "QuizProgressPaginated";
  data: Array<QuizProgress>;
  pagination: OffsetPagination;
};

export type QuizProgressV2SortInput = {
  id?: Maybe<Sort>;
  created_at?: Maybe<Sort>;
};

export type QuizzesFromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type QuizzesV2SearchInput = {
  learningIds?: Maybe<Array<Scalars["Int"]>>;
  slugs?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Status>;
  product?: Maybe<GutenbergProduct>;
  tags?: Maybe<Array<Scalars["String"]>>;
  createdAfter?: Maybe<Scalars["String"]>;
  updatedAfter?: Maybe<Scalars["String"]>;
};

export type Reason = {
  __typename?: "Reason";
  slug: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  /**
   * # Admin only
   * Only returned when using the `reasonsAdmin` query
   */
  labelList?: Maybe<LabelList>;
};

export type ReasonInput = {
  slug: Scalars["String"];
  label: LabelListInput;
};

export type RecurringPrice = PriceInterface & {
  __typename?: "RecurringPrice";
  _id: Scalars["String"];
  slug: Scalars["String"];
  name: Scalars["String"];
  description: Scalars["String"];
  enable: Scalars["Boolean"];
  amount: Scalars["Int"];
  currency: CurrencyType;
  frequency: Frequency;
  externalReference: ExternalReference;
  productId?: Maybe<Scalars["String"]>;
  product: Product;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type RecursiveCount = {
  __typename?: "RecursiveCount";
  lessons: Scalars["Int"];
  quizzes: Scalars["Int"];
  pastPapers: Scalars["Int"];
  flashcards: Scalars["Int"];
  flashcardsV2: Scalars["Int"];
  rightOfWayTests: Scalars["Int"];
};

export type Reference = {
  __typename?: "Reference";
  id: Scalars["String"];
};

export type RelatedActivities = {
  __typename?: "RelatedActivities";
  _id?: Maybe<Scalars["String"]>;
  lessons?: Maybe<Array<Maybe<Scalars["String"]>>>;
  quizzes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  flashcards?: Maybe<Array<Maybe<Scalars["String"]>>>;
  flashcardsV2?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pastPapers?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type RelatedActivitiesData =
  | Lesson
  | Quiz
  | PastPaper
  | Flashcard
  | BacFrenchStylisticDeviceFlashcard
  | BacHistoryLandmarkFlashcard
  | BacPhilosophyQuoteFlashcard
  | BrevetFrenchStylisticDeviceFlashcard
  | BrevetHistoryLandmarkFlashcard
  | BrevetChemistryMoleculeFlashcard
  | FeuVertRoadSignFlashcard
  | CdrRoadSignFlashcard
  | MosalinguaFlashcard;

export type Report = {
  __typename?: "Report";
  contentId: Scalars["String"];
  contentTitle: Scalars["String"];
  contentType: ReportContentType;
  content?: Maybe<RelatedActivitiesData>;
  /**
   * # Deprecated
   * Use `questionId` for Gutenberg content
   * @deprecated Use `questionId` for Gutenberg content
   */
  questionNumber?: Maybe<Scalars["Int"]>;
  questionId?: Maybe<Scalars["String"]>;
  client: Scalars["String"];
  count?: Maybe<Scalars["Int"]>;
  lastReportedAt?: Maybe<Scalars["String"]>;
  byReason?: Maybe<Array<Maybe<ReportReason>>>;
};

export enum ReportContentType {
  Quiz = "quiz",
  Lesson = "lesson"
}

export type ReportInput = {
  client: Scalars["String"];
  reporterSsoId?: Maybe<Scalars["String"]>;
  platform: ReportPlatform;
  contentId: Scalars["String"];
  contentTitle: Scalars["String"];
  contentType: ReportContentType;
  /**
   * # Deprecated
   * Use `questionId` for Gutenberg content
   */
  questionNumber?: Maybe<Scalars["Int"]>;
  questionId?: Maybe<Scalars["String"]>;
  reasonSlug: Scalars["String"];
  comment?: Maybe<Scalars["String"]>;
};

export enum ReportPlatform {
  Ios = "ios",
  Android = "android",
  Web = "web"
}

export type ReportReason = {
  __typename?: "ReportReason";
  reasonSlug?: Maybe<Scalars["String"]>;
  reasonLabel?: Maybe<Scalars["String"]>;
  comments?: Maybe<Array<Maybe<Scalars["String"]>>>;
  count?: Maybe<Scalars["Int"]>;
  lastReportedAt?: Maybe<Scalars["String"]>;
};

export enum ReportService {
  Bac = "BAC",
  Cdr = "CDR",
  Brevet = "BREVET",
  Orthographe = "ORTHOGRAPHE",
  Feuvert = "FEUVERT",
  Toeic = "TOEIC",
  Exercice = "EXERCICE",
  Digischool = "DIGISCHOOL",
  Orientation = "ORIENTATION",
  Alternance = "ALTERNANCE",
  Clea = "CLEA",
  Secours = "SECOURS",
  FonctionPublique = "FONCTION_PUBLIQUE",
  Primaire = "PRIMAIRE",
  College = "COLLEGE",
  Lycee = "LYCEE",
  Bts = "BTS",
  GrfPedagogique = "GRF_PEDAGOGIQUE",
  GrfCommercial = "GRF_COMMERCIAL"
}

export type ReportSolve = {
  __typename?: "ReportSolve";
  solvedCount: Scalars["Int"];
};

export type ReportSolveInput = {
  contentId: Scalars["String"];
  contentType: ReportContentType;
  /**
   * # Deprecated
   * Use `questionId` for Gutenberg content
   */
  questionNumber?: Maybe<Scalars["Int"]>;
  questionId?: Maybe<Scalars["String"]>;
  reasons?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export enum ReportSortType {
  Date = "date",
  Count = "count"
}

export type ReportSummariesInput = {
  client?: Maybe<Array<Maybe<Scalars["String"]>>>;
  platform?: Maybe<Array<Maybe<ReportPlatform>>>;
  reason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  contentId?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  sort?: Maybe<ReportSortType>;
};

export type Reservation = {
  __typename?: "Reservation";
  _id?: Maybe<Scalars["String"]>;
  reservationProviderId?: Maybe<Scalars["String"]>;
  session?: Maybe<Session>;
  sessionProviderId: Scalars["String"];
  sessionBeginAt: Scalars["String"];
  gender: Gender;
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  email: Scalars["String"];
  continentDeNaissance?: Maybe<Scalars["String"]>;
  birthday: Scalars["String"];
  phoneNumber: Scalars["String"];
  ssoId: Scalars["String"];
  status: ReservationStatus;
  result: ReservationResult;
  provider: ReservationProviders;
  neph: Scalars["String"];
  category: ReservationCategory;
  resolvedAt?: Maybe<Scalars["String"]>;
  paymentId?: Maybe<Scalars["String"]>;
  hasReducedMobility?: Maybe<Scalars["Boolean"]>;
  canceledAt?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  failureReason?: Maybe<Scalars["String"]>;
};

export type ReservationCandidatInput = {
  gender: Gender;
  lastName: Scalars["String"];
  firstName: Scalars["String"];
  birthday: Scalars["String"];
  phoneNumber: Scalars["String"];
  email: Scalars["String"];
  neph: Scalars["String"];
  hasReducedMobility: Scalars["Boolean"];
};

export enum ReservationCategory {
  Auto = "AUTO",
  Moto = "MOTO"
}

export type ReservationInput = {
  session: ReservationSessionInput;
  candidat: ReservationCandidatInput;
  provider: ReservationProviders;
};

/** **ALL** is only useable on listing queries like `sites` or `sessions` and can never be returned as a query response */
export enum ReservationProviders {
  Laposte = "LAPOSTE",
  Sgs = "SGS",
  All = "ALL"
}

export enum ReservationResult {
  Unknown = "UNKNOWN",
  Sucess = "SUCESS",
  Failed = "FAILED"
}

export type ReservationSessionInput = {
  id: Scalars["String"];
  category: ReservationCategory;
  beginAt: Scalars["String"];
};

export enum ReservationStatus {
  Initialized = "INITIALIZED",
  Pending = "PENDING",
  Canceled = "CANCELED",
  Resolved = "RESOLVED",
  Failed = "FAILED"
}

export type ResponseLevel = {
  __typename?: "ResponseLevel";
  id: Scalars["ID"];
  label: Scalars["String"];
};

export type ResponseSubject = {
  __typename?: "ResponseSubject";
  id: Scalars["ID"];
  label: Scalars["String"];
};

export enum ResponseType {
  Question = "Question"
}

export type ResponsesFilters = {
  type?: Maybe<ResponseType>;
  postId?: Maybe<Scalars["String"]>;
};

export type RestrainedUser = {
  __typename?: "RestrainedUser";
  id: Scalars["String"];
  userName: Scalars["String"];
  avatar: Scalars["String"];
  verified: Scalars["Boolean"];
};

export type ResultBase64 = {
  __typename?: "ResultBase64";
  base64: Scalars["String"];
};

export type ResultPdf = {
  __typename?: "ResultPDF";
  pdf: Scalars["String"];
};

export type RightOfWayTest = {
  __typename?: "RightOfWayTest";
  _id: Scalars["String"];
  title: Scalars["String"];
  explanation: Scalars["String"];
  video: Media;
  okulusAssets: Array<File>;
  chapters: Array<VideoChapter>;
  areas: Array<Area>;
};

export type RightOfWayTestsFromCategoryV2SearchInput = {
  status?: Maybe<Status>;
};

export type Role = {
  __typename?: "Role";
  clientOrRealm: Scalars["String"];
  name: Scalars["String"];
};

export type RoleMapping = {
  __typename?: "RoleMapping";
  platform: RoleMappingPlatform;
  productId: Scalars["String"];
  platformId?: Maybe<Scalars["String"]>;
  role: RoleMappingRole;
};

export type RoleMappingInput = {
  platform: RoleMappingPlatform;
  productId: Scalars["String"];
  platformId?: Maybe<Scalars["String"]>;
  role: RoleMappingRoleInput;
};

export enum RoleMappingPlatform {
  Android = "android",
  Ios = "ios",
  Stripe = "stripe"
}

export type RoleMappingRole = {
  __typename?: "RoleMappingRole";
  clientOrRealm: Scalars["String"];
  name: Scalars["String"];
};

export type RoleMappingRoleInput = {
  clientOrRealm: Scalars["String"];
  name: Scalars["String"];
};

export type Roles = {
  __typename?: "Roles";
  realm?: Maybe<Array<Maybe<Scalars["String"]>>>;
  client?: Maybe<Scalars["Json"]>;
};

export type ScheduleReviewInput = {
  activityType: ActivityType;
  activityId: Scalars["String"];
  language: Language;
  grade: Scalars["Int"];
};

export type ScheduledReview = {
  __typename?: "ScheduledReview";
  _id: Scalars["String"];
  activityType: ActivityType;
  activityId: Scalars["String"];
  language: Language;
  isLongTimeMemorized: Scalars["Boolean"];
  reviewDate: Scalars["String"];
  activity?: Maybe<RelatedActivitiesData>;
};

export type ScheduledReviewActivityArgs = {
  input: LanguageCombinationInput;
};

export type School = {
  __typename?: "School";
  schoolId: Scalars["String"];
  name: Scalars["String"];
  schoolType: SchoolType;
  departmentCode: Scalars["String"];
  town: Scalars["String"];
};

export enum SchoolType {
  Ecole = "ECOLE",
  College = "COLLEGE",
  Lycee = "LYCEE",
  Erea = "EREA"
}

export type Score = {
  __typename?: "Score";
  success: Scalars["Float"];
  failure: Scalars["Float"];
};

export type Sector = {
  __typename?: "Sector";
  slug: Scalars["String"];
  label: Scalars["String"];
};

export type Service = {
  __typename?: "Service";
  name: Scalars["String"];
  version: Scalars["String"];
  schema: Scalars["String"];
};

export type Services = {
  __typename?: "Services";
  codedelaroute?: Maybe<CodedelarouteService>;
  mosalingua?: Maybe<MosalinguaService>;
};

export type ServicesPatchInput = {
  codedelaroute?: Maybe<CodedelarouteServiceInput>;
  mosalingua?: Maybe<MosalinguaServiceInput>;
};

export type Session = {
  __typename?: "Session";
  id: Scalars["String"];
  provider: ReservationProviders;
  siteId?: Maybe<Scalars["String"]>;
  site?: Maybe<Site>;
  beginAt: Scalars["String"];
  endAt?: Maybe<Scalars["String"]>;
  deadlineReservation?: Maybe<Scalars["String"]>;
  totalPlaces?: Maybe<Scalars["Int"]>;
  remainingPlaces: Scalars["Int"];
  price: Scalars["Int"];
};

export type SessionBySitesInput = {
  provider: ReservationProviders;
  minimumDate: Scalars["String"];
  maximumDate: Scalars["String"];
  siteIds: Array<Maybe<Scalars["String"]>>;
};

export type SessionInput = {
  radius: Scalars["Int"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
  minimumDate: Scalars["String"];
  maximumDate: Scalars["String"];
  provider: ReservationProviders;
  hasReducedMobility?: Maybe<Scalars["Boolean"]>;
};

export type SimpleCursorPaginatedResponse = {
  __typename?: "SimpleCursorPaginatedResponse";
  nextCursor?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type SimpleCursorPaginationInput = {
  cursor?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type Site = {
  __typename?: "Site";
  id: Scalars["String"];
  provider: ReservationProviders;
  name: Scalars["String"];
  accessibleForDisabled: Scalars["Boolean"];
  capacity: Scalars["Int"];
  openAt?: Maybe<Scalars["String"]>;
  zipCode: Scalars["String"];
  city: Scalars["String"];
  address: Scalars["String"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
};

export type Situation = {
  __typename?: "Situation";
  year: Scalars["Int"];
  version?: Maybe<Scalars["String"]>;
  school?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  diploma?: Maybe<Scalars["String"]>;
  specialities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  domain?: Maybe<Scalars["String"]>;
  sectors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  externalCandidate?: Maybe<Scalars["Boolean"]>;
};

export type SituationConstraints = {
  __typename?: "SituationConstraints";
  version: Scalars["String"];
  statuses: Array<Maybe<LilsisStatus>>;
  domains: Array<Maybe<Domain>>;
};

export type SituationConstraintsV2 = {
  __typename?: "SituationConstraintsV2";
  version: Scalars["String"];
  statuses: Array<LilsisStatusV2>;
  domains: Array<DomainV2>;
};

export type SituationInput = {
  year: Scalars["Int"];
  version?: Maybe<Scalars["String"]>;
  school?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  diploma?: Maybe<Scalars["String"]>;
  specialities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  domain?: Maybe<Scalars["String"]>;
  sectors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  externalCandidate?: Maybe<Scalars["Boolean"]>;
};

export enum Sort {
  Asc = "asc",
  Desc = "desc"
}

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC"
}

export type SortRule = {
  field: Scalars["String"];
  /** default: ASC */
  order?: Maybe<SortOrder>;
};

export type Speciality = {
  __typename?: "Speciality";
  slug: Scalars["String"];
  label: Scalars["String"];
};

export enum Status {
  Draft = "DRAFT",
  Pending = "PENDING",
  Validated = "VALIDATED",
  Rejected = "REJECTED",
  Published = "PUBLISHED",
  Obsolete = "OBSOLETE",
  Archived = "ARCHIVED"
}

export type Stripe = {
  __typename?: "Stripe";
  productId: Scalars["String"];
  priceId: Scalars["String"];
};

/** Renamed "Tenant" in backend */
export enum StripeAccount {
  Digischool = "digischool",
  Feuvert = "feuvert"
}

export type StripeAddress = {
  __typename?: "StripeAddress";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  postal_code?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type StripeBillingDetails = {
  __typename?: "StripeBillingDetails";
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  address?: Maybe<StripeAddress>;
};

export type StripeCard = {
  __typename?: "StripeCard";
  brand?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  exp_month?: Maybe<Scalars["Int"]>;
  exp_year?: Maybe<Scalars["Int"]>;
  fingerprint?: Maybe<Scalars["String"]>;
  funding?: Maybe<Scalars["String"]>;
  generated_from?: Maybe<Scalars["String"]>;
  last4?: Maybe<Scalars["String"]>;
  wallet?: Maybe<Scalars["String"]>;
  three_d_secure_usage?: Maybe<StripeThreeDSecureUsage>;
  networks?: Maybe<StripeNetworks>;
  checks?: Maybe<StripeChecks>;
};

export type StripeChecks = {
  __typename?: "StripeChecks";
  address_line1_check?: Maybe<Scalars["String"]>;
  address_postal_code_check?: Maybe<Scalars["String"]>;
  cvc_check?: Maybe<Scalars["String"]>;
};

export type StripeInput = {
  productId: Scalars["String"];
  priceId: Scalars["String"];
};

export type StripeNetworks = {
  __typename?: "StripeNetworks";
  preferred?: Maybe<Scalars["String"]>;
  available?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type StripePaymentMethod = {
  __typename?: "StripePaymentMethod";
  id: Scalars["String"];
  object?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Int"]>;
  customer?: Maybe<Scalars["String"]>;
  livemode?: Maybe<Scalars["Boolean"]>;
  type?: Maybe<Scalars["String"]>;
  card?: Maybe<StripeCard>;
  billing_details?: Maybe<StripeBillingDetails>;
};

export type StripePaymentMethodInput = {
  paymentMethodId: Scalars["String"];
};

export type StripeSetupIntent = {
  __typename?: "StripeSetupIntent";
  id?: Maybe<Scalars["String"]>;
  client_secret?: Maybe<Scalars["String"]>;
  customer?: Maybe<Scalars["String"]>;
};

export type StripeSubscription = {
  __typename?: "StripeSubscription";
  id?: Maybe<Scalars["String"]>;
  object?: Maybe<Scalars["String"]>;
  cancel_at?: Maybe<Scalars["Int"]>;
  cancel_at_period_end?: Maybe<Scalars["Boolean"]>;
  current_period_start?: Maybe<Scalars["Int"]>;
  current_period_end?: Maybe<Scalars["Int"]>;
  created?: Maybe<Scalars["Int"]>;
  customer?: Maybe<Scalars["String"]>;
  start_date?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  items?: Maybe<StripeSubscriptionItem>;
};

export type StripeSubscriptionItem = {
  __typename?: "StripeSubscriptionItem";
  data?: Maybe<Array<Maybe<StripeSubscriptionItemData>>>;
};

export type StripeSubscriptionItemData = {
  __typename?: "StripeSubscriptionItemData";
  id?: Maybe<Scalars["String"]>;
  object?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Int"]>;
  price?: Maybe<StripeSubscriptionItemDataPrice>;
  subscription?: Maybe<Scalars["String"]>;
};

export type StripeSubscriptionItemDataPrice = {
  __typename?: "StripeSubscriptionItemDataPrice";
  id?: Maybe<Scalars["String"]>;
  object?: Maybe<Scalars["String"]>;
  created?: Maybe<Scalars["Int"]>;
  currency?: Maybe<Scalars["String"]>;
  product?: Maybe<Scalars["String"]>;
  metadata?: Maybe<StripeSubscriptionItemDataPriceMetadata>;
};

export type StripeSubscriptionItemDataPriceMetadata = {
  __typename?: "StripeSubscriptionItemDataPriceMetadata";
  activation_code?: Maybe<Scalars["String"]>;
};

export type StripeSubscriptionPrepaidGoodSource = PrepaidGoodSource & {
  __typename?: "StripeSubscriptionPrepaidGoodSource";
  type: PrepaidGoodSourceType;
  stripeSubscriptionId: Scalars["String"];
};

export type StripeThreeDSecureUsage = {
  __typename?: "StripeThreeDSecureUsage";
  supported?: Maybe<Scalars["Boolean"]>;
};

export type SubscriptionData = {
  __typename?: "SubscriptionData";
  _id: Scalars["ID"];
  ssoId: Scalars["String"];
  provider: SubscriptionProviderType;
  providerSubscriptionId: Scalars["String"];
  priceId: Scalars["String"];
  price: PriceUnion;
  /** When user canceled his subscription */
  canceledAt?: Maybe<Scalars["String"]>;
  /** When roles should be removed */
  endAt?: Maybe<Scalars["String"]>;
  tenant: TenantType;
  /** When roles were removed */
  endedAt?: Maybe<Scalars["String"]>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type SubscriptionListOutput = {
  __typename?: "SubscriptionListOutput";
  data: Array<SubscriptionData>;
  pagination: SimpleCursorPaginatedResponse;
};

export enum SubscriptionProviderType {
  Stripe = "STRIPE",
  Paypal = "PAYPAL"
}

export enum TenantType {
  Digischool = "digischool",
  Feuvert = "feuvert"
}

export type Time = {
  __typename?: "Time";
  _id?: Maybe<Scalars["String"]>;
  userID: Scalars["String"];
  learningCategorie?: Maybe<Array<Maybe<Scalars["String"]>>>;
  learningProduct: Array<Maybe<Scalars["String"]>>;
  learningType?: Maybe<Array<Maybe<LearningType>>>;
  learningID?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  duration: Scalars["Int"];
  confirmedDuration?: Maybe<Scalars["Int"]>;
  childrens?: Maybe<Array<Maybe<Time>>>;
  startTimestamp?: Maybe<Scalars["Int"]>;
  endTimestamp?: Maybe<Scalars["Int"]>;
};

export type TimeInput = {
  userID?: Maybe<Scalars["String"]>;
  timescale?: Maybe<Timescale>;
  fromTimestamp?: Maybe<Scalars["Int"]>;
  toTimestamp?: Maybe<Scalars["Int"]>;
  learningCategorie?: Maybe<Scalars["String"]>;
  learningProduct?: Maybe<Scalars["String"]>;
  learningType?: Maybe<LearningType>;
  learningID?: Maybe<Scalars["Int"]>;
};

export enum Timescale {
  Today = "TODAY",
  ThisWeek = "THIS_WEEK",
  ThisMonth = "THIS_MONTH"
}

export type ToeicPromoCode = {
  __typename?: "ToeicPromoCode";
  _id: Scalars["String"];
  code: Scalars["String"];
  userSsoId?: Maybe<Scalars["String"]>;
  user?: Maybe<User>;
  transaction?: Maybe<ToeicPromoCodeTransaction>;
  cartId?: Maybe<Scalars["String"]>;
  lock?: Maybe<ToeicPromoCodeLock>;
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
  deletedAt?: Maybe<Scalars["String"]>;
};

export type ToeicPromoCodeLock = {
  __typename?: "ToeicPromoCodeLock";
  userSsoId: Scalars["String"];
  until: Scalars["String"];
};

export type ToeicPromoCodeTransaction = {
  __typename?: "ToeicPromoCodeTransaction";
  id: Scalars["String"];
  type: Scalars["String"];
};

export type ToeicPromoCodesAdminImportInput = {
  codes: Array<Scalars["String"]>;
};

export type ToeicPromoCodesAdminImportOutput = {
  __typename?: "ToeicPromoCodesAdminImportOutput";
  insertedCount: Scalars["Int"];
};

export type ToeicPromoCodesAdminSearchInput = {
  /** Retrieve only the active/deleted codes */
  isActive?: Maybe<Scalars["Boolean"]>;
  /** Retrieve only the assigned/available codes */
  isAssigned?: Maybe<Scalars["Boolean"]>;
  /** Retrieve only the locked/free codes */
  isLocked?: Maybe<Scalars["Boolean"]>;
};

export type ToeicPromoCodesLockOutput = {
  __typename?: "ToeicPromoCodesLockOutput";
  lockedUntil: Scalars["String"];
};

export type TrainingPapersFromCategoryV2SearchInput = {
  isOfficial?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Status>;
};

export type TrainingPapersSearchInput = {
  slugs?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Status>;
  isOfficial?: Maybe<Scalars["Boolean"]>;
};

export type User = {
  __typename?: "User";
  id: Scalars["String"];
  userName: Scalars["String"];
  email: Scalars["String"];
  emailValid: Scalars["Boolean"];
  emailBounced: Scalars["Boolean"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  avatar?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  parentalConsent?: Maybe<Scalars["Boolean"]>;
  gender?: Maybe<Gender>;
  locale?: Maybe<Scalars["String"]>;
  address?: Maybe<Address>;
  billingAddress?: Maybe<BillingAddress>;
  phone?: Maybe<Scalars["String"]>;
  situation?: Maybe<Situation>;
  newsletters: Newsletters;
  connectedAt?: Maybe<Scalars["String"]>;
  tagsIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tags?: Maybe<Array<Maybe<UserTag>>>;
  createdAt?: Maybe<Scalars["String"]>;
  neph?: Maybe<Scalars["String"]>;
  services?: Maybe<Services>;
  roles?: Maybe<Roles>;
  elapsedTime?: Maybe<Time>;
  /** Returns the quizzesProgress for the currently logged in user (does not work when giving a userId, return for the logged user either way) */
  quizzesProgress?: Maybe<Array<Maybe<QuizProgress>>>;
  /** Returns the lessonsProgress for the currently logged in user (does not work when giving a userId, return for the logged user either way) */
  lessonsProgress?: Maybe<Array<Maybe<LessonProgress>>>;
  /** Returns the reservations for the currently logged in user (does not work when giving a userId, return for the logged user either way) */
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  /**
   * **DEPRECATED:** use `subscriptionsV2` query instead
   * Returns stripe's subscriptions for the currently logged in user (does not work when giving a userId, return for the logged user either way)
   * @deprecated on stripe subscriptions is supported, use `subscriptionsV2` instead
   */
  subscriptions?: Maybe<Array<Maybe<StripeSubscription>>>;
  /** Returns subscriptions for the currently logged in user. */
  subscriptionsV2: SubscriptionListOutput;
  /** Returns the user activated promotionnal codes */
  activationCodes?: Maybe<PaginatedCodesOutput>;
  /** Returns current user statistics */
  quizzesProgressStatistics?: Maybe<UserQuizStatistics>;
};

export type UserRolesArgs = {
  client?: Maybe<Scalars["String"]>;
};

export type UserElapsedTimeArgs = {
  client?: Maybe<Scalars["String"]>;
  learningType?: Maybe<LearningType>;
  timescale?: Maybe<Timescale>;
};

export type UserQuizzesProgressArgs = {
  section?: Maybe<Scalars["String"]>;
  product?: Maybe<BookmarkProduct>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type UserLessonsProgressArgs = {
  product?: Maybe<BookmarkProduct>;
  page?: Maybe<Scalars["Int"]>;
  per_page?: Maybe<Scalars["Int"]>;
};

export type UserReservationsArgs = {
  input?: Maybe<UserReservationInput>;
};

export type UserSubscriptionsArgs = {
  stripeAccount?: Maybe<StripeAccount>;
};

export type UserSubscriptionsV2Args = {
  pagination?: Maybe<SimpleCursorPaginationInput>;
};

export type UserActivationCodesArgs = {
  offset?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  stripeAccount?: Maybe<StripeAccount>;
  activatedBy?: Maybe<Scalars["String"]>;
};

export type UserQuizzesProgressStatisticsArgs = {
  gutenberg_uuid: Scalars["String"];
  type?: Maybe<BookmarkType>;
};

export type UserPatchInput = {
  userName?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  birthday?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  address?: Maybe<AddressInput>;
  situation?: Maybe<SituationInput>;
  newsletters?: Maybe<NewslettersInput>;
  billingAddress?: Maybe<BillingAddressInput>;
  services?: Maybe<ServicesPatchInput>;
  locale?: Maybe<Scalars["String"]>;
};

export type UserQuizStatistics = {
  __typename?: "UserQuizStatistics";
  total: Scalars["Int"];
  over_35: Scalars["Int"];
};

export type UserReservationInput = {
  status?: Maybe<Array<Maybe<ReservationStatus>>>;
  after?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
};

export type UserTag = {
  __typename?: "UserTag";
  _id: Scalars["String"];
  value: Scalars["String"];
  referentId: Scalars["String"];
  createdAt: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type ValidityTimeRange = {
  __typename?: "ValidityTimeRange";
  start: Scalars["String"];
  end: Scalars["String"];
};

export type VideoChapter = {
  __typename?: "VideoChapter";
  name: Scalars["String"];
  isCorrect: Scalars["Boolean"];
  answers: Array<Scalars["String"]>;
  start: Scalars["Int"];
  end: Scalars["Int"];
};

export enum VoucherStatus {
  Active = "active",
  Deleted = "deleted"
}

export type QuizProgressPostMutationVariables = Exact<{
  learningId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  type: BookmarkType;
  status: BookmarkStatus;
  score: Scalars["Int"];
  currentQuestionId?: Maybe<Scalars["Int"]>;
}>;

export type QuizProgressPostMutation = { __typename?: "Mutation" } & {
  quizProgressPost?: Maybe<
    { __typename?: "QuizProgress" } & Pick<
      QuizProgress,
      "learning_id" | "gutenberg_uuid" | "type"
    >
  >;
};

export type QuizDoneAndTimePostMutationVariables = Exact<{
  learningId?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["String"]>;
  type: BookmarkType;
  score: Scalars["Int"];
  userID: Scalars["String"];
  startTimestamp: Scalars["Int"];
  endTimestamp: Scalars["Int"];
  learningCategorie?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type QuizDoneAndTimePostMutation = { __typename?: "Mutation" } & {
  progress?: Maybe<
    { __typename?: "QuizProgress" } & Pick<
      QuizProgress,
      "learning_id" | "gutenberg_uuid" | "type"
    >
  >;
  time?: Maybe<{ __typename?: "Time" } & Pick<Time, "duration">>;
};

export type ContactPostMutationVariables = Exact<{
  service: ReportService;
  email: Scalars["String"];
  subType: Scalars["String"];
  message: Scalars["String"];
}>;

export type ContactPostMutation = { __typename?: "Mutation" } & {
  contactPost?: Maybe<{ __typename?: "Contact" } & Pick<Contact, "message">>;
};

export type CategoryBaseFieldsFragment = { __typename?: "Category" } & Pick<
  Category,
  | "_id"
  | "learningId"
  | "title"
  | "slug"
  | "path"
  | "status"
  | "description"
  | "tags"
> & {
    platformAvailability: {
      __typename?: "CategoryPlatformAvailability";
    } & Pick<CategoryPlatformAvailability, "web" | "mobile">;
  };

export type QuizBaseFieldsFragment = { __typename?: "Quiz" } & Pick<
  Quiz,
  | "_id"
  | "title"
  | "slug"
  | "slugV2"
  | "estimatedTime"
  | "learningId"
  | "tags"
  | "status"
  | "createdAt"
  | "updatedAt"
  | "maximalScore"
  | "startScore"
  | "successScore"
  | "questionCount"
>;

export type LessonBaseFieldsFragment = { __typename?: "Lesson" } & Pick<
  Lesson,
  | "_id"
  | "title"
  | "slug"
  | "slugV2"
  | "estimatedTime"
  | "learningId"
  | "html"
  | "tags"
  | "status"
  | "createdAt"
  | "updatedAt"
> & {
    relatedActivities: { __typename?: "RelatedActivities" } & Pick<
      RelatedActivities,
      "_id"
    >;
  };

export type FlashcardBaseFieldsFragment = { __typename?: "Flashcard" } & Pick<
  Flashcard,
  "_id" | "title" | "status" | "accessType" | "createdAt" | "updatedAt"
>;

export type PastPaperBaseFieldsFragment = { __typename?: "PastPaper" } & Pick<
  PastPaper,
  "_id" | "title" | "slug" | "accessType"
>;

export type TimeBaseFieldsFragment = { __typename?: "Time" } & Pick<
  Time,
  "userID" | "learningProduct" | "duration" | "confirmedDuration"
>;

export type BookmarkBaseFieldsFragment = { __typename?: "QuizProgress" } & Pick<
  QuizProgress,
  | "id"
  | "type"
  | "status"
  | "current_question_id"
  | "score"
  | "created_at"
  | "updated_at"
>;

export type BaseSkillQueryVariables = Exact<{
  idOrPath: Scalars["String"];
}>;

export type BaseSkillQuery = { __typename?: "Query" } & {
  category?: Maybe<
    { __typename?: "Category" } & {
      childrensData?: Maybe<
        Array<
          Maybe<
            { __typename?: "Category" } & {
              childrensData?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Category" } & {
                      childrensData?: Maybe<
                        Array<
                          Maybe<
                            { __typename?: "Category" } & {
                              quizzes?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: "Quiz" } & {
                                      elapsedTime?: Maybe<
                                        {
                                          __typename?: "Time";
                                        } & TimeBaseFieldsFragment
                                      >;
                                    } & QuizBaseFieldsFragment
                                  >
                                >
                              >;
                              lessons?: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: "Lesson" } & {
                                      elapsedTime?: Maybe<
                                        {
                                          __typename?: "Time";
                                        } & TimeBaseFieldsFragment
                                      >;
                                    } & LessonBaseFieldsFragment
                                  >
                                >
                              >;
                            } & CategoryBaseFieldsFragment
                          >
                        >
                      >;
                    } & CategoryBaseFieldsFragment
                  >
                >
              >;
            } & CategoryBaseFieldsFragment
          >
        >
      >;
    } & CategoryBaseFieldsFragment
  >;
};

export type StarterQuizListQueryVariables = Exact<{ [key: string]: never }>;

export type StarterQuizListQuery = { __typename?: "Query" } & {
  category?: Maybe<
    { __typename?: "Category" } & {
      childrensData?: Maybe<
        Array<
          Maybe<
            { __typename?: "Category" } & {
              quizzes?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Quiz" } & {
                      bookmark?: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: "QuizProgress";
                            } & BookmarkBaseFieldsFragment
                          >
                        >
                      >;
                    } & QuizBaseFieldsFragment
                  >
                >
              >;
            } & CategoryBaseFieldsFragment
          >
        >
      >;
    } & CategoryBaseFieldsFragment
  >;
};

export type NewsQueryVariables = Exact<{
  date: Scalars["String"];
}>;

export type NewsQuery = { __typename?: "Query" } & {
  folders?: Maybe<
    { __typename?: "PaginatedCategoriesOutput" } & {
      data?: Maybe<
        Array<
          { __typename?: "Category" } & {
            assetLinks?: Maybe<
              Array<
                Maybe<
                  { __typename?: "AssetLink" } & Pick<AssetLink, "type"> & {
                      media?: Maybe<
                        { __typename?: "Media" } & Pick<
                          Media,
                          "okulusId" | "url"
                        >
                      >;
                    }
                >
              >
            >;
            quizzes?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Quiz" } & {
                    bookmark?: Maybe<
                      Array<
                        Maybe<
                          {
                            __typename?: "QuizProgress";
                          } & BookmarkBaseFieldsFragment
                        >
                      >
                    >;
                    elapsedTime?: Maybe<
                      { __typename?: "Time" } & TimeBaseFieldsFragment
                    >;
                  } & QuizBaseFieldsFragment
                >
              >
            >;
            lessons?: Maybe<
              Array<
                Maybe<
                  { __typename?: "Lesson" } & {
                    elapsedTime?: Maybe<
                      { __typename?: "Time" } & TimeBaseFieldsFragment
                    >;
                  } & LessonBaseFieldsFragment
                >
              >
            >;
          } & CategoryBaseFieldsFragment
        >
      >;
    }
  >;
  newQuizzes?: Maybe<
    { __typename?: "PaginatedQuizzesOutput" } & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: "Quiz" } & {
              elapsedTime?: Maybe<
                { __typename?: "Time" } & TimeBaseFieldsFragment
              >;
              bookmark?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "QuizProgress" } & BookmarkBaseFieldsFragment
                  >
                >
              >;
              parentCategories?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Category" } & {
                      parent?: Maybe<
                        { __typename?: "Category" } & {
                          parent?: Maybe<
                            {
                              __typename?: "Category";
                            } & CategoryBaseFieldsFragment
                          >;
                        } & CategoryBaseFieldsFragment
                      >;
                    } & CategoryBaseFieldsFragment
                  >
                >
              >;
            } & QuizBaseFieldsFragment
          >
        >
      >;
    }
  >;
  updatedQuizzes?: Maybe<
    { __typename?: "PaginatedQuizzesOutput" } & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: "Quiz" } & {
              bookmark?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "QuizProgress" } & BookmarkBaseFieldsFragment
                  >
                >
              >;
              elapsedTime?: Maybe<
                { __typename?: "Time" } & TimeBaseFieldsFragment
              >;
              parentCategories?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Category" } & {
                      parent?: Maybe<
                        { __typename?: "Category" } & {
                          parent?: Maybe<
                            {
                              __typename?: "Category";
                            } & CategoryBaseFieldsFragment
                          >;
                        } & CategoryBaseFieldsFragment
                      >;
                    } & CategoryBaseFieldsFragment
                  >
                >
              >;
            } & QuizBaseFieldsFragment
          >
        >
      >;
    }
  >;
  newLessons?: Maybe<
    { __typename?: "PaginatedLessonsOutput" } & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: "Lesson" } & {
              elapsedTime?: Maybe<
                { __typename?: "Time" } & TimeBaseFieldsFragment
              >;
              parentCategories?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Category" } & {
                      parent?: Maybe<
                        { __typename?: "Category" } & {
                          parent?: Maybe<
                            {
                              __typename?: "Category";
                            } & CategoryBaseFieldsFragment
                          >;
                        } & CategoryBaseFieldsFragment
                      >;
                    } & CategoryBaseFieldsFragment
                  >
                >
              >;
            } & LessonBaseFieldsFragment
          >
        >
      >;
    }
  >;
  updatedLessons?: Maybe<
    { __typename?: "PaginatedLessonsOutput" } & {
      data?: Maybe<
        Array<
          Maybe<
            { __typename?: "Lesson" } & {
              elapsedTime?: Maybe<
                { __typename?: "Time" } & TimeBaseFieldsFragment
              >;
              parentCategories?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Category" } & {
                      parent?: Maybe<
                        { __typename?: "Category" } & {
                          parent?: Maybe<
                            {
                              __typename?: "Category";
                            } & CategoryBaseFieldsFragment
                          >;
                        } & CategoryBaseFieldsFragment
                      >;
                    } & CategoryBaseFieldsFragment
                  >
                >
              >;
            } & LessonBaseFieldsFragment
          >
        >
      >;
    }
  >;
};

export type CategorySubjectQueryVariables = Exact<{
  idOrPath: Scalars["String"];
}>;

export type CategorySubjectQuery = { __typename?: "Query" } & {
  category?: Maybe<
    { __typename?: "Category" } & {
      quizzes?: Maybe<
        Array<
          Maybe<
            { __typename?: "Quiz" } & {
              exerciseListData?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "Exercise" } & Pick<
                      Exercise,
                      | "_id"
                      | "learningId"
                      | "difficulty"
                      | "displayType"
                      | "audioText"
                      | "maxDurationSeconds"
                      | "containsLatex"
                    > & {
                        questionsData?: Maybe<
                          Array<
                            Maybe<
                              { __typename?: "Question" } & Pick<
                                Question,
                                "_id" | "text"
                              > & {
                                  explanation: {
                                    __typename?: "Explanation";
                                  } & Pick<
                                    Explanation,
                                    "text" | "audioText" | "assetLinks"
                                  >;
                                  possibleAnswers?: Maybe<
                                    Array<
                                      Maybe<
                                        { __typename?: "Answer" } & Pick<
                                          Answer,
                                          | "_id"
                                          | "learningId"
                                          | "audioText"
                                          | "text"
                                          | "isCorrect"
                                        >
                                      >
                                    >
                                  >;
                                }
                            >
                          >
                        >;
                      }
                  >
                >
              >;
              bookmark?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "QuizProgress" } & BookmarkBaseFieldsFragment
                  >
                >
              >;
              elapsedTime?: Maybe<
                { __typename?: "Time" } & TimeBaseFieldsFragment
              >;
            } & QuizBaseFieldsFragment
          >
        >
      >;
      lessons?: Maybe<
        Array<
          Maybe<
            { __typename?: "Lesson" } & {
              elapsedTime?: Maybe<
                { __typename?: "Time" } & TimeBaseFieldsFragment
              >;
            } & LessonBaseFieldsFragment
          >
        >
      >;
    } & CategoryBaseFieldsFragment
  >;
};

export type CategoryTitleQueryVariables = Exact<{
  idOrPath: Scalars["String"];
}>;

export type CategoryTitleQuery = { __typename?: "Query" } & {
  category?: Maybe<{ __typename?: "Category" } & CategoryBaseFieldsFragment>;
};

export type ActivityQueryVariables = Exact<{
  id: Scalars["String"];
  isQuiz: Scalars["Boolean"];
}>;

export type ActivityQuery = { __typename?: "Query" } & {
  quiz?: Maybe<
    { __typename?: "Quiz" } & {
      bookmark?: Maybe<
        Array<
          Maybe<{ __typename?: "QuizProgress" } & BookmarkBaseFieldsFragment>
        >
      >;
      parentCategories?: Maybe<
        Array<
          Maybe<
            { __typename?: "Category" } & {
              parent?: Maybe<
                { __typename?: "Category" } & {
                  parent?: Maybe<
                    { __typename?: "Category" } & CategoryBaseFieldsFragment
                  >;
                } & CategoryBaseFieldsFragment
              >;
            } & CategoryBaseFieldsFragment
          >
        >
      >;
      assetLinks?: Maybe<
        Array<
          Maybe<
            { __typename?: "AssetLink" } & Pick<AssetLink, "type"> & {
                media?: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    | "name"
                    | "mimeType"
                    | "provider"
                    | "url"
                    | "type"
                    | "contentType"
                    | "data"
                    | "okulusId"
                  >
                >;
              }
          >
        >
      >;
      exerciseListData?: Maybe<
        Array<
          Maybe<
            { __typename?: "Exercise" } & Pick<
              Exercise,
              | "_id"
              | "text"
              | "learningId"
              | "difficulty"
              | "displayType"
              | "audioText"
              | "maxDurationSeconds"
              | "containsLatex"
            > & {
                assetLinks?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "AssetLink" } & Pick<AssetLink, "type"> & {
                          media?: Maybe<
                            { __typename?: "Media" } & Pick<
                              Media,
                              | "name"
                              | "mimeType"
                              | "provider"
                              | "url"
                              | "type"
                              | "contentType"
                              | "data"
                              | "okulusId"
                            >
                          >;
                        }
                    >
                  >
                >;
                questionsData?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: "Question" } & Pick<
                        Question,
                        "learningId" | "type" | "_id" | "text" | "audioText"
                      > & {
                          score: { __typename?: "Score" } & Pick<
                            Score,
                            "success" | "failure"
                          >;
                          assetLinks?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "AssetLink" } & Pick<
                                  AssetLink,
                                  "type"
                                > & {
                                    media?: Maybe<
                                      { __typename?: "Media" } & Pick<
                                        Media,
                                        | "name"
                                        | "mimeType"
                                        | "provider"
                                        | "url"
                                        | "type"
                                        | "contentType"
                                        | "data"
                                        | "okulusId"
                                      >
                                    >;
                                  }
                              >
                            >
                          >;
                          explanation: { __typename?: "Explanation" } & Pick<
                            Explanation,
                            "text" | "audioText" | "assetLinks"
                          >;
                          possibleAnswers?: Maybe<
                            Array<
                              Maybe<
                                { __typename?: "Answer" } & Pick<
                                  Answer,
                                  | "_id"
                                  | "learningId"
                                  | "audioText"
                                  | "text"
                                  | "isCorrect"
                                >
                              >
                            >
                          >;
                        }
                    >
                  >
                >;
              }
          >
        >
      >;
    } & QuizBaseFieldsFragment
  >;
  lesson?: Maybe<
    { __typename?: "Lesson" } & {
      elapsedTime?: Maybe<{ __typename?: "Time" } & TimeBaseFieldsFragment>;
      parentCategories?: Maybe<
        Array<
          Maybe<
            { __typename?: "Category" } & {
              parent?: Maybe<
                { __typename?: "Category" } & {
                  parent?: Maybe<
                    { __typename?: "Category" } & CategoryBaseFieldsFragment
                  >;
                } & CategoryBaseFieldsFragment
              >;
            } & CategoryBaseFieldsFragment
          >
        >
      >;
      assetLinks?: Maybe<
        Array<
          Maybe<
            { __typename?: "AssetLink" } & Pick<AssetLink, "type"> & {
                media?: Maybe<
                  { __typename?: "Media" } & Pick<
                    Media,
                    "mimeType" | "okulusId" | "url"
                  >
                >;
              }
          >
        >
      >;
      relatedActivities: { __typename?: "RelatedActivities" } & Pick<
        RelatedActivities,
        "_id"
      >;
    } & LessonBaseFieldsFragment
  >;
};

export type TimePerDomainQueryVariables = Exact<{
  userId?: Maybe<Scalars["String"]>;
  startTimestamp?: Maybe<Scalars["Int"]>;
  endTimestamp?: Maybe<Scalars["Int"]>;
}>;

export type TimePerDomainQuery = { __typename?: "Query" } & {
  DOMAIN_ACCOUNTING?: Maybe<
    { __typename?: "Time" } & Pick<Time, "confirmedDuration">
  >;
  DOMAIN_TAX?: Maybe<{ __typename?: "Time" } & Pick<Time, "confirmedDuration">>;
  DOMAIN_SOCIAL?: Maybe<
    { __typename?: "Time" } & Pick<Time, "confirmedDuration">
  >;
  DOMAIN_PAYROLL?: Maybe<
    { __typename?: "Time" } & Pick<Time, "confirmedDuration">
  >;
  DOMAIN_BUSINESS?: Maybe<
    { __typename?: "Time" } & Pick<Time, "confirmedDuration">
  >;
  DOMAIN_HERITAGE?: Maybe<
    { __typename?: "Time" } & Pick<Time, "confirmedDuration">
  >;
  DOMAIN_OFFICE?: Maybe<
    { __typename?: "Time" } & Pick<Time, "confirmedDuration">
  >;
};

export const CategoryBaseFieldsFragmentDoc = gql`
  fragment CategoryBaseFields on Category {
    _id
    learningId
    title
    slug
    path
    status
    description
    tags
    platformAvailability {
      web
      mobile
    }
  }
`;
export const QuizBaseFieldsFragmentDoc = gql`
  fragment QuizBaseFields on Quiz {
    _id
    title
    slug
    slugV2
    estimatedTime
    learningId
    tags
    status
    createdAt
    updatedAt
    maximalScore
    startScore
    successScore
    questionCount
  }
`;
export const LessonBaseFieldsFragmentDoc = gql`
  fragment LessonBaseFields on Lesson {
    _id
    title
    slug
    slugV2
    estimatedTime
    learningId
    html
    tags
    relatedActivities {
      _id
    }
    status
    createdAt
    updatedAt
  }
`;
export const FlashcardBaseFieldsFragmentDoc = gql`
  fragment FlashcardBaseFields on Flashcard {
    _id
    title
    status
    accessType
    createdAt
    updatedAt
  }
`;
export const PastPaperBaseFieldsFragmentDoc = gql`
  fragment PastPaperBaseFields on PastPaper {
    _id
    title
    slug
    accessType
  }
`;
export const TimeBaseFieldsFragmentDoc = gql`
  fragment TimeBaseFields on Time {
    userID
    learningProduct
    duration
    confirmedDuration
  }
`;
export const BookmarkBaseFieldsFragmentDoc = gql`
  fragment BookmarkBaseFields on QuizProgress {
    id
    type
    status
    current_question_id
    score
    created_at
    updated_at
  }
`;
export const QuizProgressPostDocument = gql`
  mutation quizProgressPost(
    $learningId: Int
    $id: String
    $type: BookmarkType!
    $status: BookmarkStatus!
    $score: Int!
    $currentQuestionId: Int
  ) {
    quizProgressPost(
      data: {
        learning_id: $learningId
        gutenberg_uuid: $id
        type: $type
        status: $status
        product: rfformation
        current_question_id: $currentQuestionId
        score: $score
      }
    ) {
      learning_id
      gutenberg_uuid
      type
    }
  }
`;
export type QuizProgressPostMutationFn = Apollo.MutationFunction<
  QuizProgressPostMutation,
  QuizProgressPostMutationVariables
>;

/**
 * __useQuizProgressPostMutation__
 *
 * To run a mutation, you first call `useQuizProgressPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuizProgressPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizProgressPostMutation, { data, loading, error }] = useQuizProgressPostMutation({
 *   variables: {
 *      learningId: // value for 'learningId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      score: // value for 'score'
 *      currentQuestionId: // value for 'currentQuestionId'
 *   },
 * });
 */
export function useQuizProgressPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuizProgressPostMutation,
    QuizProgressPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuizProgressPostMutation,
    QuizProgressPostMutationVariables
  >(QuizProgressPostDocument, options);
}
export type QuizProgressPostMutationHookResult = ReturnType<
  typeof useQuizProgressPostMutation
>;
export type QuizProgressPostMutationResult = Apollo.MutationResult<
  QuizProgressPostMutation
>;
export type QuizProgressPostMutationOptions = Apollo.BaseMutationOptions<
  QuizProgressPostMutation,
  QuizProgressPostMutationVariables
>;
export const QuizDoneAndTimePostDocument = gql`
  mutation quizDoneAndTimePost(
    $learningId: Int
    $id: String
    $type: BookmarkType!
    $score: Int!
    $userID: String!
    $startTimestamp: Int!
    $endTimestamp: Int!
    $learningCategorie: String
    $limit: Int
  ) {
    progress: quizProgressPost(
      data: {
        learning_id: $learningId
        gutenberg_uuid: $id
        type: $type
        status: done
        product: rfformation
        score: $score
      }
    ) {
      learning_id
      gutenberg_uuid
      type
    }
    time: timePost(
      userID: $userID
      learningProduct: "RF_FORMATION"
      startTimestamp: $startTimestamp
      endTimestamp: $endTimestamp
      learningCategorie: $learningCategorie
      learningType: Quiz
      learningID: $learningId
      limit: $limit
    ) {
      duration
    }
  }
`;
export type QuizDoneAndTimePostMutationFn = Apollo.MutationFunction<
  QuizDoneAndTimePostMutation,
  QuizDoneAndTimePostMutationVariables
>;

/**
 * __useQuizDoneAndTimePostMutation__
 *
 * To run a mutation, you first call `useQuizDoneAndTimePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuizDoneAndTimePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quizDoneAndTimePostMutation, { data, loading, error }] = useQuizDoneAndTimePostMutation({
 *   variables: {
 *      learningId: // value for 'learningId'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *      score: // value for 'score'
 *      userID: // value for 'userID'
 *      startTimestamp: // value for 'startTimestamp'
 *      endTimestamp: // value for 'endTimestamp'
 *      learningCategorie: // value for 'learningCategorie'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useQuizDoneAndTimePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    QuizDoneAndTimePostMutation,
    QuizDoneAndTimePostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    QuizDoneAndTimePostMutation,
    QuizDoneAndTimePostMutationVariables
  >(QuizDoneAndTimePostDocument, options);
}
export type QuizDoneAndTimePostMutationHookResult = ReturnType<
  typeof useQuizDoneAndTimePostMutation
>;
export type QuizDoneAndTimePostMutationResult = Apollo.MutationResult<
  QuizDoneAndTimePostMutation
>;
export type QuizDoneAndTimePostMutationOptions = Apollo.BaseMutationOptions<
  QuizDoneAndTimePostMutation,
  QuizDoneAndTimePostMutationVariables
>;
export const ContactPostDocument = gql`
  mutation contactPost(
    $service: ReportService!
    $email: String!
    $subType: String!
    $message: String!
  ) {
    contactPost(
      input: {
        tags: [$service]
        service: $service
        email: $email
        platform: web
        type: "Contact client RF Learning"
        subType: $subType
        message: $message
        attachments: []
      }
    ) {
      message
    }
  }
`;
export type ContactPostMutationFn = Apollo.MutationFunction<
  ContactPostMutation,
  ContactPostMutationVariables
>;

/**
 * __useContactPostMutation__
 *
 * To run a mutation, you first call `useContactPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactPostMutation, { data, loading, error }] = useContactPostMutation({
 *   variables: {
 *      service: // value for 'service'
 *      email: // value for 'email'
 *      subType: // value for 'subType'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactPostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ContactPostMutation,
    ContactPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ContactPostMutation, ContactPostMutationVariables>(
    ContactPostDocument,
    options
  );
}
export type ContactPostMutationHookResult = ReturnType<
  typeof useContactPostMutation
>;
export type ContactPostMutationResult = Apollo.MutationResult<
  ContactPostMutation
>;
export type ContactPostMutationOptions = Apollo.BaseMutationOptions<
  ContactPostMutation,
  ContactPostMutationVariables
>;
export const BaseSkillDocument = gql`
  query baseSkill($idOrPath: String!) {
    category(idOrPath: $idOrPath) {
      ...CategoryBaseFields
      childrensData {
        ...CategoryBaseFields
        childrensData {
          ...CategoryBaseFields
          childrensData {
            ...CategoryBaseFields
            quizzes {
              ...QuizBaseFields
              elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
                ...TimeBaseFields
              }
            }
            lessons {
              ...LessonBaseFields
              elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
                ...TimeBaseFields
              }
            }
          }
        }
      }
    }
  }
  ${CategoryBaseFieldsFragmentDoc}
  ${QuizBaseFieldsFragmentDoc}
  ${TimeBaseFieldsFragmentDoc}
  ${LessonBaseFieldsFragmentDoc}
`;

/**
 * __useBaseSkillQuery__
 *
 * To run a query within a React component, call `useBaseSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseSkillQuery({
 *   variables: {
 *      idOrPath: // value for 'idOrPath'
 *   },
 * });
 */
export function useBaseSkillQuery(
  baseOptions: Apollo.QueryHookOptions<BaseSkillQuery, BaseSkillQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BaseSkillQuery, BaseSkillQueryVariables>(
    BaseSkillDocument,
    options
  );
}
export function useBaseSkillLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BaseSkillQuery,
    BaseSkillQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BaseSkillQuery, BaseSkillQueryVariables>(
    BaseSkillDocument,
    options
  );
}
export type BaseSkillQueryHookResult = ReturnType<typeof useBaseSkillQuery>;
export type BaseSkillLazyQueryHookResult = ReturnType<
  typeof useBaseSkillLazyQuery
>;
export type BaseSkillQueryResult = Apollo.QueryResult<
  BaseSkillQuery,
  BaseSkillQueryVariables
>;
export const StarterQuizListDocument = gql`
  query starterQuizList {
    category(idOrPath: ",grf,rfformation") {
      ...CategoryBaseFields
      childrensData {
        ...CategoryBaseFields
        quizzes {
          ...QuizBaseFields
          bookmark {
            ...BookmarkBaseFields
          }
        }
      }
    }
  }
  ${CategoryBaseFieldsFragmentDoc}
  ${QuizBaseFieldsFragmentDoc}
  ${BookmarkBaseFieldsFragmentDoc}
`;

/**
 * __useStarterQuizListQuery__
 *
 * To run a query within a React component, call `useStarterQuizListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStarterQuizListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStarterQuizListQuery({
 *   variables: {
 *   },
 * });
 */
export function useStarterQuizListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StarterQuizListQuery,
    StarterQuizListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StarterQuizListQuery, StarterQuizListQueryVariables>(
    StarterQuizListDocument,
    options
  );
}
export function useStarterQuizListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StarterQuizListQuery,
    StarterQuizListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    StarterQuizListQuery,
    StarterQuizListQueryVariables
  >(StarterQuizListDocument, options);
}
export type StarterQuizListQueryHookResult = ReturnType<
  typeof useStarterQuizListQuery
>;
export type StarterQuizListLazyQueryHookResult = ReturnType<
  typeof useStarterQuizListLazyQuery
>;
export type StarterQuizListQueryResult = Apollo.QueryResult<
  StarterQuizListQuery,
  StarterQuizListQueryVariables
>;
export const NewsDocument = gql`
  query news($date: String!) {
    folders: categoriesV2(
      search: { tags: ["DOSSIER_D_ACTUALITE"] }
      pagination: { per_page: -1 }
    ) {
      data {
        ...CategoryBaseFields
        assetLinks {
          type
          media {
            okulusId
            url
          }
        }
        quizzes {
          ...QuizBaseFields
          bookmark {
            ...BookmarkBaseFields
          }
          elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
            ...TimeBaseFields
          }
        }
        lessons {
          ...LessonBaseFields
          elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
            ...TimeBaseFields
          }
        }
      }
    }
    newQuizzes: quizzesV2(
      search: { product: RF_FORMATION, createdAfter: $date }
      pagination: { per_page: -1 }
    ) {
      data {
        ...QuizBaseFields
        elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
          ...TimeBaseFields
        }
        bookmark {
          ...BookmarkBaseFields
        }
        parentCategories {
          ...CategoryBaseFields
          parent {
            ...CategoryBaseFields
            parent {
              ...CategoryBaseFields
            }
          }
        }
      }
    }
    updatedQuizzes: quizzesV2(
      search: { product: RF_FORMATION, tags: ["A_LA_UNE"], updatedAfter: $date }
      pagination: { per_page: -1 }
    ) {
      data {
        ...QuizBaseFields
        bookmark {
          ...BookmarkBaseFields
        }
        elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
          ...TimeBaseFields
        }
        parentCategories {
          ...CategoryBaseFields
          parent {
            ...CategoryBaseFields
            parent {
              ...CategoryBaseFields
            }
          }
        }
      }
    }
    newLessons: lessonsV2(
      search: { product: RF_FORMATION, createdAfter: $date }
      pagination: { per_page: -1 }
    ) {
      data {
        ...LessonBaseFields
        elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
          ...TimeBaseFields
        }
        parentCategories {
          ...CategoryBaseFields
          parent {
            ...CategoryBaseFields
            parent {
              ...CategoryBaseFields
            }
          }
        }
      }
    }
    updatedLessons: lessonsV2(
      search: { product: RF_FORMATION, tags: ["A_LA_UNE"], updatedAfter: $date }
      pagination: { per_page: -1 }
    ) {
      data {
        ...LessonBaseFields
        elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
          ...TimeBaseFields
        }
        parentCategories {
          ...CategoryBaseFields
          parent {
            ...CategoryBaseFields
            parent {
              ...CategoryBaseFields
            }
          }
        }
      }
    }
  }
  ${CategoryBaseFieldsFragmentDoc}
  ${QuizBaseFieldsFragmentDoc}
  ${BookmarkBaseFieldsFragmentDoc}
  ${TimeBaseFieldsFragmentDoc}
  ${LessonBaseFieldsFragmentDoc}
`;

/**
 * __useNewsQuery__
 *
 * To run a query within a React component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useNewsQuery(
  baseOptions: Apollo.QueryHookOptions<NewsQuery, NewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
}
export function useNewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewsQuery, NewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewsQuery, NewsQueryVariables>(
    NewsDocument,
    options
  );
}
export type NewsQueryHookResult = ReturnType<typeof useNewsQuery>;
export type NewsLazyQueryHookResult = ReturnType<typeof useNewsLazyQuery>;
export type NewsQueryResult = Apollo.QueryResult<NewsQuery, NewsQueryVariables>;
export const CategorySubjectDocument = gql`
  query categorySubject($idOrPath: String!) {
    category(idOrPath: $idOrPath) {
      ...CategoryBaseFields
      quizzes {
        ...QuizBaseFields
        exerciseListData {
          _id
          learningId
          difficulty
          displayType
          audioText
          maxDurationSeconds
          containsLatex
          questionsData {
            _id
            text
            explanation {
              text
              audioText
              assetLinks
            }
            possibleAnswers {
              _id
              learningId
              audioText
              text
              isCorrect
            }
          }
        }
        bookmark {
          ...BookmarkBaseFields
        }
        elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
          ...TimeBaseFields
        }
      }
      lessons {
        ...LessonBaseFields
        elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
          ...TimeBaseFields
        }
      }
    }
  }
  ${CategoryBaseFieldsFragmentDoc}
  ${QuizBaseFieldsFragmentDoc}
  ${BookmarkBaseFieldsFragmentDoc}
  ${TimeBaseFieldsFragmentDoc}
  ${LessonBaseFieldsFragmentDoc}
`;

/**
 * __useCategorySubjectQuery__
 *
 * To run a query within a React component, call `useCategorySubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategorySubjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategorySubjectQuery({
 *   variables: {
 *      idOrPath: // value for 'idOrPath'
 *   },
 * });
 */
export function useCategorySubjectQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategorySubjectQuery,
    CategorySubjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategorySubjectQuery, CategorySubjectQueryVariables>(
    CategorySubjectDocument,
    options
  );
}
export function useCategorySubjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategorySubjectQuery,
    CategorySubjectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CategorySubjectQuery,
    CategorySubjectQueryVariables
  >(CategorySubjectDocument, options);
}
export type CategorySubjectQueryHookResult = ReturnType<
  typeof useCategorySubjectQuery
>;
export type CategorySubjectLazyQueryHookResult = ReturnType<
  typeof useCategorySubjectLazyQuery
>;
export type CategorySubjectQueryResult = Apollo.QueryResult<
  CategorySubjectQuery,
  CategorySubjectQueryVariables
>;
export const CategoryTitleDocument = gql`
  query categoryTitle($idOrPath: String!) {
    category(idOrPath: $idOrPath) {
      ...CategoryBaseFields
    }
  }
  ${CategoryBaseFieldsFragmentDoc}
`;

/**
 * __useCategoryTitleQuery__
 *
 * To run a query within a React component, call `useCategoryTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTitleQuery({
 *   variables: {
 *      idOrPath: // value for 'idOrPath'
 *   },
 * });
 */
export function useCategoryTitleQuery(
  baseOptions: Apollo.QueryHookOptions<
    CategoryTitleQuery,
    CategoryTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CategoryTitleQuery, CategoryTitleQueryVariables>(
    CategoryTitleDocument,
    options
  );
}
export function useCategoryTitleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CategoryTitleQuery,
    CategoryTitleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CategoryTitleQuery, CategoryTitleQueryVariables>(
    CategoryTitleDocument,
    options
  );
}
export type CategoryTitleQueryHookResult = ReturnType<
  typeof useCategoryTitleQuery
>;
export type CategoryTitleLazyQueryHookResult = ReturnType<
  typeof useCategoryTitleLazyQuery
>;
export type CategoryTitleQueryResult = Apollo.QueryResult<
  CategoryTitleQuery,
  CategoryTitleQueryVariables
>;
export const ActivityDocument = gql`
  query activity($id: String!, $isQuiz: Boolean!) {
    quiz(id: $id) @include(if: $isQuiz) {
      ...QuizBaseFields
      bookmark {
        ...BookmarkBaseFields
      }
      parentCategories {
        ...CategoryBaseFields
        parent {
          ...CategoryBaseFields
          parent {
            ...CategoryBaseFields
          }
        }
      }
      assetLinks {
        type
        media {
          name
          mimeType
          provider
          url
          type
          contentType
          data
          okulusId
        }
      }
      exerciseListData {
        _id
        text
        learningId
        difficulty
        displayType
        audioText
        maxDurationSeconds
        containsLatex
        assetLinks {
          type
          media {
            name
            mimeType
            provider
            url
            type
            contentType
            data
            okulusId
          }
        }
        questionsData {
          learningId
          score {
            success
            failure
          }
          type
          assetLinks {
            type
            media {
              name
              mimeType
              provider
              url
              type
              contentType
              data
              okulusId
            }
          }
          _id
          text
          audioText
          explanation {
            text
            audioText
            assetLinks
          }
          possibleAnswers {
            _id
            learningId
            audioText
            text
            isCorrect
          }
        }
      }
    }
    lesson(id: $id) @skip(if: $isQuiz) {
      ...LessonBaseFields
      elapsedTime(search: { learningProduct: "RF_FORMATION" }) {
        ...TimeBaseFields
      }
      parentCategories {
        ...CategoryBaseFields
        parent {
          ...CategoryBaseFields
          parent {
            ...CategoryBaseFields
          }
        }
      }
      assetLinks {
        type
        media {
          mimeType
          okulusId
          url
        }
      }
      relatedActivities {
        _id
      }
    }
  }
  ${QuizBaseFieldsFragmentDoc}
  ${BookmarkBaseFieldsFragmentDoc}
  ${CategoryBaseFieldsFragmentDoc}
  ${LessonBaseFieldsFragmentDoc}
  ${TimeBaseFieldsFragmentDoc}
`;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isQuiz: // value for 'isQuiz'
 *   },
 * });
 */
export function useActivityQuery(
  baseOptions: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options
  );
}
export function useActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityQuery,
    ActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options
  );
}
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<
  typeof useActivityLazyQuery
>;
export type ActivityQueryResult = Apollo.QueryResult<
  ActivityQuery,
  ActivityQueryVariables
>;
export const TimePerDomainDocument = gql`
  query timePerDomain(
    $userId: String
    $startTimestamp: Int
    $endTimestamp: Int
  ) {
    DOMAIN_ACCOUNTING: timev2(
      input: {
        userID: $userId
        learningCategorie: "812"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
    DOMAIN_TAX: timev2(
      input: {
        userID: $userId
        learningCategorie: "930"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
    DOMAIN_SOCIAL: timev2(
      input: {
        userID: $userId
        learningCategorie: "1073"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
    DOMAIN_PAYROLL: timev2(
      input: {
        userID: $userId
        learningCategorie: "1123"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
    DOMAIN_BUSINESS: timev2(
      input: {
        userID: $userId
        learningCategorie: "1203"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
    DOMAIN_HERITAGE: timev2(
      input: {
        userID: $userId
        learningCategorie: "1374"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
    DOMAIN_OFFICE: timev2(
      input: {
        userID: $userId
        learningCategorie: "1380"
        learningProduct: "RF_FORMATION"
        fromTimestamp: $startTimestamp
        toTimestamp: $endTimestamp
      }
    ) {
      confirmedDuration
    }
  }
`;

/**
 * __useTimePerDomainQuery__
 *
 * To run a query within a React component, call `useTimePerDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimePerDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimePerDomainQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startTimestamp: // value for 'startTimestamp'
 *      endTimestamp: // value for 'endTimestamp'
 *   },
 * });
 */
export function useTimePerDomainQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimePerDomainQuery,
    TimePerDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimePerDomainQuery, TimePerDomainQueryVariables>(
    TimePerDomainDocument,
    options
  );
}
export function useTimePerDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimePerDomainQuery,
    TimePerDomainQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimePerDomainQuery, TimePerDomainQueryVariables>(
    TimePerDomainDocument,
    options
  );
}
export type TimePerDomainQueryHookResult = ReturnType<
  typeof useTimePerDomainQuery
>;
export type TimePerDomainLazyQueryHookResult = ReturnType<
  typeof useTimePerDomainLazyQuery
>;
export type TimePerDomainQueryResult = Apollo.QueryResult<
  TimePerDomainQuery,
  TimePerDomainQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CursorPaginatedResponse: [
      "PaginatedBriefsOutput",
      "PaginatedCategoriesOutput",
      "PaginatedFlashcardsV2Output",
      "PaginatedLessonsOutput",
      "PaginatedQuizzesOutput",
      "PaginatedRightOfWayTestsOutput",
      "PaginatedTrainingPapersOutput"
    ],
    FlashcardV2: [
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "CdrRoadSignFlashcard",
      "FeuVertRoadSignFlashcard",
      "FleJobsFlashcard",
      "FleVocabularyFlashcard",
      "MosalinguaFlashcard"
    ],
    OffsetPaginatedResponse: [
      "PaginatedCartsOutput",
      "PaginatedCartsV2Output",
      "PaginatedCodesOutput",
      "PaginatedDealsOutput",
      "PaginatedGainsOutput",
      "PaginatedPartnersOutput",
      "PaginatedPrepaidGoodsOutput",
      "PaginatedProductsOutput",
      "PaginatedReportSummariesOutput",
      "PaginatedRoleMappingsOutput",
      "PaginatedToeicPromoCodesOutput",
      "PaginatedUsersOutput",
      "QuizProgressPaginated"
    ],
    PrepaidGoodSource: [
      "AndroidSubscriptionPrepaidGoodSource",
      "CartPrepaidGoodSource",
      "CodePrepaidGoodSource",
      "IosSubscriptionPrepaidGoodSource",
      "StripeSubscriptionPrepaidGoodSource"
    ],
    PriceInterface: ["OneTimePrice", "RecurringPrice"],
    PriceUnion: ["RecurringPrice", "OneTimePrice"],
    RelatedActivitiesData: [
      "Lesson",
      "Quiz",
      "PastPaper",
      "Flashcard",
      "BacFrenchStylisticDeviceFlashcard",
      "BacHistoryLandmarkFlashcard",
      "BacPhilosophyQuoteFlashcard",
      "BrevetFrenchStylisticDeviceFlashcard",
      "BrevetHistoryLandmarkFlashcard",
      "BrevetChemistryMoleculeFlashcard",
      "FeuVertRoadSignFlashcard",
      "CdrRoadSignFlashcard",
      "MosalinguaFlashcard"
    ]
  }
};
export default result;
